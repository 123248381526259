import axios, { AxiosError, AxiosInstance } from 'axios';
import { EbError } from './EbError';
import { AxiosRetryConfig } from './AxiosRetryConfig';
import { HttpStatus } from './HttpStatus';

export class AxiosUtil {
    static create(url: string, timeout: number, retry: number): AxiosInstance {
        const created = axios.create({
            baseURL: url,
            timeout: timeout
        });
        created.interceptors.response.use(undefined, (error) => {
            try {
                const apiError = EbError.fromAxios(error);
                const axiosError = error as AxiosError<
                    undefined,
                    AxiosRetryConfig
                >;
                const config = axiosError?.config;
                if (config && axiosError.isAxiosError) {
                    if (
                        ((typeof config.method === 'string' &&
                            config.method.toUpperCase() === 'GET') ||
                            typeof (config as AxiosRetryConfig).maxRetry ===
                                'number') &&
                        (apiError.status === HttpStatus.ERROR_UNAVAILABLE ||
                            apiError.status === HttpStatus.TIMEOUT)
                    ) {
                        const nullableRequestMaxRetry = (
                            config as AxiosRetryConfig
                        ).maxRetry;
                        const requestMaxRetry =
                            typeof nullableRequestMaxRetry === 'number'
                                ? nullableRequestMaxRetry
                                : retry;
                        const requestCurrentRetry =
                            (config as AxiosRetryConfig).currentRetry || 0;
                        if (requestCurrentRetry < requestMaxRetry) {
                            return new Promise((resolve, reject) => {
                                const backoff =
                                    1_000 * Math.pow(2, requestCurrentRetry);
                                console.log(
                                    `Retrying request ${config.method} ${config.url} in ${backoff}ms (${requestCurrentRetry}/${requestMaxRetry})`
                                );
                                setTimeout(() => {
                                    const updatedConfig = {
                                        ...config,
                                        maxRetry: requestMaxRetry,
                                        currentRetry: requestCurrentRetry + 1
                                    };
                                    created
                                        .request(updatedConfig)
                                        .then(resolve)
                                        .catch(reject);
                                }, backoff);
                            });
                        }
                    }
                }

                return Promise.reject(apiError);
            } catch (e) {
                // Silence
            }
            return Promise.reject(error);
        });
        return created;
    }
}
