import React from 'react';
import { KioskConstants } from '../data/KioskData';

export const ThemeHeaderBar: React.FC = () => {
    return (
        <div
            style={{
                height: `${KioskConstants.STATIC_TOP_MARGIN}%`
            }}
        />
    );
};
