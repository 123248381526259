import React from 'react';

interface ErrorBoundaryState {
    error?: any;
}

export class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
    state: ErrorBoundaryState = {};

    static getDerivedStateFromError(error): ErrorBoundaryState {
        console.error('Error boundary', error);
        return { error };
    }

    componentDidUpdate(
        _prevProps: Readonly<any>,
        _prevState: Readonly<ErrorBoundaryState>,
        _snapshot?: any
    ) {
        if (this.state.error) {
            setTimeout(() => {
                window.location.reload();
            }, 15_000);
        }
    }

    render() {
        const { error } = this.state;
        if (error) {
            return (
                <p>
                    Er is iets fout gegaan :( De pagina wordt over 15 seconden
                    herladen.
                </p>
            );
        }
        return this.props.children;
    }
}
