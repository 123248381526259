import React, { createRef, useCallback, useEffect } from 'react';
import { IBody } from '../data/KioskData';
import '../scss/InfiniteScroll.scss';

export const InfiniteScrollWrapper: React.FC<{
    data: IBody;
    preview: boolean;
    children: React.ReactNode;
    scrolling?: boolean;
}> = ({ data, preview, children, scrolling }) => {
    const ref = createRef<HTMLDivElement>();

    const multiplier = Array.from(
        { length: Math.ceil(data.maxItems / React.Children.count(children)) },
        (_, i) => i + 1
    );

    const updateScroll = useCallback(() => {
        if (ref) {
            let newPos = 0;
            const container = ref.current;
            if (!container) {
                return;
            }

            const _scrollWidth = container.scrollWidth;
            let _scrollPos = container.scrollLeft;

            if (_scrollPos + container.clientWidth >= container.scrollWidth) {
                newPos = _scrollWidth / 2 - container.clientWidth;
                ref.current.scrollLeft = newPos;
                return;
            } else if (_scrollPos <= 0) {
                newPos = _scrollWidth / 2;
                ref.current.scrollLeft = newPos;
                return;
            }
        }
    }, [ref]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!scrolling && ref?.current) {
                if (ref.current.scrollLeft === 0) {
                    updateScroll();
                } else {
                    ref.current.scrollLeft -= 0.1;
                }
            }
        }, 15);
        return () => clearInterval(interval);
    }, [ref, scrolling, updateScroll]);

    const handleScroll = () => {
        updateScroll();
    };

    return (
        <div className="infinite-card-container">
            <div
                ref={ref}
                className="scrolling-wrapper"
                onScroll={handleScroll}
            >
                {multiplier.map((k) => {
                    return (
                        <React.Fragment key={k}>
                            {!preview && <>{children}</>}
                            {!preview &&
                                React.Children.map(children, (child) =>
                                    // @ts-ignore
                                    React.cloneElement(child, {
                                        // @ts-ignore
                                        className: `${child.props.className} is-clone`
                                    })
                                )}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};
