import React, { useContext } from 'react';
import { InfiniteScrollWrapper } from './InfiniteScrollWrapper';
import { InfiniteCard } from './InfiniteCard';
import { LibrettoData } from '../data/LibrettoData';
import { IBody } from '../data/KioskData';
import { KioskLibrettoContext } from '../KioskLibrettoContext';

export const LibrettoRow: React.FC<{
    k: number;
    rowCount: number;
    row: LibrettoData;
    body: IBody;
}> = ({ k, rowCount, row, body }) => {
    const { setSelected, movingRow } = useContext(KioskLibrettoContext);

    return (
        <>
            <div
                className="w-100"
                style={{
                    height: `${(100 - 3 * (rowCount - 1)) / rowCount}%`
                }}
            >
                {row.items && row.items.length > 0 && (
                    <InfiniteScrollWrapper
                        data={body}
                        preview={false}
                        scrolling={movingRow === row.id}
                    >
                        {row.items.map((item, k) => {
                            return (
                                <InfiniteCard
                                    key={k}
                                    data={body}
                                    title={item.getName()}
                                    imageUrl={item.getImageUrl()}
                                    action={() => {
                                        setSelected({
                                            rowId: row.id,
                                            ticket: item
                                        });
                                    }}
                                />
                            );
                        })}
                    </InfiniteScrollWrapper>
                )}
            </div>
            {k < rowCount - 1 && (
                <div
                    key={`${k}-space`}
                    style={{
                        height: '3%',
                        minHeight: '3%',
                        width: '100%'
                    }}
                />
            )}
        </>
    );
};
