import React, { useContext } from 'react';
import { Button, Form, Spinner } from 'reactstrap';
import { OvaticContext } from '../context/OvaticContext';
import { Formik } from 'formik';
import { EbError, ErrorCode } from '../../shared/axios/EbError';
import { OvaticLoginAccountRequest } from '../data/OvaticLoginAccountRequest';
import { InputField } from '../../vsmart/components/InputField';
import { UserActivityContext } from '../../context/UserActivityContext';

export const OvaticLoginAccount: React.FC = () => {
    const { loginAccount, handleError } = useContext(OvaticContext);
    const { resetActivity } = useContext(UserActivityContext);
    return (
        <Formik<OvaticLoginAccountRequest>
            initialValues={{
                email: '',
                password: ''
            }}
            onSubmit={async (values, helpers) => {
                resetActivity();
                try {
                    await loginAccount(values);
                } catch (e) {
                    let handled = false;
                    if (e instanceof EbError) {
                        if (
                            e.hasErrorCode(ErrorCode.OVATIC_INVALID_CREDENTIALS)
                        ) {
                            handled = true;
                            ['email', 'password'].forEach((field) =>
                                helpers.setFieldError(
                                    field,
                                    'Ongeldige e-mail of wachtwoord combinatie'
                                )
                            );
                        } else if (
                            e.hasErrorCode(ErrorCode.OVATIC_EMAIL_UNKNOWN)
                        ) {
                            handled = true;
                            helpers.setFieldError(
                                'email',
                                'Dit e-mailadres heeft nog geen account. Kies voor account registeren.'
                            );
                        }
                    }
                    if (!handled) {
                        handleError(e);
                    }
                }
            }}
        >
            {(formikProps) => (
                <Form onSubmit={formikProps.handleSubmit}>
                    <InputField
                        id="email"
                        label="E-mail"
                        type="email"
                        mandatory={true}
                    />
                    <InputField
                        id="password"
                        label="Wachtwoord"
                        type="password"
                        mandatory={true}
                    />
                    <Button
                        className="fs-5"
                        type="submit"
                        color="primary"
                        disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                        }
                    >
                        {formikProps.isSubmitting && (
                            <Spinner size="sm" className="mr-2" />
                        )}
                        <i className="fa fa-user" /> Inloggen
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
