export interface HealthCheckData {
    state: HealthCheckState;
    step: HealthCheckStep | string;
    outOfOrder: boolean;
}

export enum HealthCheckState {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    VALIDATING = 'VALIDATING',
    VALIDATED = 'VALIDATED'
}

export enum HealthCheckStep {
    LICENSE = 'LICENSE',
    PIN = 'PIN',
    PRINTER = 'PRINTER',
    ALL = 'ALL'
}
