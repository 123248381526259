import { replaceHostname } from '../core/utils/UrlUtil';

let version = process.env.REACT_APP_VERSION;
const buildId = process.env.REACT_APP_BUILD_ID;
if (buildId) {
    version += `-b${buildId}`;
}

export const config: {
    env: typeof process.env.NODE_ENV;
    version: string;
    dashboardUrl: string;
    applicationUrl: string;
    gatewayUrl: string;
} = {
    env: process.env.NODE_ENV,
    version: version,
    dashboardUrl: replaceHostname(process.env.REACT_APP_DASHBOARD_URL),
    applicationUrl: process.env.REACT_APP_APPLICATION_URL,
    gatewayUrl: replaceHostname(process.env.REACT_APP_GATEWAY_URL)
};
