import React, { useCallback, useContext, useEffect, useState } from 'react';
import { timer } from 'rxjs';
import { ApplicationContext } from '../../context/ApplicationContext';
import { VSmartItem } from '../data/VSmartItem';
import { SubscriptionModal } from '../components/SubscriptionModal';
import { KioskContext } from '../../context/KioskContext';
import { CollectionType } from '../../shared/smartbridge/data/CollectionType';

interface SubscriptionContextType {
    subscriptions: VSmartItem[];

    setModal(modal: boolean);

    setSelectedSubscription(selected: VSmartItem);
}

export const SubscriptionContext = React.createContext<SubscriptionContextType>(
    undefined as any
);

export const SubscriptionContextProvider: React.FC<{
    children: React.ReactElement;
}> = ({ children }) => {
    const {
        services: { subscription, smartBridge },
        session,
        splash
    } = useContext(ApplicationContext);

    const { page } = useContext(KioskContext);

    const [modal, setModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] =
        useState<VSmartItem>();

    const [subscriptions, setSubscriptions] = useState<VSmartItem[]>();

    const loadActivities = useCallback(() => {
        if (!session || splash) {
            return;
        }

        subscription
            .fetchAllOfOrganization(session)
            .then((data) => {
                setSubscriptions(
                    data.map(
                        (_subscription) =>
                            new VSmartItem(_subscription, smartBridge)
                    )
                );
            })
            .catch((err) => {
                console.error('Failed to refresh subscriptions', err);
            });
    }, [session, smartBridge, splash, subscription]);

    useEffect(() => {
        const subscription = timer(0, 60_000).subscribe(loadActivities);
        return () => subscription.unsubscribe();
    }, [loadActivities]);

    useEffect(() => {
        if (!selectedSubscription) {
            setModal(false);
        }
    }, [selectedSubscription]);

    const value: SubscriptionContextType = {
        subscriptions,
        setModal,
        setSelectedSubscription
    };

    return (
        <SubscriptionContext.Provider value={value}>
            {children}
            <SubscriptionModal
                isOpen={
                    modal && page?.provider === CollectionType.SUBSCRIPTIONS
                }
                item={selectedSubscription}
                toggle={(isOpen?: boolean) => {
                    if (!isOpen) {
                        setSelectedSubscription(undefined);
                    }
                }}
            />
        </SubscriptionContext.Provider>
    );
};
