import React, { useContext, useEffect, useState } from 'react';
import { ThemeDefault } from './theme/ThemeDefault';
import { DefaultTheme } from './data/KioskData';
import { ThemeMenuItem } from './components/ThemeMenuItem';
import { ThemeData } from '../smartbridge/data/ThemeData';
import { CollectionData } from '../smartbridge/data/CollectionData';
import { SubscriptionContext } from '../../vsmart/context/SubscriptionContext';
import { KioskItem } from './data/KioskItem';
import { CollectionType } from '../smartbridge/data/CollectionType';
import { VSmartItem } from '../../vsmart/data/VSmartItem';
import { OvaticContext } from '../../ovatic/context/OvaticContext';
import { OvaticActivityContextProvider } from '../../ovatic/component/OvaticActivityContextProvider';
import { OvaticItem } from '../../ovatic/data/OvaticItem';
import { OvaticSelectActivityDateModal } from '../../ovatic/component/OvaticSelectActivityDateModal';
import { OvaticOrderActivityModal } from '../../ovatic/component/OvaticOrderActivityModal';
import { KioskContext } from '../../context/KioskContext';
import { OvaticDetailModal } from '../../ovatic/component/OvaticDetailModal';

interface KioskDefaultContextType {
    theme: ThemeData;
    preview: boolean;

    toHome?();
}

export const KioskDefaultContext = React.createContext<KioskDefaultContextType>(
    undefined as any
);

export const KioskDefaultContextProvider: React.FC<{
    theme: ThemeData;
    module?: CollectionData;
    preview: boolean;
    toHome?();
}> = ({ theme, module, preview, toHome }) => {
    const { page } = useContext(KioskContext);

    const { subscriptions, setModal, setSelectedSubscription } =
        useContext(SubscriptionContext);
    const { activities } = useContext(OvaticContext);

    const [showInfo, setShowInfo] = useState(false);
    const [selected, setSelected] = useState<OvaticItem>();
    const [selectDate, setSelectDate] = useState(false);

    const [items] = useState<KioskItem[]>(() => {
        switch (module.provider) {
            case CollectionType.OVATIC:
                return activities;
            default:
                return subscriptions;
        }
    });

    useEffect(() => {
        if (page) {
            setSelectedSubscription(undefined);
            setSelected(undefined);
        }
    }, [page, setSelectedSubscription]);

    const mockHome = () => {};

    const value: KioskDefaultContextType = {
        theme,
        preview,
        toHome: toHome ?? mockHome
    };

    return (
        <KioskDefaultContext.Provider value={value}>
            <ThemeDefault>
                {items &&
                    items.map((item) => {
                        return (
                            <ThemeMenuItem
                                key={item.getId()}
                                item={{
                                    image: item.getImageUrl(),
                                    name: item.getName(),
                                    subtitle: item.getSubtitle()
                                }}
                                settings={theme.settings as DefaultTheme}
                                action={() => {
                                    switch (module.provider) {
                                        case CollectionType.SUBSCRIPTIONS: {
                                            setModal(true);
                                            setSelectedSubscription(
                                                item as VSmartItem
                                            );
                                            break;
                                        }
                                        case CollectionType.OVATIC: {
                                            setSelected(item as OvaticItem);
                                            setShowInfo(true);
                                            break;
                                        }
                                    }
                                }}
                            />
                        );
                    })}
            </ThemeDefault>
            {module.provider === CollectionType.OVATIC && selected && (
                <OvaticActivityContextProvider item={selected}>
                    <>
                        <OvaticSelectActivityDateModal
                            isOpen={
                                selected instanceof OvaticItem && selectDate
                            }
                            selected={selected}
                            select={(item: OvaticItem) => {
                                setSelected(item);
                            }}
                            toggle={(isOpen?: boolean) => {
                                if (!isOpen && typeof isOpen == 'boolean') {
                                    setSelected(undefined);
                                }
                                setSelectDate(false);
                            }}
                            highlightCurrentSelection={false}
                        />
                        <OvaticOrderActivityModal
                            isOpen={!!selected && !selectDate && !showInfo}
                            toggle={() => {
                                setSelected(undefined);
                            }}
                        />
                        <OvaticDetailModal
                            isOpen={showInfo && selected instanceof OvaticItem}
                            selected={selected}
                            toggle={() => {
                                setSelected(undefined);
                                setShowInfo(false);
                            }}
                            nextStep={() => {
                                setShowInfo(false);
                                setSelectDate(true);
                            }}
                        />
                    </>
                </OvaticActivityContextProvider>
            )}
        </KioskDefaultContext.Provider>
    );
};
