import React, { useContext } from 'react';
import { KioskConstants, Themes } from '../data/KioskData';
import { UserActivityContext } from '../../../context/UserActivityContext';

export const ThemeHeaderMenu: React.FC<{
    toHome?();
    settings: Themes;
}> = ({ toHome, settings }) => {
    const { resetActivity } = useContext(UserActivityContext);
    return (
        <>
            <div
                className="d-flex flex-row w-100 align-items-start justify-content-between"
                style={{
                    height: `${KioskConstants.DEFAULT_HEADER_MENU_SIZE}%`,
                    backgroundColor: settings.subHeader.backgroundColor,
                    padding: '0 3%',
                    color: settings.subHeader.color
                }}
            >
                <div className="d-flex">
                    <span
                        className="font-weight-bold"
                        style={{
                            fontSize: '335%'
                        }}
                    >
                        {settings.subHeader.title}
                    </span>
                </div>
                {toHome && (
                    <div
                        className="d-flex"
                        onClick={() => {
                            resetActivity();
                            toHome();
                        }}
                    >
                        <span
                            className="font-weight-bold"
                            style={{
                                fontSize: '250%'
                            }}
                        >
                            {settings.home.title}{' '}
                            <i className={`fa fa-${settings.home.icon}`} />
                        </span>
                    </div>
                )}
            </div>
        </>
    );
};
