import React from 'react';
import SanitizedHTML from 'react-sanitized-html';

export const SanitizedDescription: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {
    return (
        <SanitizedHTML
            allowedTags={['br', 'ol', 'ul', 'li', 'b', 'div', 'p']}
            html={children}
        />
    );
};
