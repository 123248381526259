import * as Yup from 'yup';
import { StringSchema, TestConfig } from 'yup';
import { Length, resolveLength } from './Length';
import { TFunction } from 'i18next';
import { EbError } from '../../shared/axios/EbError';

/**
 *
 * @param name Name of the field
 * @param min Minimum amount of characters
 * @param max Maximum amount of characters
 * @param t Translation function
 */
export function testLength(
    name: string,
    min = 0,
    max = 255,
    t: TFunction
): TestConfig<string | undefined> {
    return {
        name: 'length',
        test: function (value: string | undefined) {
            const length = value ? value.length : 0;
            if (length < min) {
                return (this as any).createError({
                    message: t('form:error.minLength', {
                        min,
                        count: length,
                        remaining: min - length
                    }),
                    path: name
                });
            }
            if (length > max) {
                return (this as any).createError({
                    message: t('form:error.maxLength', {
                        max,
                        count: length,
                        remaining: length - max
                    }),
                    path: name
                });
            }
            return true;
        }
    };
}

export function testSubscriptionField(
    name: string,
    type: 'txt' | 'eml' | 'dat' | 'sel' | 'chk'
): TestConfig<string | undefined> {
    return {
        name: 'validate-subscription-fields',
        test: function (value: string | undefined) {
            console.log('inside test ' + value);
            if (value) {
                switch (type) {
                    case 'eml': {
                        if (
                            !value
                                .toLowerCase()
                                .match(
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                )
                        ) {
                            console.log('here');
                            return (this as any).createError({
                                message: 'Invalid email',
                                path: name
                            });
                        }
                    }
                }
            }

            return true;
        }
    };
}

/**
 * Builder for creating Yup schemas with some default translations.
 */
export class YupBuilder {
    /**
     * Name of the field.
     * @private
     */
    private _name: string | undefined;
    private _type: 'string' | 'number' | 'boolean' | undefined;
    private _required: boolean = false;
    private _requiredMessage: string | undefined = undefined;
    private _min: number | undefined;
    private _max: number | undefined;
    private _macAddress: boolean = false;

    // eslint-disable-next-line no-useless-constructor
    constructor(private t: TFunction) {}

    string(): this {
        this._type = 'string';
        return this;
    }

    macAddress(): this {
        this._macAddress = true;
        return this;
    }

    number(): this {
        this._type = 'number';
        return this;
    }

    boolean(): this {
        this._type = 'boolean';
        return this;
    }

    required(
        required: boolean = true,
        message: string | undefined = undefined
    ): this {
        this._required = required;
        this._requiredMessage = message;
        return this;
    }

    length(name: string, min: Length = 0, max: Length = 255): this {
        this._name = name;
        this._min = resolveLength(min);
        this._max = resolveLength(max);
        return this;
    }

    build(): Yup.BaseSchema<any> {
        let schema: Yup.BaseSchema<any>;
        switch (this._type) {
            case 'string':
                schema = Yup.string();
                if (
                    typeof this._name === 'string' &&
                    typeof this._min === 'number'
                ) {
                    schema = (schema as StringSchema).test(
                        testLength(this._name, this._min, this._max, this.t)
                    );
                }
                break;
            case 'number':
                schema = Yup.number();
                break;
            case 'boolean':
                schema = Yup.boolean();
                break;
            default:
                throw new EbError(
                    500,
                    `Yup schema type ${this._type} is not implemented`
                );
        }

        if (this._required) {
            if (typeof (schema as Yup.StringSchema).required === 'function') {
                // Required also exists on other schemas, but sadly not on the base Schema. So we just cast to StringSchema and check whether required function exists.
                schema = (schema as Yup.StringSchema).required(
                    this._requiredMessage || 'Dit veld is verplicht'
                );
            }
        } else if (
            typeof (schema as Yup.StringSchema).optional === 'function'
        ) {
            schema = (schema as Yup.StringSchema).optional();
        }

        if (this._macAddress) {
            schema = (schema as StringSchema).matches(
                /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
                'Dit is geen geldig MAC-adres'
            );
        }

        return schema;
    }
}

/**
 * Required translation sections: ['form']
 * @param t
 */
export function yupBuilder(t: TFunction): YupBuilder {
    return new YupBuilder(t);
}
