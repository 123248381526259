import React, { useContext } from 'react';
import { ImageReferenceData, KioskConstants } from '../data/KioskData';
import { ApplicationContext } from '../../../context/ApplicationContext';

export const ThemeLogo: React.FC<{ logo: ImageReferenceData }> = ({ logo }) => {
    const { services } = useContext(ApplicationContext);

    return (
        <>
            {logo && (
                <img
                    className="position-absolute"
                    style={{
                        left: '5%',
                        top: `${KioskConstants.STATIC_TOP_MARGIN - 1}%`,
                        height: `${
                            KioskConstants.MAX_LOGO_POSITION -
                            KioskConstants.STATIC_TOP_MARGIN +
                            1
                        }%`
                    }}
                    src={services.smartBridge.getImage(logo.id, logo.checksum)}
                    alt="logo"
                />
            )}
        </>
    );
};
