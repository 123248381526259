import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { WebsocketPaths } from '../../constants';
import { ApplicationContext } from '../../context/ApplicationContext';
import classNames from 'classnames';

export const CashierDisplay: React.FC = () => {
    const {
        websocket: { app }
    } = useContext(ApplicationContext);

    const [message, setMessage] =
        useState<{ value: string; height?: string; width?: string }[]>();

    useEffect(() => {
        if (!app) {
            return () => {};
        }

        const sub = app.observe(WebsocketPaths.payment.cashier).subscribe({
            next: (data) => {
                console.log('cashier ' + data);
                setMessage(JSON.parse(data));
            }
        });
        return () => sub.unsubscribe();
    }, [app]);

    return (
        <div>
            {message?.map((line, k) => {
                return (
                    <Row key={k}>
                        <span
                            className={classNames('', {
                                'fw-bold': line.width.toLowerCase() === 'double'
                            })}
                            style={{
                                fontSize:
                                    line.height.toLowerCase() === 'double'
                                        ? '200%'
                                        : '100%'
                            }}
                        >
                            {line.value}
                        </span>
                    </Row>
                );
            })}
        </div>
    );
};
