export enum HttpStatus {
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    TIMEOUT = 408,
    CONFLICT = 409,
    TOO_MANY_REQUESTS = 429,
    ERROR = 500,
    ERROR_UNAVAILABLE = 503
}
