import { EbWebSocketOptions } from './EbWebSocketOptions';
import webstomp, { Client } from 'webstomp-client';
import SockJS from 'sockjs-client';
import { Observable, PartialObserver, share, Subscriber } from 'rxjs';

export class EbWebSocket {
    private socket: WebSocket | undefined;
    stompClient: Client | undefined;
    connected: boolean = false;
    reconnecting: boolean = false;
    closing: boolean = false;
    private subscriptions: { [key: string]: WebSocketSubject } = {};

    constructor(private options: EbWebSocketOptions) {}

    init = () => {
        if (this.socket) {
            throw new Error('Socket already initialized');
        }
        this.reconnecting = false;
        this.socket = this.createSocket();
        const stompClient = this.createStompClient();
        this.stompClient = stompClient;

        stompClient.connect(
            {},
            () => {
                this.connected = true;

                // Setup subscriptions against new socket
                Object.values(this.subscriptions).forEach((subscription) => {
                    subscription.subscribed = false;
                    subscription.setupSubscriber();
                });
            },
            (err) => {
                if (
                    this.reconnecting ||
                    stompClient !== this.stompClient ||
                    this.closing
                ) {
                    return;
                }
                console.log('Connection error. Reconnecting...', err);
                this.reconnecting = true;
                this.connected = false;

                if (this.stompClient) {
                    this.stompClient.disconnect();
                    this.stompClient = undefined;
                }
                if (this.socket) {
                    this.socket.close();
                    this.socket = undefined;
                }

                setTimeout(() => {
                    this.init();
                }, this.options.reconnectDelay);
            }
        );
    };

    close = () => {
        this.closing = true;
        this.connected = false;
        this.reconnecting = false;
        if (this.stompClient) {
            this.stompClient.disconnect();
            this.stompClient = undefined;
        }
        if (this.socket) {
            this.socket.close();
            this.socket = undefined;
        }
    };

    private createStompClient() {
        return webstomp.over(this.socket, {
            debug: this.options.debug
        });
    }

    private createSocket() {
        return new SockJS(this.options.url, undefined, {
            timeout: this.options.timeout
        });
    }

    observe = (destination: string) => {
        let subscription = this.subscriptions[destination];
        if (subscription) {
            return subscription;
        }
        subscription = new WebSocketSubject(this, destination);
        this.subscriptions[destination] = subscription;
        return subscription;
    };
}

class WebSocketSubject {
    subscriber: Subscriber<string> | undefined;
    subscribed = false;
    private $o = new Observable<string>((subscriber) => {
        this.subscriber = subscriber;
        if (this.socket.connected && this.socket.stompClient) {
            this.setupSubscriber();
        }
        return undefined;
    }).pipe(share());

    constructor(private socket: EbWebSocket, private destination: string) {}

    subscribe = (observer: PartialObserver<string>) => {
        return this.$o.subscribe(observer);
    };

    setupSubscriber = () => {
        if (this.socket.stompClient && !this.subscribed && this.subscriber) {
            this.subscribed = true;
            const subscription = this.socket.stompClient.subscribe(
                this.destination,
                (message) => {
                    if (this.subscriber) {
                        this.subscriber.next(message.body);
                    }
                }
            );
            this.subscriber.add(() => {
                this.subscribed = false;
                this.subscriber = undefined;
                subscription.unsubscribe();
            });
        }
    };
}
