import { Axios } from 'axios';
import { SaveBorrowerRequest } from '../../payment/data/SaveBorrowerRequest';
import { SaveBorrowerResponseData } from '../data/SaveBorrowerResponseData';
import { ActivateBorrowerResponseData } from '../data/ActivateBorrowerResponseData';
import { SetPasswordBorrowerRequestData } from '../data/SetPasswordBorrowerRequestData';

export class IguanaRestService {
    private axios: Axios;

    constructor(axios: Axios) {
        this.axios = axios;
    }

    /*getBorrowerFields(): Promise<BorrowerFieldsResponse> {
return this.axios
.get<BorrowerFieldsResponse>(`/v1/iguana/borrower/fields`)
.then((res) => res.data);
}*/

    getFields(
        organizationId: number,
        subscriptionId: string
    ): Promise<IguanaGroupFieldData[]> {
        return this.axios
            .get<IguanaGroupFieldData[]>(
                `v1/iguana/organizations/${organizationId}/subscriptions/${subscriptionId}/fields`
            )
            .then((res) => res.data);
    }

    saveBorrower(
        organizationId: number,
        subscriptionId: string,
        data: SaveBorrowerRequest,
        check?: boolean
    ): Promise<SaveBorrowerResponseData> {
        return this.axios
            .post<SaveBorrowerResponseData>(
                `v1/iguana/organizations/${organizationId}/subscriptions/${subscriptionId}/borrower/save${
                    typeof check == 'boolean' ? `?check=${check}` : ''
                }`,
                data
            )
            .then((res) => res.data);
    }

    activateBorrower(
        organizationId: number,
        activationCode: string
    ): Promise<ActivateBorrowerResponseData> {
        return this.axios
            .post<ActivateBorrowerResponseData>(
                `v1/iguana/organizations/${organizationId}/borrower/activate/${activationCode}`
            )
            .then((res) => res.data);
    }

    setPassword(
        organizationId: number,
        data: SetPasswordBorrowerRequestData
    ): Promise<void> {
        return this.axios
            .post<void>(
                `v1/iguana/organizations/${organizationId}/borrower/setpassword`,
                data
            )
            .then((res) => res.data);
    }
}
