import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Fade, Table } from 'reactstrap';

import { KioskLibrettoContext } from '../KioskLibrettoContext';
import { KioskConstants, LibrettoTheme } from '../data/KioskData';
import { ThemeHeaderBar } from '../components/ThemeHeaderBar';
import { ThemeLogo } from '../components/ThemeLogo';
import { InfiniteScrollWrapper } from '../components/InfiniteScrollWrapper';
import { InfiniteCard } from '../components/InfiniteCard';
import { ThemeFooter } from '../components/ThemeFooter';
import { OvaticActivityContext } from '../../../ovatic/context/OvaticActivityContext';
import { OvaticItem } from '../../../ovatic/data/OvaticItem';
import SanitizedHTML from 'react-sanitized-html';
import { VSmartItem } from '../../../vsmart/data/VSmartItem';
import moment from 'moment-timezone';
import { UserActivityContext } from '../../../context/UserActivityContext';
import { SubscriptionContext } from '../../../vsmart/context/SubscriptionContext';
import { FormatDate } from '../components/FormatDate';
import { FormatTime } from '../components/FormatTime';
import { KioskItemlocation } from '../components/KioskItemLocation';
import { SanitizedDescription } from '../../../vsmart/components/SanitizedDescription';
import './LibrettoDetail.scss';
import { OvaticSelectActivityDateModal } from '../../../ovatic/component/OvaticSelectActivityDateModal';
import { OvaticOrderActivityModal } from '../../../ovatic/component/OvaticOrderActivityModal';
import { formatMoney } from '../../../util';
import { OvaticDetailModal } from '../../../ovatic/component/OvaticDetailModal';

export const ThemeLibrettoDetail: React.FC = () => {
    const { theme, selected, setSelected, rows, select } =
        useContext(KioskLibrettoContext);
    const ovaticActivityContext = useContext(OvaticActivityContext);
    const { setModal, setSelectedSubscription } =
        useContext(SubscriptionContext);

    const { rowId, ticket } = selected;
    const selectedRow = rows.find((_row) => _row.id === rowId);
    const [orderTicket, setOrderTicket] = useState(false);
    const { resetActivity } = useContext(UserActivityContext);
    const [selectDate, setSelectDate] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    const settings = theme.settings as LibrettoTheme;

    const detailRef = useRef<HTMLDivElement>();

    const [moreInfo, setMoreInfo] = useState(false);

    let frameTitle = 'INFO EN TICKETS';
    if (ticket instanceof VSmartItem) {
        frameTitle = 'ABONNEMENT';
    }

    let frameButton = 'BESTEL KAARTEN';
    if (ticket instanceof VSmartItem) {
        frameButton = 'LID WORDEN';
    }

    const orderButtonAction = () => {
        if (ticket instanceof OvaticItem) {
            setOrderTicket(true);
        } else if (ticket instanceof VSmartItem) {
            setModal(true);
            setSelectedSubscription(ticket);
        }
    };

    const MAX_BODY_SIZE =
        100 -
        KioskConstants.STATIC_TOP_MARGIN -
        45 -
        KioskConstants.STATIC_BOTTOM_MARGIN;

    // TODO image url escape
    const imageUrl =
        ticket.getImageUrl() ??
        'https://agenda.ticketunie.com/img/thumbnail.ashx?trsid=416&id=6734&imagegroup=1';

    let description: React.ReactNode;
    let canAdjustDate = undefined;
    if (ticket instanceof OvaticItem) {
        const ovaticDescription =
            ovaticActivityContext?.description?.longText ??
            ovaticActivityContext?.description?.shortText;
        if (ovaticDescription) {
            description = <SanitizedHTML html={ovaticDescription} />;
        }
        const { productions } = ovaticActivityContext;
        if (Array.isArray(productions)) {
            canAdjustDate = productions.length > 1;
        }
    } else if (ticket instanceof VSmartItem) {
        description = (
            <SanitizedDescription>
                {ticket.getDescription()}
            </SanitizedDescription>
        );
    }

    useEffect(() => {
        if (detailRef.current) {
            if (
                detailRef.current.clientHeight !==
                detailRef.current.scrollHeight
            ) {
                setMoreInfo(true);
            } else {
                setMoreInfo(false);
            }
        }
    }, [detailRef, description]);

    const doorsOpenDate = ticket.getDoorsOpenDate();
    const startDate = ticket.getStartDate();
    const endDate = ticket.getEndDate();

    const showDoorsOpen = useMemo(() => {
        if (!doorsOpenDate) {
            return false;
        }
        if (
            !moment(startDate)
                .locale('nl')
                .isSame(moment(doorsOpenDate).locale('nl'), 'day')
        ) {
            // Only show when date is the same day, otherwise it becomes confusing
            return false;
        }
        // Only show when the doors open date is different from the start date
        return moment(startDate)
            .locale('nl')
            .isSame(moment(doorsOpenDate).locale('nl'), 'minute');
    }, [doorsOpenDate, startDate]);

    let frameBody = () => {
        return (
            <>
                {canAdjustDate && (
                    <Fade in>
                        <div
                            style={{
                                fontSize: '295%',
                                color: settings.details.frame.secondaryColor
                            }}
                        >
                            <span
                                className="font-weight-bold"
                                onClick={() => {
                                    resetActivity();
                                    if (canAdjustDate) {
                                        setSelectDate(true);
                                    }
                                }}
                            >
                                Pas datum of tijd aan{' '}
                                <i className="fa fa-pencil" />
                            </span>
                        </div>
                    </Fade>
                )}
                {!canAdjustDate && (
                    <Fade in={canAdjustDate === false}>
                        <div
                            style={{
                                fontSize: '295%',
                                color: settings.details.frame.secondaryColor
                            }}
                        >
                            <span className="font-weight-bold">
                                Geen andere datum beschikbaar
                            </span>
                        </div>
                    </Fade>
                )}
                <Table
                    borderless
                    style={{
                        fontSize: '295%',
                        color: settings.details.frame.color
                    }}
                >
                    <tbody>
                        <tr>
                            <td className="p-0 font-weight-bold">Datum</td>
                            <td className="p-0">
                                <FormatDate date={startDate} />
                            </td>
                        </tr>
                        <tr>
                            <td className="p-0 font-weight-bold">Aanvang</td>
                            <td className="p-0">
                                <FormatTime date={startDate} />
                                {showDoorsOpen && (
                                    <small className="d-block">
                                        Deuren open vanaf{' '}
                                        <FormatTime date={doorsOpenDate} />
                                    </small>
                                )}
                            </td>
                        </tr>
                        {endDate && (
                            <tr>
                                <td className="p-0 font-weight-bold">Einde</td>
                                <td className="p-0">
                                    <FormatTime date={endDate} />
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td className="p-0 font-weight-bold">Locatie</td>
                            <td className="p-0">
                                <KioskItemlocation item={ticket} />
                            </td>
                        </tr>
                        <tr>
                            <td className="p-0 font-weight-bold">Prijzen</td>
                            <td className="p-0">
                                {ovaticActivityContext.prices
                                    ?.map((price) => {
                                        let cheapestPrice = undefined;
                                        const availableRanks =
                                            price.ranks.filter(
                                                (rank) =>
                                                    ovaticActivityContext
                                                        .activity.seatPlan
                                                        ?.placed === false ||
                                                    ovaticActivityContext.getAvailableSeatCountForRank(
                                                        rank.rankId
                                                    ) > 0
                                            );
                                        availableRanks.forEach((rank) => {
                                            if (
                                                cheapestPrice === undefined ||
                                                rank.price < cheapestPrice
                                            ) {
                                                cheapestPrice = rank.price;
                                            }
                                        });
                                        if (cheapestPrice === undefined) {
                                            return undefined;
                                        }
                                        let label = price.name;
                                        if (price.ranks.length > 1) {
                                            label += ' vanaf ';
                                        }
                                        label +=
                                            ' ' + formatMoney(cheapestPrice);
                                        return [
                                            <span
                                                key={price.id}
                                                className="d-block"
                                            >
                                                {label}
                                            </span>,
                                            cheapestPrice
                                        ];
                                    })
                                    .filter((el) => el !== undefined)
                                    .sort((a, b) => a[1] - b[1])
                                    .map((arr) => arr[0])}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        );
    };
    if (ticket instanceof VSmartItem) {
        frameBody = () => {
            return (
                <Table
                    borderless
                    style={{
                        fontSize: '295%',
                        color: settings.details.frame.color
                    }}
                >
                    <tbody>
                        <tr>
                            <td className="p-0 font-weight-bold">Prijs</td>
                            <td className="p-0">{ticket.getSubtitle()}</td>
                        </tr>
                    </tbody>
                </Table>
            );
        };
    }

    return (
        <div
            className="position-relative test123"
            style={{
                aspectRatio: '9/16',
                backgroundImage: "url('/assets/img/background_libretto.png')",
                backgroundRepeat: 'repeat',
                backgroundPosition: 'center'
            }}
        >
            <ThemeHeaderBar />
            <div
                className="d-flex flex-column align-items-end justify-content-end"
                style={{
                    height: `45%`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundImage: `linear-gradient(0deg, ${settings.details.body.backgroundColor} 5%, rgba(0,0,0,0) 65%), url(${imageUrl})`
                }}
            >
                <div
                    className="d-flex flex-column detail-frame"
                    style={{
                        backgroundColor: settings.details.frame.backgroundColor
                    }}
                >
                    <div
                        style={{
                            fontSize: '380%',
                            marginBottom: '3%',
                            color: settings.details.frame.color
                        }}
                    >
                        <span className="font-weight-bold">{frameTitle}</span>
                    </div>
                    <div
                        className="d-flex flex-column flex-grow-1 justify-content-center"
                        style={{
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}
                    >
                        {frameBody()}
                    </div>
                    <div className="d-flex justify-content-center">
                        <Button
                            className="font-weight-bold"
                            style={{
                                fontSize: '295%',
                                padding: '2% 5%',
                                borderRadius: 0,
                                backgroundColor:
                                    settings.details.frame.secondaryColor,
                                color: settings.details.frame.backgroundColor
                            }}
                            onClick={() => {
                                resetActivity();
                                orderButtonAction();
                            }}
                        >
                            {frameButton}
                        </Button>
                    </div>
                </div>
            </div>
            <ThemeLogo logo={theme.logo} />
            <div
                className="d-flex flex-column"
                style={{
                    height: `${MAX_BODY_SIZE}%`,
                    backgroundColor: settings.details.body.backgroundColor
                }}
            >
                <div
                    className="d-flex flex-row"
                    style={{
                        height: '40%',
                        padding: '0 5% 3% 5%'
                    }}
                >
                    <div
                        className="d-flex flex-column"
                        style={{
                            color: settings.details.body.color,
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <div
                            style={{
                                width: '100%'
                            }}
                        >
                            <div className="title-frame">
                                <div />
                            </div>
                            <span
                                className="font-weight-bold"
                                style={{
                                    fontSize: '505%',
                                    lineHeight: '110%'
                                }}
                            >
                                {ticket.getName()}
                            </span>
                        </div>
                        <div
                            className="d-flex"
                            style={{
                                overflowY: 'hidden',
                                overflowX: 'hidden',
                                flex: 1
                            }}
                        >
                            <div
                                className="d-flex flex-column"
                                style={{
                                    overflow: 'hidden',
                                    width: '80%',
                                    height: '100%'
                                }}
                            >
                                <div
                                    ref={detailRef}
                                    style={{
                                        overflow: 'hidden',
                                        height: '100%',
                                        position: 'relative'
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: '250%',
                                            display: 'inline-block',
                                            lineHeight: '110%'
                                        }}
                                    >
                                        {description}
                                    </div>
                                    {moreInfo && (
                                        <div
                                            className="w-100 position-absolute d-flex align-items-end"
                                            style={{
                                                backgroundColor:
                                                    settings.details.body
                                                        .backgroundColor,
                                                height: '2vh',
                                                bottom: 0
                                            }}
                                        >
                                            <span
                                                className="font-weight-bold"
                                                style={{
                                                    fontSize: '150%',
                                                    color: settings.details.body
                                                        .secondaryColor
                                                }}
                                                onClick={() => {
                                                    resetActivity();
                                                    if (
                                                        ticket instanceof
                                                        OvaticItem
                                                    ) {
                                                        setShowInfo(true);
                                                    } else {
                                                        orderButtonAction();
                                                    }
                                                }}
                                            >
                                                Meer informatie
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-end justify-content-end"
                                style={{ width: '20%' }}
                                onClick={() => {
                                    resetActivity();
                                    setSelected(null);
                                }}
                            >
                                <span
                                    className="font-weight-bold"
                                    style={{
                                        fontSize: '250%',
                                        color: settings.details.body
                                            .secondaryColor
                                    }}
                                >
                                    {settings.home.title}{' '}
                                    <i className="fa fa-home" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-column w-100"
                    style={{ height: '60%' }}
                >
                    <InfiniteScrollWrapper
                        data={settings.details.body}
                        preview={false}
                    >
                        {selectedRow.items.map((item, k) => {
                            return (
                                <InfiniteCard
                                    key={k}
                                    data={settings.body}
                                    title={item.getName()}
                                    imageUrl={item.getImageUrl()}
                                    action={() => {
                                        setSelected({
                                            rowId: selectedRow.id,
                                            ticket: item
                                        });
                                    }}
                                />
                            );
                        })}
                    </InfiniteScrollWrapper>
                </div>
            </div>
            <ThemeFooter settings={settings} />
            {ticket instanceof OvaticItem && (
                <>
                    <OvaticSelectActivityDateModal
                        isOpen={ticket instanceof OvaticItem && selectDate}
                        selected={ticket}
                        select={(ticket: OvaticItem) => {
                            select(ticket);
                        }}
                        toggle={() => {
                            setSelectDate(false);
                        }}
                    />
                    <OvaticOrderActivityModal
                        isOpen={orderTicket}
                        toggle={() => setOrderTicket(false)}
                    />
                    <OvaticDetailModal
                        isOpen={showInfo}
                        selected={ticket}
                        /*nextStep={() => {
                            setShowInfo(false);
                            orderButtonAction();
                        }}*/
                        toggle={() => {
                            setShowInfo(false);
                        }}
                    />
                </>
            )}
        </div>
    );
};
