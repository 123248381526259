export const WebsocketPaths = {
    health: '/topic/health',
    payment: {
        cashier: '/topic/payment/cashier',
        result: '/topic/payment/result'
    }
};

export const INACTIVITY_TIMEOUT = 60;
export const EVENT_TIMEOUT = 30;
