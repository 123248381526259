import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table
} from 'reactstrap';
import { OvaticActivityContext } from '../context/OvaticActivityContext';
import { FormatDate } from '../../shared/kiosk/components/FormatDate';
import { FormatTime } from '../../shared/kiosk/components/FormatTime';
import { OvaticItem } from '../data/OvaticItem';
import { OvaticActivityData } from '../data/OvaticActivityData';
import { UserActivityContext } from '../../context/UserActivityContext';

export interface OvaticSelectActivityDateModalProps {
    isOpen: boolean;

    selected: OvaticItem;

    select(ticket: OvaticItem);

    toggle(isOpen?: boolean);

    highlightCurrentSelection?: boolean;
}

export const OvaticSelectActivityDateModal: React.FC<
    OvaticSelectActivityDateModalProps
> = ({
    isOpen,
    selected,
    select,
    toggle,
    highlightCurrentSelection = true
}) => {
    const { productions } = useContext(OvaticActivityContext);
    const [_productions, setProductions] = useState<OvaticActivityData[]>();
    const { resetActivity } = useContext(UserActivityContext);

    const sorted = useMemo(() => {
        if (_productions) {
            return _productions.sort(
                (a, b) =>
                    new Date(a.startDate).getTime() -
                    new Date(b.startDate).getTime()
            );
        }
        return [];
    }, [_productions]);

    useEffect(() => {
        if (isOpen) {
            // Copy productions to internal state only when modal is open, to prevent flickering when selecting a different date (which triggers a load of productions)
            setProductions(productions);
        }
    }, [isOpen, productions]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                toggle();
            }}
            returnFocusAfterClose={false}
            centered
            size="lg"
            backdrop="static"
        >
            <ModalHeader>
                <span className="fs-2">Kies een moment</span>
            </ModalHeader>
            <ModalBody>
                <p>Kies een moment voor de activiteit.</p>
                <Table>
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Starttijd</th>
                            <th>Eindtijd</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {sorted.map((activity, index) => {
                            const activitySelected =
                                activity.id === selected.getId();
                            const startDate = new Date(activity.startDate);
                            const endDate = new Date(activity.endDate);
                            return (
                                <tr key={index}>
                                    <td>
                                        <FormatDate date={startDate} />
                                    </td>
                                    <td>
                                        <FormatTime date={startDate} />
                                    </td>
                                    <td>
                                        <FormatTime date={endDate} />
                                    </td>
                                    <td
                                        style={{
                                            textAlign: 'right'
                                        }}
                                    >
                                        {activity.free > 0 ? (
                                            <Button
                                                color={
                                                    highlightCurrentSelection &&
                                                    activitySelected
                                                        ? 'secondary'
                                                        : 'primary'
                                                }
                                                onClick={() => {
                                                    resetActivity();
                                                    select(
                                                        new OvaticItem(activity)
                                                    );
                                                    toggle();
                                                }}
                                            >
                                                Kies
                                            </Button>
                                        ) : (
                                            <Button color="secondary" disabled>
                                                Uitverkocht
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => {
                        resetActivity();
                        toggle(false);
                    }}
                >
                    Sluiten
                </Button>
            </ModalFooter>
        </Modal>
    );
};
