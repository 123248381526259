import React, { useContext } from 'react';
import { Badge } from 'reactstrap';
import { OvaticActivityContext } from '../context/OvaticActivityContext';
import { OvaticActivityPriceData } from '../data/OvaticActivityPriceData';
import { formatMoney } from '../../util';

export interface OvaticPriceSummaryProps {
    price: OvaticActivityPriceData;
}

export const OvaticPriceSummary: React.FC<OvaticPriceSummaryProps> = ({
    price
}) => {
    const { getRankById } = useContext(OvaticActivityContext);
    return (
        <>
            {price.ranks.map((rankPerPrice) => {
                const rank = getRankById(rankPerPrice.rankId);
                return (
                    <span key={rankPerPrice.rankId} className="mr-1">
                        <sup className="px-1">
                            <Badge
                                pill
                                color={null}
                                style={{
                                    backgroundColor: rank?.colors[0]
                                }}
                            >
                                &nbsp;
                            </Badge>
                        </sup>{' '}
                        {rank?.name} {formatMoney(rankPerPrice.price)}
                    </span>
                );
            })}
        </>
    );
};
