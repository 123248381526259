export enum MediaType {
    UNKNOWN = 'UNKNOWN',
    BOOK = 'BOOK',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    WEBSITE = 'WEBSITE',
    LIBRETTO = 'LIBRETTO',
    LIBRETTO_PAGE = 'LIBRETTO_PAGE',
    DIGITAL_SIGNAGE = 'DIGITAL_SIGNAGE',
    PRESENTATION = 'PRESENTATION',
    NEWS_OVERVIEW = 'NEWS_OVERVIEW',
    DVD_TRAILER = 'DVD_TRAILER',
    SUBSCRIPTION = 'SUBSCRIPTION',
    COLLECTION = 'COLLECTION'
}
