import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Axios } from 'axios';
import { EbWebSocket } from '../shared/axios/EbWebSocket';
import { AxiosUtil } from '../shared/axios/AxiosUtil';
import { useParams } from 'react-router-dom';
import { SmartBridgeService } from '../routes/default/service/SmartBridgeService';
import { ThemeService } from '../shared/kiosk/service/ThemeService';
import { SubscriptionService } from '../shared/kiosk/service/SubscriptionService';
import { PaymentService } from '../payment/service/PaymentService';
import { IguanaRestService } from '../vsmart/service/IguanaRestService';
import { config } from '../config/config';
import { KioskContextProvider } from './KioskContext';
import { OvaticContextProvider } from '../ovatic/component/OvaticContextProvider';
import { SubscriptionContextProvider } from '../vsmart/context/SubscriptionContext';
import { KioskPreview } from '../shared/kiosk/KioskPreview';
import { UserActivityContextProvider } from './UserActivityContext';
import { ErrorNotification } from '../health/component/ErrorNotification';
import { LoadStatusType } from '../data/LoadStatusType';
import { SplashLoader } from '../shared/boot/SplashLoader';
import { InactiveModal } from '../ovatic/component/InactiveModal';
import { useBeforeunload } from 'react-beforeunload';
import { timer } from 'rxjs';
import { KeyboardContextProvider } from '../shared/kiosk/components/KioskTextField';
import { LibrettoDisplayData } from '../shared/smartbridge/data/LibrettoDisplayData';
import { EVENT_TIMEOUT, INACTIVITY_TIMEOUT } from '../constants';

export interface IApplicationContext {
    api: { dashboard: Axios; app?: Axios; gateway: Axios };
    websocket: { app?: EbWebSocket };

    services: {
        smartBridge: SmartBridgeService;
        theme: ThemeService;
        subscription: SubscriptionService;
        payment: PaymentService;
        iguana: IguanaRestService;
    };

    organizationId: number;
    displayId: number;
    hash: string;

    session: string;
    displayConfig: LibrettoDisplayData;
    init: LoadStatusType;
    splash: boolean;
    limited: boolean;
}

export const ApplicationContext = React.createContext<IApplicationContext>(
    undefined as any
);

export const ApplicationContextProvider: React.FC = () => {
    const { organizationId, displayId, hash } = useParams();

    const [session, setSession] = useState<string>();

    const timeout = 30_000;
    const retry = 3;
    const dashboardUrl = config.dashboardUrl;

    const applicationUrl = config.applicationUrl;
    const websocketUrl = `${applicationUrl}/websocket`;

    const gatewayUrl = config.gatewayUrl;

    const [splash, setSplash] = useState(true);
    const [init, setInit] = useState<LoadStatusType>('loading');

    const [limited, setLimited] = useState(true);

    const [displayConfig, setDisplayConfig] = useState<LibrettoDisplayData>({
        settings: {
            inactiveTime: INACTIVITY_TIMEOUT,
            eventInterval: EVENT_TIMEOUT
        }
    });

    const [websocket] = useState<{ app?: EbWebSocket }>(() => {
        return {
            app: new EbWebSocket({
                url: websocketUrl,
                timeout: 10_000,
                reconnectDelay: 5_000,
                debug: false
            })
        };
    });

    useBeforeunload(() => {
        navigator.sendBeacon(
            `${dashboardUrl}/api/v1/smartbridge/beacon/${session}`
        );
    });

    useEffect(() => {
        websocket?.app.init();
        return () => {
            if (websocket?.app.connected) {
                websocket?.app.close();
            }
        };
    }, [websocket]);

    useEffect(() => {
        const t = setInterval(() => {
            if (!websocket.app) {
                return;
            }

            setLimited(!websocket.app.connected || websocket.app.reconnecting);
        }, 5_000);
        return () => clearInterval(t);
    }, [websocket?.app]);

    const [api] = useState<{ dashboard: Axios; app?: Axios; gateway: Axios }>(
        () => {
            return {
                dashboard: AxiosUtil.create(dashboardUrl, timeout, retry),
                app: AxiosUtil.create(applicationUrl, timeout, retry),
                gateway: AxiosUtil.create(gatewayUrl, timeout, retry)
            };
        }
    );

    const value: IApplicationContext = useMemo(
        () => ({
            api,
            websocket,
            services: {
                smartBridge: new SmartBridgeService(api.dashboard),
                theme: new ThemeService(api.dashboard),
                subscription: new SubscriptionService(api.dashboard),
                payment: new PaymentService(api.app),
                iguana: new IguanaRestService(api.dashboard)
            },
            organizationId: parseInt(organizationId),
            displayId: parseInt(displayId),
            hash,
            session,
            displayConfig,
            init,
            splash,
            limited
        }),
        [
            api,
            displayConfig,
            displayId,
            hash,
            init,
            limited,
            organizationId,
            session,
            splash,
            websocket
        ]
    );

    const load = useCallback(async () => {
        console.log(`session ${session}`);
        if (session) {
            return;
        }

        try {
            const display = await value.services.smartBridge.login({
                organization: value.organizationId,
                display: value.displayId,
                hash: encodeURIComponent(hash)
            });

            // IN USE 226
            if (!display.session) {
                setInit('inuse');
                return;
            }

            setSession(display.session);
            setInit('success');
        } catch (e) {
            console.log(e);
            setInit('failed');
        }
    }, [
        hash,
        session,
        value.displayId,
        value.organizationId,
        value.services.smartBridge
    ]);

    const getDisplayData = useCallback(() => {
        if (session) {
            value.services.smartBridge
                .getDisplay(session)
                .then((data) => {
                    if (
                        JSON.stringify(displayConfig) !== JSON.stringify(data)
                    ) {
                        setDisplayConfig(data);
                    }
                })
                .catch((e) => console.error(e));
        }
    }, [displayConfig, session, value.services.smartBridge]);

    const heartbeat = useCallback(() => {
        if (session) {
            value.services.smartBridge
                .heartBeat(session)
                .catch((e) => console.error(e));
        }
    }, [session, value.services.smartBridge]);

    useEffect(() => {
        const startTime = session ? 300_000 : 0;

        const subscription = timer(startTime, 30_000).subscribe(heartbeat);
        return () => subscription.unsubscribe();
    }, [heartbeat, session]);

    useEffect(() => {
        if (session) {
            return undefined;
        }

        const subscription = timer(0, 30_000).subscribe(load);
        return () => subscription.unsubscribe();
    }, [load, session]);

    useEffect(() => {
        const startTime = displayConfig ? 300_000 : 0;
        const subscription = timer(startTime, 300_000).subscribe(
            getDisplayData
        );
        return () => subscription.unsubscribe();
    }, [displayConfig, getDisplayData, session]);

    useEffect(() => {
        if (!session) {
            return undefined;
        }

        const t = setTimeout(() => {
            setSplash(false);
        }, 1_000);
        return () => clearTimeout(t);
    }, [session]);

    return (
        <ApplicationContext.Provider value={value}>
            <UserActivityContextProvider>
                <KeyboardContextProvider>
                    <KioskContextProvider>
                        <OvaticContextProvider>
                            <SubscriptionContextProvider>
                                <>
                                    <KioskPreview />
                                    <SplashLoader />
                                    <InactiveModal />
                                    <ErrorNotification />
                                </>
                            </SubscriptionContextProvider>
                        </OvaticContextProvider>
                    </KioskContextProvider>
                </KeyboardContextProvider>
            </UserActivityContextProvider>
        </ApplicationContext.Provider>
    );
};
