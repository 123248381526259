import React, { useContext, useEffect } from 'react';
import { PaymentContext } from '../../payment/context/PaymentContext';
import { Button } from 'reactstrap';
import { formatMoney } from '../../util';
import { ApplicationContext } from '../../context/ApplicationContext';
import { CashierDisplay } from '../../payment/components/CashierDisplay';
import { UserActivityContext } from '../../context/UserActivityContext';

export interface PaymentProps {
    email: string;
    money: number;
    reference: {
        borrowerId: string;
        bookingCode: string;
        activationCode: string;
    };
}

export interface PaymentStepProps extends PaymentProps {
    nextStep();
}

export interface test extends PaymentStepProps {}

export const PaymentStep: React.FC<PaymentStepProps> = ({
    nextStep,
    email,
    money,
    reference
}) => {
    const {
        services: { payment }
    } = useContext(ApplicationContext);
    const { startTransaction, transaction, outOfOrder, success } =
        useContext(PaymentContext);
    const { resetActivity } = useContext(UserActivityContext);

    useEffect(() => {
        if (success) {
            nextStep();
        }
    }, [nextStep, success]);

    useEffect(() => {
        if (money === 0) {
            startTransaction({
                user: {
                    email: email
                },
                money: money,
                source: 'LMS',
                item: 'SUBSCRIPTION',
                reference: JSON.stringify(reference)
            });
        }
    }, [email, money, reference, startTransaction]);

    if (money === 0) {
        return <>Het activeren van uw account vereist geen betaling</>;
    }

    return (
        <>
            {!success && (
                <p>
                    Het activeren van uw account vereist een betaling
                    {money && (
                        <span>
                            {' '}
                            van <b>{formatMoney(money / 100)}</b>
                        </span>
                    )}
                </p>
            )}
            <CashierDisplay />
            {money &&
                email &&
                reference &&
                (!transaction || success === false) && (
                    <Button
                        className="fs-5"
                        color="danger"
                        onClick={() => {
                            resetActivity();
                            startTransaction({
                                user: {
                                    email: email
                                },
                                money: money,
                                source: 'LMS',
                                item: 'SUBSCRIPTION',
                                reference: JSON.stringify(reference)
                            });
                        }}
                        disabled={outOfOrder}
                    >
                        Start pin betaling
                    </Button>
                )}
            {transaction && success === undefined && (
                <Button
                    className="fs-5"
                    onClick={() => {
                        resetActivity();
                        payment.abort();
                    }}
                >
                    Annuleer pin betaling
                </Button>
            )}
        </>
    );
};
