export interface ImageReferenceData {
    id: string;
    checksum?: string;
}

export const KioskConstants = {
    STATIC_TOP_MARGIN: 3,
    STATIC_BOTTOM_MARGIN: 10,
    MAX_LOGO_POSITION: 10,
    DEFAULT_HEADER_MENU_SIZE: 6
};

export enum ThemeTemplate {
    HOME = 'HOME',
    DEFAULT = 'DEFAULT',
    LIBRETTO = 'LIBRETTO'
}

export type Themes = DefaultTheme | LibrettoTheme;

export interface ThemeBase {
    header: IHeader;
    subHeader: ISubHeader;
    body: IBody;
    footer: IFooter;
}

export interface HomeTheme extends ThemeBase {
    subject: string;
    divider: string;
}

export interface DefaultTheme extends ThemeBase {
    home: IHome;
    subject: string;
    divider: string;
}

export interface LibrettoTheme extends ThemeBase {
    home: IHome;
    filters: string[];
    details: IDetails;
}

interface IHeader {
    positionX: number;
    positionY: number;
    size: number;
    title: string;
    color: string;
    backgroundColor: string;
}

interface ISubHeader {
    title: string;
    color: string;
    backgroundColor: string;
}

interface IHome {
    title: string;
    icon: string;
}

interface IFooter {
    title: string;
    color: string;
}

export interface IBody extends IColorScheme {
    maxItems: number;
    itemBackgroundColor?: string;
}

interface IDetails {
    body: IBody;
    frame: IColorScheme;
}

interface IColorScheme {
    color: string;
    secondaryColor: string;
    backgroundColor?: string;
}
