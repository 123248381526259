import React, { useContext } from 'react';
import { OvaticActivitySeatData } from '../data/OvaticActivitySeatData';
import { Badge, Card, CardHeader, Table } from 'reactstrap';
import { OvaticActivityContext } from '../context/OvaticActivityContext';
import { getSeatStatus, getSeatStatusName } from '../data/OvaticSeatStatus';

export interface OvaticSelectedSeatProps {
    seat: OvaticActivitySeatData;
}

export const OvaticSelectedSeat: React.FC<OvaticSelectedSeatProps> = ({
    seat
}) => {
    const { getSectionById, getRankById } = useContext(OvaticActivityContext);

    const section = getSectionById(seat.sectionId);
    const rank = getRankById(seat.rankId);

    return (
        <Card>
            <CardHeader>
                Plek-r{seat.rowId}-p{seat.seatNumberInRow}
            </CardHeader>
            <Table className="mb-0">
                <tbody>
                    <tr>
                        <th>Sectie</th>
                        <td>{section?.description}</td>
                    </tr>
                    <tr>
                        <th>Rij</th>
                        <td>{seat.rowId}</td>
                    </tr>
                    <tr>
                        <th>Plek in rij</th>
                        <td>{seat.seatNumberInRow}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>{getSeatStatusName(getSeatStatus(seat))}</td>
                    </tr>
                    <tr>
                        <th>Rank</th>
                        <td>
                            <sup className="px-1">
                                <Badge
                                    pill
                                    color={null}
                                    style={{
                                        backgroundColor: rank.colors[0]
                                    }}
                                >
                                    &nbsp;
                                </Badge>
                            </sup>{' '}
                            {rank?.name}
                        </td>
                    </tr>
                    <tr>
                        <th>Prijs</th>
                        <td>
                            De definitieve prijs wordt bepaald als u de
                            ticket(s) toevoegd aan de winkelwagen
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    );
};
