import React from 'react';
import { KioskItem } from '../data/KioskItem';
import { OvaticItem } from '../../../ovatic/data/OvaticItem';
import { VSmartItem } from '../../../vsmart/data/VSmartItem';
import { OvaticAddress } from '../../../ovatic/component/OvaticAddress';

export interface KioskItemLocationProps {
    item: KioskItem;
}

export const KioskItemlocation: React.FC<KioskItemLocationProps> = ({
    item
}) => {
    if (item instanceof OvaticItem) {
        const building = item.data.building;
        return (
            <>
                {building?.name && <p className="mb-0">{building?.name}</p>}
                {building && (
                    <em>
                        <OvaticAddress address={building.address} />
                    </em>
                )}
            </>
        );
    } else if (item instanceof VSmartItem) {
        // TODO @Richard
    }
    return <>Theaterzaal</>;
};
