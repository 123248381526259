import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { ApplicationContext } from './ApplicationContext';
import { CollectionData } from '../shared/smartbridge/data/CollectionData';
import { ThemeData } from '../shared/smartbridge/data/ThemeData';
import { CalendarItemData } from '../shared/smartbridge/data/CalendarItemData';
import { UserActivityContext } from './UserActivityContext';
import { PaymentContextProvider } from '../payment/context/PaymentContext';
import { MediaType } from '../shared/smartbridge/data/MediaType';
import { LoadStatusType } from '../data/LoadStatusType';
import { PresentationItemData } from '../shared/smartbridge/data/PresentationItemData';
import { SmartBridgeEvent } from '../shared/smartbridge/components/SmartBridgeEvent';
import { EventStateType } from '../shared/smartbridge/data/EventStateType';
import { TermsOfServiceModal } from '../shared/kiosk/components/TermsOfServiceModal';

interface KioskContextType {
    toHome();

    page: CollectionData;

    goToPage(page: CollectionData);

    settings: KioskSettings;

    modules: CollectionData[];

    showTermsOfService: boolean;

    setShowTermsOfService(show: boolean);

    init: LoadStatusType;
    kioskSplash: boolean;

    eventState: EventStateType;

    setEventState(eventState: EventStateType);
}

export const KioskContext = React.createContext<KioskContextType>(
    undefined as any
);

interface KioskSettings {
    id: string;
    title: string;
    theme: ThemeData;
    items: CollectionData[];
}

export const KioskContextProvider: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {
    const { services, session, displayConfig, splash } =
        useContext(ApplicationContext);
    const { resetActivity, lastActivity } = useContext(UserActivityContext);

    const [settings, setSettings] = useState<KioskSettings>();

    const [presentation, setPresentation] = useState<CalendarItemData>();
    const [modules, setModules] = useState<CollectionData[]>();

    const [kioskSplash, setSplash] = useState(true);
    const [init, setInit] = useState<LoadStatusType>('loading');

    const [events, setEvents] = useState<PresentationItemData[]>([]);
    const [currentEvent, setCurrentEvent] = useState<PresentationItemData>();
    const [eventState, setEventState] = useState<EventStateType>();

    const [page, setPage] = useState<CollectionData>();

    const getPresentations = useCallback(() => {
        if (!session || splash) {
            return;
        }

        if (presentation) {
            return;
        }

        services.smartBridge
            .getPresentations(session)
            .then((data) => {
                if (data.length > 0) {
                    setTimeout(() => {
                        setPresentation(data[0]);
                    }, 1_000);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, [presentation, services.smartBridge, session, splash]);

    useEffect(() => {
        getPresentations();
    }, [getPresentations]);

    useEffect(() => {
        if (!presentation) {
            return;
        }

        if (!presentation.kiosk) {
            setInit('failed');
            return;
        }

        if (!presentation.theme) {
            setInit('failed');
            return;
        }

        if (settings) {
            return;
        }

        console.debug('Trying to fetch kiosk settings');

        services.theme
            .get(presentation.theme, session)
            .then((data) => {
                setTimeout(() => {
                    setSettings((prev) => {
                        if (
                            !prev ||
                            JSON.stringify(prev.theme) !== JSON.stringify(data)
                        ) {
                            return {
                                id: 'kiosk',
                                title: 'kiosk',
                                theme: data,
                                items: []
                            };
                        }
                        return prev;
                    });
                }, 1_000);
            })
            .catch((e) => {
                console.error(e);
                setInit('failed');
            });
    }, [presentation, services.theme, session, settings]);

    useEffect(() => {
        if (!presentation) {
            return;
        }

        if (!settings) {
            return;
        }

        if (modules) {
            return;
        }

        services.smartBridge
            .getPresentation(session, presentation.presentation)
            .then((data) => {
                console.log(data);

                const _modules = data
                    .filter((it) => it.media.type === MediaType.COLLECTION)
                    .map((it) => it.media as CollectionData);

                const _events = data.filter(
                    (it) =>
                        it.media.type === MediaType.IMAGE ||
                        it.media.type === MediaType.VIDEO
                );

                setModules(_modules);
                setEvents(_events);
                setInit('success');
                setTimeout(() => {
                    if (_modules.length > 0) {
                        setSplash(false);
                    }
                }, 1_000);
            })
            .catch((e) => {
                console.error(e);
                setInit('failed');
            });
    }, [modules, presentation, services.smartBridge, session, settings]);

    const toHome = useCallback(() => {
        resetActivity();
        setPage(null);
        setShowTermsOfService(false);
    }, [resetActivity]);

    const goToPage = useCallback(
        (page: CollectionData) => {
            resetActivity();
            setPage((prev) => {
                if (JSON.stringify(prev) !== JSON.stringify(page)) {
                    return page;
                }
                return prev;
            });
            setShowTermsOfService(false);
        },
        [resetActivity]
    );

    useEffect(() => {
        if (lastActivity) {
            setEventState('finished');
        }
    }, [lastActivity]);

    useEffect(() => {
        if (page) {
            setEventState('finished');
            return undefined;
        }

        if (events.length === 0 || eventState === 'playing') {
            return undefined;
        }

        const t = setInterval(() => {
            if (!currentEvent) {
                setCurrentEvent(events[0]);
                return;
            }

            const index = events.findIndex(
                (it) => it.media.id === currentEvent.media.id
            );
            if (index === -1) {
                setCurrentEvent(events[0]);
                return;
            }

            if (index >= events.length - 1) {
                setCurrentEvent(events[0]);
                return;
            }

            setCurrentEvent(events[index + 1]);
        }, displayConfig.settings.eventInterval * 1000);

        return () => clearInterval(t);
    }, [
        currentEvent,
        displayConfig.settings.eventInterval,
        eventState,
        events,
        page
    ]);

    const [showTermsOfService, setShowTermsOfService] = useState(false);

    const value: KioskContextType = useMemo(
        () => ({
            toHome,
            page,
            goToPage,
            settings,
            modules,
            showTermsOfService,
            setShowTermsOfService,
            init,
            kioskSplash: kioskSplash,
            eventState,
            setEventState
        }),
        [
            toHome,
            page,
            goToPage,
            settings,
            modules,
            showTermsOfService,
            init,
            kioskSplash,
            eventState
        ]
    );

    return (
        <KioskContext.Provider value={value}>
            <PaymentContextProvider>
                <>
                    <SmartBridgeEvent event={currentEvent} />
                    {children}
                </>
            </PaymentContextProvider>
            <TermsOfServiceModal />
        </KioskContext.Provider>
    );
};
