import { OvaticActivityData } from './OvaticActivityData';
import { KioskItem } from '../../shared/kiosk/data/KioskItem';

export class OvaticItem implements KioskItem {
    constructor(public data: OvaticActivityData) {}

    getImageUrl(): string | undefined {
        const images = this.data.images;
        if (images.length) {
            return images[0].imageUrl;
        }
        return 'https://agenda.ticketunie.com/img/thumbnail.ashx?trsid=416&id=6734&imagegroup=1';
    }

    getName(): string {
        return this.data.title;
    }

    getId(): string {
        return this.data.id;
    }

    getDoorsOpenDate(): Date {
        return new Date(this.data.doorsOpen);
    }

    getEndDate(): Date {
        return new Date(this.data.endDate);
    }

    getStartDate(): Date {
        return new Date(this.data.startDate);
    }

    getSubtitle(): string {
        return this.data.subtitle;
    }

    getType(): { code: string; id: string; name: string } | undefined {
        return this.data.type;
    }
}
