import React, { useContext } from 'react';
import { DefaultTheme, HomeTheme } from '../data/KioskData';
import { UserActivityContext } from '../../../context/UserActivityContext';

export const ThemeMenuItem: React.FC<{
    item: { image: string; name: string; subtitle: string };
    settings: HomeTheme | DefaultTheme;
    action?();
}> = ({ item, settings, action }) => {
    const { resetActivity } = useContext(UserActivityContext);
    return (
        <div
            className="d-flex flex-row"
            style={{
                backgroundColor: settings.body.itemBackgroundColor,
                padding: '3.5% 8%',
                borderBottom: '1px solid',
                borderColor: settings.divider,
                height: `calc(100%/${settings.body.maxItems})`
            }}
            onClick={() => {
                resetActivity();
                action?.();
            }}
        >
            <div
                className=""
                style={{
                    marginRight: '5%'
                }}
            >
                <img
                    style={{
                        height: '100%',
                        aspectRatio: '1/1',
                        objectFit: 'cover'
                    }}
                    src={item.image}
                    alt={item.name}
                />
            </div>
            <div
                className="d-flex flex-column justify-content-center"
                style={{
                    lineHeight: '420%',
                    color: settings.body.color
                }}
            >
                <span
                    className="font-weight-bold"
                    style={{
                        fontSize: '420%'
                    }}
                >
                    {item.name}
                </span>
                <span
                    style={{
                        fontSize: '315%'
                    }}
                >
                    {item.subtitle}
                </span>
            </div>
            <div className="d-flex flex-column align-items-end justify-content-center flex-fill">
                <span
                    className="font-weight-bold"
                    style={{
                        fontSize: '1050%',
                        color: settings.body.secondaryColor
                    }}
                >
                    {'>'}
                </span>
            </div>
        </div>
    );
};
