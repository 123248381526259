import React, { useContext } from 'react';
import { OvaticContext } from '../context/OvaticContext';
import { OvaticCartSummary } from './OvaticCartSummary';
import { Alert, Button, Col, Row } from 'reactstrap';
import { PaymentContext } from '../../payment/context/PaymentContext';
import { Errors } from '../../errors/Errors';
import { UserActivityContext } from '../../context/UserActivityContext';

export interface OvaticCartSummaryStepProps {
    goStepBack();
}

export const OvaticCartSummaryStep: React.FC<OvaticCartSummaryStepProps> = ({
    goStepBack
}) => {
    const { account, logout } = useContext(OvaticContext);
    const { outOfOrder } = useContext(PaymentContext);
    const { resetActivity } = useContext(UserActivityContext);

    return (
        <div>
            <Row className="mb-3">
                <Col md={8}>
                    <p>
                        U bent ingelogd met het account:{' '}
                        <strong>{account?.email}</strong>
                    </p>
                    <p>
                        Controleer nog even of uw bestelling klopt en u ingelogd
                        bent met het juiste e-mailadres.
                        <br />
                        De tickets ontvangt u na de betaling op het e-mailadres:{' '}
                        <strong>{account?.email}</strong>
                    </p>
                </Col>
                <Col md={4} style={{ textAlign: 'right' }}>
                    <Button
                        className="fs-5"
                        color="primary"
                        onClick={() => {
                            resetActivity();
                            logout();
                            goStepBack();
                        }}
                    >
                        <i className="fa fa-right-from-bracket" /> Verander van
                        account
                    </Button>
                </Col>
            </Row>

            <OvaticCartSummary readOnly />
            {outOfOrder && <Alert color="danger">{Errors.pin}</Alert>}
        </div>
    );
};
