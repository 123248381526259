import { Axios } from 'axios';
import { SmartBridgeLoginData } from '../data/SmartBridgeLoginData';
import { SmartBridgeLoginResponse } from '../data/SmartBridgeLoginResponse';
import { CalendarItemData } from '../../../shared/smartbridge/data/CalendarItemData';
import { PresentationItemData } from '../../../shared/smartbridge/data/PresentationItemData';
import { LibrettoDisplayData } from '../../../shared/smartbridge/data/LibrettoDisplayData';

export class SmartBridgeService {
    axios: Axios;

    constructor(axios: Axios) {
        this.axios = axios;
    }

    login(data: SmartBridgeLoginData): Promise<SmartBridgeLoginResponse> {
        return this.axios
            .post<SmartBridgeLoginResponse>(`api/v1/smartbridge/login`, null, {
                params: data
            })
            .then((res) => res.data);
    }

    getDisplay(session: string): Promise<LibrettoDisplayData> {
        return this.axios
            .get<LibrettoDisplayData>(
                `api/v1/smartbridge/display?session=${session}`
            )
            .then((res) => res.data);
    }

    heartBeat(session: string): Promise<void> {
        return this.axios
            .post<void>(`api/v1/smartbridge/heartbeat?session=${session}`)
            .then((res) => res.data);
    }

    getImage(id: string, checksum?: string) {
        return `${this.axios.defaults.baseURL}/api/v1/images/${id}${
            checksum ? `?checksum=${checksum}` : ''
        }`;
    }

    getVideo(id: string, checksum?: string) {
        return `${this.axios.defaults.baseURL}/api/v1/videos/${id}/stream/${
            checksum ? `?checksum=${checksum}` : ''
        }`;
    }

    getPresentations(session: string): Promise<CalendarItemData[]> {
        return this.axios
            .get<CalendarItemData[]>(
                `api/v1/smartbridge/presentations?session=${session}`
            )
            .then((res) => res.data);
    }

    getPresentation(
        session: string,
        id: string
    ): Promise<PresentationItemData[]> {
        return this.axios
            .get<PresentationItemData[]>(
                `api/v1/smartbridge/presentations/${id}?session=${session}`
            )
            .then((res) => res.data);
    }
}
