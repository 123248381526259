import { Axios } from 'axios';
import { PaymentRequest } from '../data/PaymentRequest';
import { PaymentTransactionData } from '../data/PaymentTransactionData';

export class PaymentService {
    axios: Axios;

    constructor(axios: Axios) {
        this.axios = axios;
    }

    startTransaction(data: PaymentRequest): Promise<PaymentTransactionData> {
        return this.axios
            .post<any>(`/v1/session/payment`, data)
            .then((res) => res.data);
    }

    getTransaction(id: string): Promise<PaymentTransactionData> {
        return this.axios
            .get<PaymentTransactionData>(`/v1/payment/transaction/${id}`)
            .then((res) => res.data);
    }

    abort(): Promise<any> {
        return this.axios.delete(`/v1/payment`).then((res) => res.data);
    }
}
