import React, { useContext, useMemo } from 'react';
import { OvaticActivityContext } from '../context/OvaticActivityContext';
import { Spinner } from 'reactstrap';
import { OvaticActivitySeat } from './OvaticActivitySeat';
import { useResizeDetector } from 'react-resize-detector';
import { OvaticSeatPlanLegend } from './OvaticSeatPlanLegend';

export interface OvaticActivitySeatPlanProps {
    backgroundColor?: string;

    /**
     * Optional array of seat numbers to highlight.
     * All other seats will be greyed out.
     */
    highlightSeats?: number[];
}

export const OvaticActivitySeatPlan: React.FC<OvaticActivitySeatPlanProps> = ({
    backgroundColor = '#FFF',
    highlightSeats
}) => {
    const { seats } = useContext(OvaticActivityContext);
    const { width = 100, height = 100, ref } = useResizeDetector();

    const maxSeatCoordinates = useMemo(() => {
        let x = 100;
        let y = 100;

        if (seats) {
            seats.zones.forEach((zone) => {
                zone.seats.forEach((seat) => {
                    if (seat.coordinateX > x) {
                        x = seat.coordinateX;
                    }
                    if (seat.coordinateY > y) {
                        y = seat.coordinateY;
                    }
                });
            });
        }
        return { x, y };
    }, [seats]);

    let content: React.ReactNode;

    if (!seats) {
        content = (
            <div
                style={{
                    width,
                    height,
                    backgroundColor
                }}
                className="d-flex justify-content-center align-items-center"
            >
                <div className="p-3 text-center">
                    <span>
                        <Spinner
                            color="primary"
                            size="sm"
                            style={{ marginRight: 5 }}
                        />{' '}
                        Het zitplan wordt geladen...
                    </span>
                </div>
            </div>
        );
    } else {
        const maxX = maxSeatCoordinates.x + 10;
        const maxY = maxSeatCoordinates.y + 10;
        let scale = width / maxX;
        let scaleY = height / maxY;
        if (scaleY < scale) {
            scale = scaleY;
        }

        if (isNaN(scale)) {
            scale = 1;
        }

        const scaledWidth = width / scale;
        const scaledHeight = height / scale;

        let translateX = -(maxX - scaledWidth) / 2;
        let translateY = -(maxY - scaledHeight) / 2;

        const transform = `scale(${scale}) translate(${translateX}, ${translateY})`;
        content = (
            <svg
                width={width}
                height={height}
                style={{
                    backgroundColor
                }}
            >
                <g transform={transform}>
                    {seats.zones.flatMap((zone) => {
                        return zone.seats.map((seat) => (
                            <OvaticActivitySeat
                                key={seat.seatNumber}
                                seat={seat}
                                highlight={highlightSeats?.includes(
                                    seat.seatNumber
                                )}
                            />
                        ));
                    })}
                </g>
            </svg>
        );
    }

    return (
        <div className="d-flex flex-column" style={{ height: '100%' }}>
            <div
                ref={ref}
                className="flex-grow-1"
                style={{ width: '100%', overflow: 'hidden' }}
            >
                {content}
            </div>
            <OvaticSeatPlanLegend />
        </div>
    );
};
