import { Axios } from 'axios';
import { SubscriptionData } from '../../smartbridge/data/SubscriptionData';

export class SubscriptionService {
    axios: Axios;

    constructor(axios: Axios) {
        this.axios = axios;
    }

    fetchAllOfOrganization(session: string): Promise<SubscriptionData[]> {
        return this.axios
            .get<SubscriptionData[]>(
                `api/v1/smartbridge/subscriptions?session=${session}`
            )
            .then((res) => res.data.filter((it) => !it.disable));
    }

    get(session: string, id: string): Promise<SubscriptionData> {
        return this.axios
            .put<SubscriptionData>(
                `api/v1/smartbridge/subscriptions/${id}?session=${session}`
            )
            .then((res) => res.data);
    }
}
