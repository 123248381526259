import { AxiosError } from 'axios';
import { HttpStatus } from './HttpStatus';

export enum ErrorCode {
    REQUIRED = 'REQUIRED',
    CONFLICT = 'CONFLICT',
    EXTERNAL_MAIL_BLOCKED = 'EXTERNAL_MAIL_BLOCKED',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    OVATIC_CART_CANCELLED = 'OVATIC_CART_CANCELLED',
    OVATIC_SEATS_NOT_AVAILABLE = 'OVATIC_SEATS_NOT_AVAILABLE',
    OVATIC_NOT_ENOUGH_SEATS_TOGETHER = 'OVATIC_NOT_ENOUGH_SEATS_TOGETHER',
    OVATIC_NOT_ENOUGH_FREE_SEATS = 'OVATIC_NOT_ENOUGH_FREE_SEATS',
    OVATIC_ACCOUNT_ALREADY_EXISTS = 'OVATIC_ACCOUNT_ALREADY_EXISTS',
    OVATIC_INVALID_CREDENTIALS = 'OVATIC_INVALID_CREDENTIALS',
    OVATIC_EMAIL_UNKNOWN = 'OVATIC_EMAIL_UNKNOWN'
}

interface ErrorDetail {
    code: ErrorCode;
    field?: string;
    detail?: string;
}

interface TechnicalErrorDetail {
    label: string;
    detail: any;
}

export class EbError extends Error {
    errors?: ErrorDetail[];
    details?: TechnicalErrorDetail[];
    data?: any;

    /**
     *
     * @param status HTTP status
     * @param error Detailed util field
     * @param message Optional util message
     */
    constructor(
        public status: number,
        public error?: string,
        message?: string
    ) {
        super(message || `An error occurred (status=${status})`);
    }

    static fromAxios(e: any): EbError {
        if (e instanceof EbError) {
            // Backwards compatibility. We introduced request interceptor that performs fromAxios on util responses
            // Also when we catch an util we are not sure if it is an ApiError or e.g. NPE, so we always have to perform fromAxios() call
            return e;
        }
        const details: TechnicalErrorDetail[] = [];
        if (e instanceof Error) {
            details.push({
                label: 'Error message',
                detail: e.message
            });
            const axiosError = e as AxiosError<EbError>;
            if (axiosError.isAxiosError) {
                details.push({
                    label: 'Type',
                    detail: 'Axios (HTTP request)'
                });
                const config = axiosError.config;
                if (config) {
                    const labelMapping = {
                        baseURL: 'Base URL',
                        url: 'Path',
                        method: 'HTTP method',
                        timeout: 'Timeout (ms)'
                    };

                    Object.entries(labelMapping).forEach(([key, label]) => {
                        details.push({
                            label,
                            detail: axiosError.config[key]
                        });
                    });
                }

                const response = axiosError.response;

                details.push({
                    label: 'Response code',
                    detail: response ? response.status : 'No response'
                });

                details.push({
                    label: 'Response',
                    detail: response ? response.data : 'No response'
                });
                if (response && response.status) {
                    const apiError = new EbError(response.status);
                    apiError.details = details;
                    apiError.data = axiosError.response?.data;

                    try {
                        const errors = axiosError.response?.data?.errors;
                        if (Array.isArray(errors)) {
                            apiError.errors = errors;
                        }
                    } catch (e) {
                        // Silence
                    }

                    return apiError;
                } else {
                    if (axiosError.message.toLowerCase().includes('timeout')) {
                        const apiError = new EbError(HttpStatus.TIMEOUT);
                        apiError.details = details;
                        apiError.data = axiosError.response?.data;
                        return apiError;
                    } else if (
                        axiosError.message.toLowerCase().includes('network')
                    ) {
                        // Most likely a network util
                        const apiError = new EbError(
                            HttpStatus.ERROR_UNAVAILABLE,
                            'NETWORK'
                        );
                        apiError.details = details;
                        apiError.data = axiosError.response?.data;
                        return apiError;
                    }
                }
            }
        }
        const apiError = new EbError(HttpStatus.ERROR, 'UNKNOWN');
        apiError.details = e.message;
        return apiError;
    }

    getErrors(): ErrorDetail[] {
        if (Array.isArray(this.errors)) {
            return this.errors;
        }
        return [];
    }

    getFieldErrors(field: string): ErrorDetail[] {
        return this.getErrors().filter((err) => err.field === field);
    }

    hasFieldErrorCode(field: string, code: ErrorCode): boolean {
        return this.getFieldErrors(field).some((error) => error.code === code);
    }

    hasErrorCode(code: ErrorCode): boolean {
        return this.getErrors().some((error) => error.code === code);
    }
}
