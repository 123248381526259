import React, { useContext } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { KioskContext } from '../../../context/KioskContext';
import { UserActivityContext } from '../../../context/UserActivityContext';

export const TermsOfServiceModal: React.FC = () => {
    const { showTermsOfService, setShowTermsOfService } =
        useContext(KioskContext);
    const { resetActivity } = useContext(UserActivityContext);
    const closeModal = () => setShowTermsOfService(false);
    return (
        <Modal
            centered
            isOpen={showTermsOfService}
            toggle={closeModal}
            size="lg"
        >
            <ModalHeader>Algemene voorwaarden</ModalHeader>
            <ModalBody style={{ aspectRatio: 1 / 1.41 }}>
                <object
                    style={{ height: '100%' }}
                    data="/assets/Privacyverklaring Eastbridge & Cultura Ede.pdf#view=FitW&scrollbar=0&toolbar=0&statusbar=0&messages=0&navpanes=0"
                    type="application/pdf"
                    width="100%"
                    height="500px"
                >
                    <p>Algemene voorwaarden konden niet worden weergeven.</p>
                </object>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => {
                        resetActivity();
                        closeModal();
                    }}
                >
                    Sluit algemene voorwaarden
                </Button>
            </ModalFooter>
        </Modal>
    );
};
