import React from 'react';
import { DefaultTheme, HomeTheme } from '../data/KioskData';

export const ThemeDummyMenuItem: React.FC<{
    settings: HomeTheme | DefaultTheme;
}> = ({ settings }) => {
    const item = {
        title: 'BIBLIOTHEEK',
        subtitle: 'Iedereen is welkom'
    };

    const imageUrl = '/assets/img/borat.jpg';

    return (
        <div
            className="d-flex flex-row"
            style={{
                backgroundColor: settings.body.itemBackgroundColor,
                padding: '3.5% 8%',
                borderBottom: '1px solid',
                borderColor: settings.divider,
                height: `calc(100%/${settings.body.maxItems})`
            }}
        >
            <div
                className=""
                style={{
                    marginRight: '5%'
                }}
            >
                <img
                    alt={item.title}
                    style={{
                        height: '100%',
                        aspectRatio: '1/1',
                        objectFit: 'cover'
                    }}
                    src={imageUrl}
                />
            </div>
            <div
                className="d-flex flex-column justify-content-center"
                style={{
                    lineHeight: '420%',
                    color: settings.body.color
                }}
            >
                <span
                    className="font-weight-bold"
                    style={{
                        fontSize: '420%'
                    }}
                >
                    {item.title}
                </span>
                <span
                    style={{
                        fontSize: '315%'
                    }}
                >
                    {item.subtitle}
                </span>
            </div>
            <div className="d-flex flex-column align-items-end justify-content-center flex-fill">
                <span
                    className="font-weight-bold"
                    style={{
                        fontSize: '1050%',
                        color: settings.body.secondaryColor
                    }}
                >
                    {'>'}
                </span>
            </div>
        </div>
    );
};
