import {
    Button,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner
} from 'reactstrap';
import { OvaticActivitySeatPlan } from './OvaticActivitySeatPlan';
import { OvaticSelectedSeats } from './OvaticSelectedSeats';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { OvaticActivityContext } from '../context/OvaticActivityContext';
import { OvaticContext } from '../context/OvaticContext';
import { EbError, ErrorCode } from '../../shared/axios/EbError';
import { OvaticPriceSummary } from './OvaticPriceSummary';
import { UserActivityContext } from '../../context/UserActivityContext';
import { getSeatStatus, OvaticSeatStatus } from '../data/OvaticSeatStatus';
import { formatMoney } from '../../util';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';

export interface OvaticOrderActivityModalProps {
    isOpen: boolean;

    toggle();
}

export const OvaticOrderActivityModal: React.FC<
    OvaticOrderActivityModalProps
> = ({ isOpen, toggle }) => {
    const {
        seats,
        activity,
        selectedSeats,
        prices,
        selectSeats,
        loadSeats,
        selectedRank,
        getRankById,
        getAvailableSeatCountForRank
    } = useContext(OvaticActivityContext);
    const { addTicketToCart, handleError, setShowCart, logout } =
        useContext(OvaticContext);
    const [loading, setLoading] = useState(false);
    const [selectedPriceId, setSelectedPriceId] = useState<string>();
    const selectedPrice = useMemo(
        () => prices?.find((price) => price.id === selectedPriceId),
        [selectedPriceId, prices]
    );
    const { resetActivity } = useContext(UserActivityContext);
    const [manualSeatSelection, setManualSeatSelection] = useState(false);
    const [numberOfTickets, setNumberOfTickets] = useState(1);
    const [selectedRankId, setSelectedRankId] = useState<number>();

    useEffect(() => {
        if (isOpen) {
            // Load latest seat information, which can be stale after e.g. cart cancellation
            loadSeats().catch((err) => {
                console.error('Failed to load seats', err);
            });
        }
    }, [isOpen, loadSeats]);

    useEffect(() => {
        if (!prices || prices.length === 0) {
            return;
        }
        const isValid = prices.some((price) => price.id === selectedPriceId);
        if (!isValid) {
            const defaultPrice =
                prices.find(
                    (price) => price.name.toLowerCase() === 'normaal'
                ) || prices[0];
            setSelectedPriceId(defaultPrice.id);
        }
    }, [prices, selectedPriceId]);

    const isPlaced = activity && activity.seatPlan?.placed;

    useEffect(() => {
        if (manualSeatSelection && !isPlaced) {
            // No manual seat selection allowed
            setManualSeatSelection(false);
        }
    }, [manualSeatSelection, isPlaced]);

    useEffect(() => {
        if (manualSeatSelection) {
            setNumberOfTickets(selectedSeats.length);
        } else {
            if (numberOfTickets < 1) {
                setNumberOfTickets(1);
            }
            if (selectedSeats.length) {
                // Clear seat selection
                selectSeats([]);
            }
        }
    }, [manualSeatSelection, selectedSeats, numberOfTickets, selectSeats]);

    useEffect(() => {
        if (manualSeatSelection) {
            if (selectedRank) {
                setSelectedRankId(selectedRank);
            }
        } else {
            const ranks = selectedPrice?.ranks;
            if (!selectedRankId && ranks?.length) {
                // TODO select cheapest available rank
                setSelectedRankId(ranks[0].rankId);
            }
        }
    }, [selectedRank, manualSeatSelection, selectedPrice, selectedRankId]);

    let highlightSeats: number[] = undefined;
    if (!manualSeatSelection && selectedRankId && seats) {
        highlightSeats = seats.zones
            .flatMap((zone) => zone.seats)
            .filter(
                (seat) =>
                    selectedRankId === seat.rankId &&
                    getSeatStatus(seat) === OvaticSeatStatus.AVAILABLE
            )
            .map((seat) => seat.seatNumber);
    }

    return (
        <Modal
            isOpen={isOpen}
            size="xl"
            toggle={toggle}
            onOpened={() => {
                if (loading) {
                    // Reset loading state
                    setLoading(false);
                }
            }}
            centered
            backdrop="static"
        >
            <ModalHeader>
                <span className="fs-2">
                    Bestel tickets voor {activity?.title}
                </span>
            </ModalHeader>
            <ModalBody>
                {isPlaced && (
                    <FormGroup>
                        <Label>Wil je zelf je zitplaats selecteren:</Label>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="ovatic-select-ticket-seat-label">
                                Zitplaats
                            </InputLabel>
                            <Select
                                labelId="ovatic-select-ticket-seat-label"
                                id="ovatic-select-ticket-seat"
                                label="Zitplaats"
                                value={manualSeatSelection ? '1' : '0'}
                                onChange={(e) => {
                                    setManualSeatSelection(
                                        e.target.value === '1'
                                    );
                                }}
                            >
                                <MenuItem key="0" value="0">
                                    Kies automatisch voor mij de beste zitplaats
                                </MenuItem>
                                <MenuItem key="1" value="1">
                                    Ik wil zelf de zitplaats bepalen
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                )}
                {!manualSeatSelection && (
                    <FormGroup>
                        <Label>Hoeveel tickets wil je bestellen?</Label>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="ovatic-select-ticket-amount-label">
                                Aantal
                            </InputLabel>
                            <Select
                                labelId="ovatic-select-ticket-amount-label"
                                id="ovatic-select-ticket-amount"
                                label="Aantal"
                                value={numberOfTickets}
                                onChange={(e) => {
                                    setNumberOfTickets(+e.target.value);
                                }}
                            >
                                {(() => {
                                    const options = [];
                                    for (
                                        let i = 1;
                                        i <= activity.maxTicketsPerTransaction;
                                        i++
                                    ) {
                                        options.push(i);
                                    }
                                    return options.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ));
                                })()}
                            </Select>
                        </FormControl>
                    </FormGroup>
                )}
                <FormGroup>
                    <Label>Welke prijs soort is van toepassing?</Label>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="ovatic-select-ticket-sort-label">
                            Soort
                        </InputLabel>
                        <Select
                            labelId="ovatic-select-ticket-sort-label"
                            id="ovatic-select-ticket-sort"
                            label="Soort"
                            value={selectedPriceId}
                            onChange={(e) => {
                                resetActivity();
                                setSelectedPriceId(e.target.value);
                            }}
                        >
                            {prices?.map((price) => (
                                <MenuItem key={price.id} value={price.id}>
                                    {price.name}
                                    {typeof price.amountAvailable ===
                                        'number' &&
                                        ` (${
                                            price.moreAvailable
                                                ? `${price.amountAvailable}+ beschikbaar`
                                                : `${price.amountAvailable} beschikbaar`
                                        })`}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                            Kies de prijs soort waarmee u de tickets wilt kopen.
                            Aan de verschillende prijssoorten kunnen voorwaarden
                            zijn gebonden.
                        </FormHelperText>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <Label>Welke rang wil je zitten?</Label>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="ovatic-select-ticket-rank-label">
                            Rang
                        </InputLabel>
                        <Select
                            labelId="ovatic-select-ticket-rank-label"
                            id="ovatic-select-ticket-rank"
                            label="Rang"
                            value={selectedRankId}
                            onChange={(e) => {
                                setSelectedRankId(+e.target.value);
                            }}
                        >
                            {selectedPrice?.ranks?.map((priceRank) => {
                                const rank = getRankById(priceRank.rankId);
                                let rankLabel = formatMoney(priceRank.price);
                                if (rank?.name) {
                                    rankLabel = `${rank.name} - ${rankLabel}`;
                                }
                                let availableSeatCountForRank =
                                    getAvailableSeatCountForRank(
                                        priceRank.rankId
                                    );
                                if (
                                    activity.seatPlan?.placed === false &&
                                    availableSeatCountForRank === 0
                                ) {
                                    availableSeatCountForRank =
                                        selectedPrice.amountAvailable;
                                }
                                return (
                                    <MenuItem
                                        key={priceRank.rankId}
                                        value={priceRank.rankId}
                                        disabled={
                                            activity.seatPlan?.placed ===
                                                false &&
                                            availableSeatCountForRank === 0
                                        }
                                    >
                                        {rankLabel} (
                                        {availableSeatCountForRank > 0
                                            ? `${availableSeatCountForRank} beschikbaar`
                                            : 'Uitverkocht'}
                                        )
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>
                            Kies de rang waarmee u de tickets wilt kopen. De
                            rang bepaalt waar je komt te zitten.
                        </FormHelperText>
                    </FormControl>
                </FormGroup>

                {isPlaced && (
                    <>
                        {selectedPrice && (
                            <OvaticPriceSummary price={selectedPrice} />
                        )}
                        {manualSeatSelection ? (
                            <p>
                                Selecteer de plekken die u wilt reserveren. De
                                prijs van plekken kan verschillen, vanwege
                                bijvoorbeeld beter zicht of de afstand tot het
                                podium.
                            </p>
                        ) : (
                            <p>
                                Met de huidig geselecteerde rang zal u een plek
                                krijgen in het gekleurde gedeelte. Wilt u een
                                stoel in het grijze gebied, selecteer dan een
                                andere rang of kies ervoor om zelf uw
                                zitplaatsen uit te kiezen.
                            </p>
                        )}
                        <div style={{ height: 600 }}>
                            <OvaticActivitySeatPlan
                                highlightSeats={highlightSeats}
                            />
                        </div>
                    </>
                )}
                {manualSeatSelection && <OvaticSelectedSeats />}
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={loading}
                    onClick={() => {
                        resetActivity();
                        toggle();
                        logout();
                    }}
                >
                    Annuleren
                </Button>
                <Button
                    color="primary"
                    onClick={async () => {
                        resetActivity();
                        try {
                            setLoading(true);
                            const priceTypes = [];
                            for (let i = 0; i < numberOfTickets; i++) {
                                priceTypes.push(selectedPriceId);
                            }
                            await addTicketToCart({
                                activityId: activity.id,
                                numberOfTickets: numberOfTickets,
                                priceTypes,
                                rankId: selectedRankId,
                                seats: selectedSeats.map(
                                    (seat) => seat.seatNumber
                                )
                            });
                            // Clear selection
                            selectSeats([]);
                            toggle();
                            setShowCart(true);
                        } catch (e) {
                            const error = EbError.fromAxios(e);
                            handleError(error);
                            if (
                                error.hasErrorCode(
                                    ErrorCode.OVATIC_SEATS_NOT_AVAILABLE
                                )
                            ) {
                                // Clear selection as some of the selected seats are no longer available
                                selectSeats([]);
                            }
                        } finally {
                            setLoading(false);
                            await loadSeats();
                        }
                    }}
                    disabled={numberOfTickets === 0 || loading}
                >
                    {loading && <Spinner className="mr-2" size="sm" />}
                    Voeg tickets toe aan winkelwagen
                </Button>
            </ModalFooter>
        </Modal>
    );
};
