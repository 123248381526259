import React, { useRef, useState } from 'react';

export const ThemeResizeWrapper: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {
    const ref = useRef<HTMLDivElement>();

    const [fontSize] = useState(`${(1 / 16) * 9}vh`);

    /*const handleResize = useCallback(() => {
    if (ref?.current?.getBoundingClientRect()) {
        setFontSize(
            `${ref.current.getBoundingClientRect().height / 1920}vh`
        );
    }
}, [ref]);*/

    /*useEffect(() => {
    const current = ref?.current;

    handleResize();

    window.addEventListener('resize', handleResize);
    current?.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
        current?.removeEventListener('resize', handleResize);
    };
}, [handleResize, ref]);*/

    return (
        <div ref={ref} style={{ fontSize }}>
            {children}
        </div>
    );
};
