import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner
} from 'reactstrap';
import { OvaticCartSummary } from './OvaticCartSummary';
import { OvaticContext } from '../context/OvaticContext';
import { OvaticCartAccountStep } from './OvaticCartAccountStep';
import { OvaticCartSummaryStep } from './OvaticCartSummaryStep';
import { OvaticCartPaymentStep } from './OvaticCartPaymentStep';
import { PaymentContext } from '../../payment/context/PaymentContext';
import { OvaticCartFinishStep } from './OvaticCartFinishStep';
import { UserActivityContext } from '../../context/UserActivityContext';

export enum OvaticCartStep {
    OVERVIEW,
    ACCOUNT,
    SUMMARY,
    PAYMENT,
    FINISH
}

export const OvaticCartModal: React.FC = () => {
    const { cart, showCart, setShowCart, cancelCart, account, logout } =
        useContext(OvaticContext);
    const { reset, outOfOrder } = useContext(PaymentContext);
    const [cancelling, setCancelling] = useState(false);
    const [step, setStep] = useState(OvaticCartStep.OVERVIEW);
    const { resetActivity } = useContext(UserActivityContext);

    useEffect(() => {
        setStep(OvaticCartStep.OVERVIEW);
    }, [showCart]);

    useEffect(() => {
        if (step === OvaticCartStep.ACCOUNT && account) {
            setStep(OvaticCartStep.SUMMARY);
        }
    }, [account, step]);

    return (
        <Modal
            isOpen={showCart}
            size="xl"
            toggle={() => {
                reset();
                setShowCart(false);
            }}
            centered
            backdrop="static"
        >
            <ModalHeader>
                <span className="fs-2">Winkelwagen</span>
            </ModalHeader>
            <ModalBody className="fs-5">
                {step === OvaticCartStep.OVERVIEW && <OvaticCartSummary />}
                {step === OvaticCartStep.ACCOUNT && <OvaticCartAccountStep />}
                {step === OvaticCartStep.SUMMARY && (
                    <OvaticCartSummaryStep
                        goStepBack={() => setStep(OvaticCartStep.ACCOUNT)}
                    />
                )}
                {step === OvaticCartStep.PAYMENT && (
                    <OvaticCartPaymentStep
                        goStepBack={() => setStep(OvaticCartStep.SUMMARY)}
                        goStepForward={() => setStep(OvaticCartStep.FINISH)}
                    />
                )}
                {step === OvaticCartStep.FINISH && <OvaticCartFinishStep />}
            </ModalBody>
            <ModalFooter>
                {step === OvaticCartStep.OVERVIEW && (
                    <Button
                        className="fs-5"
                        color="primary"
                        onClick={() => {
                            resetActivity();
                            setStep(OvaticCartStep.ACCOUNT);
                        }}
                        disabled={cart?.ticketCount === 0}
                    >
                        <i className="fa fa-arrow-right" /> Doorgaan met
                        bestelling
                    </Button>
                )}
                {step === OvaticCartStep.SUMMARY && !outOfOrder && (
                    <Button
                        className="fs-5"
                        color="primary"
                        onClick={() => {
                            reset();
                            resetActivity();
                            setStep(OvaticCartStep.PAYMENT);
                        }}
                    >
                        <i className="fa fa-credit-card" /> Betalen
                    </Button>
                )}
                {step !== OvaticCartStep.PAYMENT &&
                    step !== OvaticCartStep.FINISH && (
                        <Button
                            className="fs-5"
                            color="secondary"
                            onClick={() => {
                                resetActivity();
                                setShowCart(false);
                            }}
                        >
                            <i className="fa fa-magnifying-glass" /> Verder
                            kijken
                        </Button>
                    )}
                {step === OvaticCartStep.FINISH && (
                    <Button
                        className="fs-5"
                        color="secondary"
                        onClick={() => {
                            resetActivity();
                            setShowCart(false);
                            logout();
                        }}
                    >
                        <i className="fa fa-xmark" /> Sluiten
                    </Button>
                )}
                {step !== OvaticCartStep.PAYMENT &&
                    step !== OvaticCartStep.FINISH && (
                        <Button
                            className="fs-5"
                            color="danger"
                            disabled={cancelling}
                            onClick={async () => {
                                resetActivity();
                                setCancelling(true);
                                try {
                                    await cancelCart();
                                } finally {
                                    setCancelling(false);
                                }
                                logout();
                            }}
                        >
                            {cancelling && (
                                <Spinner className="mr-2" size="sm" />
                            )}
                            <i className="fa fa-xmark" /> Annuleren
                        </Button>
                    )}
            </ModalFooter>
        </Modal>
    );
};
