import React, { useContext } from 'react';
import { ThemeHome } from './theme/ThemeHome';
import { HomeTheme } from './data/KioskData';
import { ThemeMenuItem } from './components/ThemeMenuItem';
import { ThemeData } from '../smartbridge/data/ThemeData';
import { CollectionData } from '../smartbridge/data/CollectionData';
import { ApplicationContext } from '../../context/ApplicationContext';

interface KioskHomeContextType {
    theme: ThemeData;
    preview: boolean;

    goto?(page: CollectionData);
}

export const KioskHomeContext = React.createContext<KioskHomeContextType>(
    undefined as any
);

export const KioskHomeContextProvider: React.FC<{
    theme: ThemeData;
    preview: boolean;
    modules?: CollectionData[];
    goto?(page: CollectionData);
}> = ({ theme, preview, modules, goto }) => {
    const { services } = useContext(ApplicationContext);

    const value: KioskHomeContextType = {
        theme,
        preview,
        goto
    };

    return (
        <KioskHomeContext.Provider value={value}>
            <ThemeHome>
                {modules &&
                    modules.map((module) => {
                        return (
                            <ThemeMenuItem
                                key={module.id}
                                item={{
                                    image: services.smartBridge.getImage(
                                        module.image.id,
                                        module.image.checksum
                                    ),
                                    name: module.name,
                                    subtitle: module.subtitle
                                }}
                                settings={theme.settings as HomeTheme}
                                action={() => {
                                    if (goto) {
                                        goto(module);
                                    } else {
                                        console.debug('No goto action defined');
                                    }
                                }}
                            />
                        );
                    })}
            </ThemeHome>
        </KioskHomeContext.Provider>
    );
};
