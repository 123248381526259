import React, { useContext } from 'react';
import { ImageReferenceData, KioskConstants, Themes } from '../data/KioskData';
import { ApplicationContext } from '../../../context/ApplicationContext';

export const ThemeHeader: React.FC<{
    image: ImageReferenceData;
    settings: Themes;
}> = ({ image, settings }) => {
    const { services } = useContext(ApplicationContext);

    return (
        <>
            <div
                className="d-flex flex-column align-items-center justify-content-end"
                style={{
                    height: `${settings.header.size}%`,
                    backgroundSize: 'cover',
                    backgroundPositionX: `${settings.header.positionX}%`,
                    backgroundPositionY: `${settings.header.positionY}%`,
                    backgroundImage: image
                        ? `url(${services.smartBridge.getImage(
                              image.id,
                              image.checksum
                          )})`
                        : ''
                }}
            />
            <div
                className="d-flex w-100 align-items-end position-absolute"
                style={{
                    backgroundColor: settings.header.backgroundColor,
                    padding: '0 3% 0 3%',
                    fontSize: '550%',
                    top: `${
                        KioskConstants.STATIC_TOP_MARGIN +
                        settings.header.size -
                        KioskConstants.DEFAULT_HEADER_MENU_SIZE
                    }%`,
                    height: `${KioskConstants.DEFAULT_HEADER_MENU_SIZE}%`
                }}
            >
                <span
                    className="font-weight-bold"
                    style={{
                        color: settings.header.color
                    }}
                >
                    {settings.header.title}
                </span>
            </div>
        </>
    );
};
