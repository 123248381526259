import React, { useContext } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { OvaticItem } from '../data/OvaticItem';
import { OvaticActivityContext } from '../context/OvaticActivityContext';
import SanitizedHTML from 'react-sanitized-html';
import { UserActivityContext } from '../../context/UserActivityContext';

export interface OvaticDetailModalProps {
    isOpen: boolean;
    selected: OvaticItem;

    toggle(isOpen?: boolean);

    nextStep?();
}

export const OvaticDetailModal: React.FC<OvaticDetailModalProps> = ({
    isOpen,
    selected,
    nextStep,
    toggle
}) => {
    const ovaticActivityContext = useContext(OvaticActivityContext);
    const { resetActivity } = useContext(UserActivityContext);

    let description: React.ReactNode;
    const ovaticDescription =
        ovaticActivityContext?.description?.longText ??
        ovaticActivityContext?.description?.shortText;
    if (ovaticDescription) {
        description = <SanitizedHTML html={ovaticDescription} />;
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                toggle();
            }}
            returnFocusAfterClose={false}
            centered
            size="lg"
            backdrop="static"
        >
            <ModalHeader>
                <span className="fs-2">{selected.getName()}</span>
            </ModalHeader>
            <ModalBody>
                {description}
                {!description && 'Geen beschrijving beschikbaar!'}
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => {
                        resetActivity();
                        toggle(false);
                    }}
                >
                    Annuleren
                </Button>
                {nextStep && (
                    <Button
                        color="primary"
                        onClick={() => {
                            resetActivity();
                            nextStep();
                        }}
                    >
                        Bestel kaarten
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};
