import React, { useContext } from 'react';
import { KioskContext } from '../../context/KioskContext';
import { ThemeTemplate } from './data/KioskData';
import { ThemeResizeWrapper } from './components/ThemeResizeWrapper';
import { KioskHomeContextProvider } from './KioskHomeContext';
import { KioskLibrettoContextProvider } from './KioskLibrettoContext';
import { KioskDefaultContextProvider } from './KioskDefaultContext';
import './scss/HideScroll.scss';

export const KioskPreview: React.FC = () => {
    const { page, goToPage, settings, modules, toHome } =
        useContext(KioskContext);

    if (!settings) {
        //return <Spinner />;
        return null;
    }

    let theme = ThemeTemplate.HOME;
    if (page) {
        theme = page.theme.template;
        console.debug(`Setting theme '${theme}' from page '${page.name}'`);
    }

    return (
        <ThemeResizeWrapper>
            <>
                {theme === ThemeTemplate.HOME && (
                    <KioskHomeContextProvider
                        theme={settings.theme}
                        modules={modules}
                        preview={false}
                        goto={goToPage}
                    />
                )}
                {theme === ThemeTemplate.LIBRETTO && (
                    <KioskLibrettoContextProvider
                        theme={page.theme}
                        module={page}
                        preview={false}
                        toHome={toHome}
                    />
                )}
                {theme === ThemeTemplate.DEFAULT && (
                    <KioskDefaultContextProvider
                        theme={page.theme}
                        module={page}
                        preview={false}
                        toHome={toHome}
                    />
                )}
            </>
        </ThemeResizeWrapper>
    );
};
