import React, { createRef, useContext, useEffect, useState } from 'react';

import { KioskHomeContext } from '../KioskHomeContext';
import { HomeTheme } from '../data/KioskData';
import { ThemeGrid } from '../components/ThemeGrid';
import { ThemeDummyMenuItem } from '../components/ThemeDummyMenuItem';

enum ScrollPositionType {
    TOP,
    MIDDLE,
    BOTTOM,
    NONE
}

export const ThemeHome: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {
    const { theme, preview } = useContext(KioskHomeContext);

    const settings = theme.settings as HomeTheme;

    const ref = createRef<HTMLDivElement>();

    const [scrollPos, setScrollPos] = useState(ScrollPositionType.NONE);

    useEffect(() => {
        if (React.Children.count(children) > settings.body.maxItems) {
            setScrollPos(ScrollPositionType.TOP);
        }
    }, [children, settings.body.maxItems]);

    const handleScroll = (target) => {
        if (target.clientHeight === target.scrollHeight) {
            setScrollPos(ScrollPositionType.NONE);
            return;
        }

        if (target.scrollTop <= 0) {
            setScrollPos(ScrollPositionType.TOP);
            return;
        }

        if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
            setScrollPos(ScrollPositionType.BOTTOM);
            return;
        }

        setScrollPos(ScrollPositionType.MIDDLE);
    };

    useEffect(() => {
        if (!ref) {
            return undefined;
        }

        const current = ref.current;
        const scroll = (e) => {
            handleScroll(e.target);
        };
        current.addEventListener('scroll', scroll);

        return () => current.removeEventListener('scroll', scroll);
    }, [ref]);

    return (
        <ThemeGrid data={theme}>
            <div
                className="d-flex flex-column justify-content-end position-relative"
                style={{
                    height: '12%',
                    padding: '0 3%',
                    borderBottom: '1px solid',
                    borderColor: settings.divider
                }}
            >
                <span
                    className="font-weight-bold"
                    style={{
                        color: settings.body.color,
                        fontSize: '505%'
                    }}
                >
                    {settings.subject}
                </span>
                {(scrollPos === ScrollPositionType.BOTTOM ||
                    scrollPos === ScrollPositionType.MIDDLE) && (
                    <span
                        className="d-flex position-absolute w-100 justify-content-center align-content-center"
                        style={{
                            bottom: 0,
                            left: 0,
                            color: settings.body.secondaryColor,
                            fontSize: '420%'
                        }}
                    >
                        <i className="fa fa-caret-up" />
                    </span>
                )}
            </div>
            {
                <div
                    className="flex-grow-1 hide-scroll"
                    style={{
                        overflow: 'auto',
                        height: `${100 - 20}%`
                    }}
                    ref={ref}
                >
                    {children}
                    {preview && (
                        <>
                            {[1, 2, 3, 4, 5].map((k) => {
                                return (
                                    <ThemeDummyMenuItem
                                        key={k}
                                        settings={settings}
                                    />
                                );
                            })}
                        </>
                    )}
                </div>
            }
            <div
                className="position-relative"
                style={{
                    height: '8%'
                }}
            >
                {(scrollPos === ScrollPositionType.TOP ||
                    scrollPos === ScrollPositionType.MIDDLE) && (
                    <span
                        className="d-flex position-absolute w-100 justify-content-center align-content-center"
                        style={{
                            top: 0,
                            left: 0,
                            color: settings.body.secondaryColor,
                            fontSize: '420%'
                        }}
                    >
                        <i className="fa fa-caret-down" />
                    </span>
                )}
            </div>
        </ThemeGrid>
    );
};
