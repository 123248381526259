import React, { useContext, useEffect, useState } from 'react';
import {
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import { OvaticRegisterAccount } from './OvaticRegisterAccount';
import { OvaticLoginAccount } from './OvaticLoginAccount';
import { ApplicationContext } from '../../context/ApplicationContext';
import QRCode from 'react-qr-code';
import { UserActivityContext } from '../../context/UserActivityContext';

export enum OvaticCartAccountAction {
    GUEST,
    REGISTER,
    LOGIN
}

export const OvaticCartAccountStep: React.FC = () => {
    const {
        api: { app }
    } = useContext(ApplicationContext);
    const [action, setAction] = useState(OvaticCartAccountAction.LOGIN);
    const { resetActivity } = useContext(UserActivityContext);

    const [url, setUrl] = useState<string>();

    useEffect(() => {
        app.get<string>(`/v1/system/ovatic`).then((res) => {
            setUrl(res.data);
        });
    }, [app]);

    return (
        <div>
            <p>
                Om verder te gaan met de bestelling moet u een Ovatic account
                registreren, inloggen als u al een account heeft of een eenmalig
                gast account gebruiken.
            </p>
            <Row>
                {url && (
                    <>
                        <Col md={8}>
                            <p>
                                <span>
                                    U kunt uw Ovatic account beheren via de
                                    website van Ovatic: <strong>{url}</strong>
                                </span>
                                <br />
                                <em>
                                    Gebruik uw eigen (mobiele) apparaat om de
                                    website van Ovatic te bezoeken door de QR
                                    code te scannen of het URL te bezoeken in de
                                    webbrowser.
                                </em>
                            </p>
                        </Col>
                        <Col md={4}>
                            <QRCode value={url} />
                        </Col>
                    </>
                )}
            </Row>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={OvaticCartAccountAction.REGISTER === action}
                        onClick={() => {
                            resetActivity();
                            setAction(OvaticCartAccountAction.REGISTER);
                        }}
                    >
                        Registreren
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={OvaticCartAccountAction.LOGIN === action}
                        onClick={() => {
                            resetActivity();
                            setAction(OvaticCartAccountAction.LOGIN);
                        }}
                    >
                        Inloggen
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={OvaticCartAccountAction.GUEST === action}
                        onClick={() => {
                            resetActivity();
                            setAction(OvaticCartAccountAction.GUEST);
                        }}
                    >
                        Gast account
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={action} className="pt-3">
                <TabPane tabId={OvaticCartAccountAction.REGISTER}>
                    <OvaticRegisterAccount />
                </TabPane>
                <TabPane tabId={OvaticCartAccountAction.LOGIN}>
                    <OvaticLoginAccount />
                </TabPane>
                <TabPane tabId={OvaticCartAccountAction.GUEST}>
                    <OvaticRegisterAccount guest />
                </TabPane>
            </TabContent>
        </div>
    );
};
