import React from 'react';
import { ThemeLogo } from './ThemeLogo';
import { ThemeHeader } from './ThemeHeader';
import { ThemeHeaderMenu } from './ThemeHeaderMenu';
import { ThemeFooter } from './ThemeFooter';
import { ThemeHeaderBar } from './ThemeHeaderBar';
import { KioskConstants } from '../data/KioskData';
import { ThemeData } from '../../smartbridge/data/ThemeData';

export const ThemeGrid: React.FC<{
    data: ThemeData;
    toHome?();
    children: React.ReactNode;
}> = ({ data, toHome, children }) => {
    const settings = data.settings;

    const MAX_BODY_SIZE =
        100 -
        KioskConstants.STATIC_TOP_MARGIN -
        settings.header.size -
        KioskConstants.DEFAULT_HEADER_MENU_SIZE -
        KioskConstants.STATIC_BOTTOM_MARGIN;

    return (
        <div
            className="position-relative"
            style={{
                aspectRatio: '9/16',
                backgroundImage: "url('/assets/img/background_libretto.png')",
                //backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'repeat'
            }}
        >
            <ThemeHeaderBar />
            <ThemeHeader image={data.image} settings={settings} />
            <ThemeLogo logo={data.logo} />
            <ThemeHeaderMenu toHome={toHome} settings={settings} />
            <div
                className="d-flex flex-column"
                style={{
                    height: `${MAX_BODY_SIZE}%`,
                    backgroundColor: settings.body.backgroundColor
                }}
            >
                {children}
            </div>
            <ThemeFooter settings={settings} />
        </div>
    );
};
