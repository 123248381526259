export const saveBorrowerErrors = {
    '@NicknameInUse': 'Gebruikersnaam is al in gebruik',
    '@ErrMandatoryFieldMissing': 'Verplichte velden missen',
    '@ErrBirthDateMismatch': 'Leeftijd is niet toegestaan met dit abonnement',
    '@ErrFieldFormatError': 'Format fout in veld',
    '@ErrDuplicateBorrower': 'Gebruiker bestaat al',
    '@ErrDuplicateBarcode': 'Barcode is al in gebruik',
    '@ErrBorrowerNotFound': 'Gebruiker kon niet worden gevonden',
    '@ErrBorrowerLocked': 'Gebruiker is geblokkeerd',
    '@ErrWebServiceError': 'Adres kon niet geverifieerd worden',
    '@ErrUnknownError': 'Onbekende fout'
};
