import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { OvaticContext } from '../context/OvaticContext';
import { UserActivityContext } from '../../context/UserActivityContext';

export const OvaticCartFixedButton: React.FC = () => {
    const { showCart, setShowCart, cart } = useContext(OvaticContext);
    const { resetActivity } = useContext(UserActivityContext);

    const itemCount = cart?.ticketCount || 0;
    if (showCart || itemCount === 0) {
        return null;
    }

    return (
        <Button
            style={{
                position: 'fixed',
                right: 50,
                bottom: 50,
                zIndex: 1000,
                padding: 15,
                borderRadius: 30
            }}
            onClick={() => {
                resetActivity();
                setShowCart(true);
            }}
        >
            <i className="fa fa-cart-shopping" /> Open winkelwagen ({itemCount}{' '}
            tickets)
        </Button>
    );
};
