import { useField } from 'formik';
import { FORMIK_GLOBAL_ERROR_FIELD } from './FormikGlobalErrorValues';
import { Alert } from 'reactstrap';
import React from 'react';

interface FormikGlobalErrorFieldProps {
    color?: string;
}

export const FormikGlobalErrorField: React.FC<FormikGlobalErrorFieldProps> = ({
    color = 'danger'
}) => {
    const [, meta] = useField(FORMIK_GLOBAL_ERROR_FIELD);
    if (meta.error) {
        return <Alert color={color}>{meta.error}</Alert>;
    }
    return null;
};
