import { KioskItem } from '../../shared/kiosk/data/KioskItem';
import { SubscriptionData } from '../../shared/smartbridge/data/SubscriptionData';
import { SmartBridgeService } from '../../routes/default/service/SmartBridgeService';

export class VSmartItem implements KioskItem {
    constructor(
        public data: SubscriptionData,
        private smartbridge: SmartBridgeService
    ) {}

    getDescription(): string | undefined {
        return this.data.description;
    }

    getImageUrl(): string | undefined {
        // TODO
        return this.data.image
            ? this.smartbridge.getImage(
                  this.data.image.id,
                  this.data.image.checksum
              )
            : '/assets/img/borat.jpg';
    }

    getName(): string {
        return this.data.name;
    }

    getSubtitle(): string {
        return this.data.subtitle;
    }

    getId(): string {
        return this.data.id;
    }

    getDoorsOpenDate(): Date | undefined {
        return new Date('2021-09-09T19:30:00.000+00:00');
    }

    getEndDate(): Date {
        return new Date('2021-09-09T13:00:00.000+00:00');
    }

    getStartDate(): Date {
        return new Date('2021-09-09T20:00:00.000+00:00');
    }

    getType(): { code: string; id: string; name: string } | undefined {
        return undefined;
    }
}
