import React, { useContext, useState } from 'react';
import { PaymentContext } from '../context/PaymentContext';
import { EbError } from '../../shared/axios/EbError';
import { PaymentTransactionStatus } from '../data/PaymentTransactionData';
import { Alert, Button, Col, Row, Spinner } from 'reactstrap';
import { ApplicationContext } from '../../context/ApplicationContext';
import { UserActivityContext } from '../../context/UserActivityContext';

export const TransactionReceipt: React.FC<{
    printing: boolean;
    setPrinting(printing: boolean);
}> = ({ printing, setPrinting }) => {
    const {
        api: { app }
    } = useContext(ApplicationContext);
    const { transaction, printerAvailable } = useContext(PaymentContext);
    const { resetActivity } = useContext(UserActivityContext);

    const status = PaymentTransactionStatus[transaction.status];

    const [printed, setPrinted] = useState(false);

    if (transaction instanceof EbError || !transaction.receipt) {
        return undefined;
    }

    return (
        <>
            {status < PaymentTransactionStatus.FINISHED && (
                <Alert color="warning">
                    <Spinner size="sm" color="warning" />
                    Transactie bon word verzonden naar {transaction.email}
                </Alert>
            )}
            {transaction.status === 'FINISHED' && (
                <Alert className="success">
                    Transactie bon is verzonden naar {transaction.email}
                    .
                    <br />
                    Kijk voor de zekerheid in uw spam folder als u de email niet
                    kunt vinden.
                    {printed && (
                        <>
                            <br />
                            Transactie bon is uitgeprint
                        </>
                    )}
                </Alert>
            )}
            {!printed && (
                <>
                    <Col
                        className="mb-3 p-3 border"
                        style={{ fontSize: '50%' }}
                    >
                        {transaction.receipt.split('\n').map((line, k) => {
                            if (line === '') {
                                return (
                                    <Row>
                                        <br />
                                    </Row>
                                );
                            }

                            return (
                                <>
                                    <Row className="m-0" key={k}>
                                        {line}
                                    </Row>
                                </>
                            );
                        })}
                    </Col>
                    <p>
                        <Button
                            color="primary"
                            className="me-1 fs-5"
                            onClick={() => {
                                resetActivity();
                                setPrinting(true);
                                app.post<any>(`/v1/printer/receipt`, {
                                    transaction
                                })
                                    .then((res) => res.data)
                                    .finally(() => {
                                        setPrinting(false);
                                        setPrinted(true);
                                    });
                            }}
                            disabled={!printerAvailable || printing}
                        >
                            {printing && <Spinner className="mr-2" size="sm" />}
                            Klik hier
                        </Button>
                        als u toch een uitgeprinte versie van de transactie bon
                        wilt hebben.
                    </p>
                </>
            )}
        </>
    );
};
