import React, { JSXElementConstructor } from 'react';
import { WizardContext } from './WizardContext';
import { WizardStep, WizardStepProps } from './WizardStep';
import './Wizard.scss';
import { Step, StepLabel, Stepper } from '@mui/material';

export function findReactNodesOfType<T>(
    children: React.ReactNode,
    type: JSXElementConstructor<T>,
    maxDepth = 2,
    depth = 0
): React.ReactElement<T>[] {
    if (depth > maxDepth) {
        return [];
    }
    if (Array.isArray(children)) {
        return children.flatMap((child) => {
            const _child = child as React.ReactElement;
            if (_child) {
                if (_child.type === type) {
                    return [_child];
                } else {
                    const mappedChildren = (
                        _child.type as JSXElementConstructor<any>
                    ).call({}, _child.props);
                    return findReactNodesOfType(
                        mappedChildren,
                        type,
                        maxDepth,
                        depth + 1
                    );
                }
            } else {
                return [];
            }
        });
    } else {
        return findReactNodesOfType([children], type, maxDepth, depth + 1);
    }
}

interface WizardProps {
    activeStep: number;
    maxStep: number;
    stepper?: boolean;

    changeStep(step: number);

    children: React.ReactElement<WizardStepProps>[];
}

export const Wizard: React.FC<WizardProps> = ({
    activeStep,
    maxStep,
    changeStep,
    stepper = true,
    children
}) => {
    const steps = findReactNodesOfType(children, WizardStep).map((step) => {
        return {
            step: step.props.step,
            name: step.props.name
        };
    });

    return (
        <div className="wizard">
            <WizardContext.Provider
                value={{
                    activeStep: Math.min(activeStep, maxStep),
                    steps,
                    maxStep,
                    changeStep
                }}
            >
                {stepper && (
                    <Stepper
                        className="mb-3"
                        activeStep={activeStep}
                        alternativeLabel
                    >
                        {steps.map((it) => {
                            return (
                                <Step key={it.step}>
                                    <StepLabel>
                                        <span className="fs-5">{it.name}</span>
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                )}
                {children}
            </WizardContext.Provider>
        </div>
    );
};
