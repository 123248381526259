import React from 'react';
import { OvaticActivityData } from '../data/OvaticActivityData';
import { OvaticActivityPriceData } from '../data/OvaticActivityPriceData';
import { OvaticActivityDescriptionData } from '../data/OvaticActivityDescriptionData';
import { OvaticActivitySeatsResponse } from '../data/OvaticActivitySeatsResponse';
import { OvaticActivitySeatData } from '../data/OvaticActivitySeatData';
import { OvaticActivitySeatRankData } from '../data/OvaticActivitySeatRankData';
import { OvaticBlueprintSectionData } from '../data/OvaticBlueprintSectionData';

export interface OvaticActivityContextType {
    description?: OvaticActivityDescriptionData;
    activity?: OvaticActivityData;
    prices?: OvaticActivityPriceData[];
    seats?: OvaticActivitySeatsResponse;
    productions?: OvaticActivityData[];
    selectedRank?: number;

    selectedSeats: OvaticActivitySeatData[];

    selectSeats(seats: OvaticActivitySeatData[]);

    isSeatSelected(seat: OvaticActivitySeatData): boolean;

    getRankById(rankId: number): OvaticActivitySeatRankData | undefined;

    getSectionById(rankId: number): OvaticBlueprintSectionData | undefined;

    loadSeats(): Promise<any>;

    /**
     * @return Number of available seats for rank
     */
    getAvailableSeatCountForRank(rankId: number): number;
}

export const OvaticActivityContext =
    React.createContext<OvaticActivityContextType>(undefined as any);
