import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { ApplicationContext } from '../../context/ApplicationContext';
import { PaymentRequest } from '../data/PaymentRequest';
import { WebsocketPaths } from '../../constants';
import {
    HealthCheckData,
    HealthCheckState,
    HealthCheckStep
} from '../../health/data/HealthCheckData';
import {
    PaymentTransactionData,
    PaymentTransactionStatus
} from '../data/PaymentTransactionData';
import { timer } from 'rxjs';
import { EbError } from '../../shared/axios/EbError';

interface PaymentContextType {
    printerAvailable: boolean;
    outOfOrder: boolean;
    transaction: PaymentTransactionData | EbError;
    success: boolean;

    startTransaction(data: PaymentRequest): void;

    reset(): void;
}

export const PaymentContext = React.createContext<PaymentContextType>(
    undefined as any
);

export const PaymentContextProvider: React.FC<{
    children: React.ReactElement;
}> = ({ children }) => {
    const {
        services: { payment },
        websocket: { app }
    } = useContext(ApplicationContext);

    const [outOfOrder, setOutOfOrder] = useState<boolean>(true);
    const [printerAvailable, setPrinterAvailable] = useState<boolean>(false);

    const [transaction, setTransaction] = useState<
        PaymentTransactionData | EbError
    >();

    const [success, setSuccess] = useState<boolean>();

    const reset = () => {
        setTransaction(undefined);
        setSuccess(undefined);
    };

    useEffect(() => {
        if (!app) {
            return undefined;
        }

        const sub = app.observe(WebsocketPaths.health).subscribe({
            next: (data) => {
                const ht: HealthCheckData = JSON.parse(data);
                if (ht.step === HealthCheckStep.PIN) {
                    if (ht.state === HealthCheckState.FAILED) {
                        setOutOfOrder(true);
                    } else if (ht.state === HealthCheckState.VALIDATED) {
                        setOutOfOrder(false);
                    }
                }
                if (ht.step === HealthCheckStep.PRINTER) {
                    if (ht.state === HealthCheckState.FAILED) {
                        setPrinterAvailable(false);
                    } else if (ht.state === HealthCheckState.VALIDATED) {
                        setPrinterAvailable(true);
                    }
                }
            }
        });
        return () => sub.unsubscribe();
    }, [app, setOutOfOrder]);

    const checkTransaction = useCallback(() => {
        if (!transaction || transaction instanceof EbError) {
            return undefined;
        }

        return payment
            .getTransaction(transaction.id)
            .then((_data) => {
                console.log('check transaction data');
                console.log(_data);
                console.log(PaymentTransactionStatus[_data.status]);
                console.log(PaymentTransactionStatus.ABORTED);

                if (_data.status !== transaction.status) {
                    setTransaction(_data);

                    if (
                        PaymentTransactionStatus[_data.status] >=
                        PaymentTransactionStatus.SUCCESS
                    ) {
                        setSuccess(true);
                    } else if (_data.status === 'ABORTED') {
                        setSuccess(false);
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, [payment, transaction]);

    useEffect(() => {
        const subscription = timer(0, 5_000).subscribe(checkTransaction);
        return () => subscription.unsubscribe();
    }, [checkTransaction]);

    const startTransaction = useCallback(
        (data: PaymentRequest) => {
            console.debug('starting transaction');
            payment
                .startTransaction(data)
                .then((_data) => {
                    console.log('start transaction');
                    console.log(_data);
                    reset();
                    setTransaction(_data);
                })
                .catch((err) => {
                    console.error(err);
                    if (err.status !== 409) {
                        setTransaction(err);
                    }
                });
        },
        [payment]
    );

    const value: PaymentContextType = useMemo(
        () => ({
            printerAvailable,
            outOfOrder,
            transaction,
            success,
            startTransaction,
            reset
        }),
        [outOfOrder, printerAvailable, startTransaction, success, transaction]
    );

    return (
        <PaymentContext.Provider value={value}>
            {children}
        </PaymentContext.Provider>
    );
};
