import React, { CSSProperties, useContext } from 'react';
import { OvaticActivitySeatData } from '../data/OvaticActivitySeatData';
import { OvaticActivityContext } from '../context/OvaticActivityContext';
import { getSeatStatus, OvaticSeatStatus } from '../data/OvaticSeatStatus';

export interface OvaticActivitySeatProps {
    seat: OvaticActivitySeatData;
    highlight?: boolean;
}

export const OvaticActivitySeat: React.FC<OvaticActivitySeatProps> = ({
    seat,
    highlight
}) => {
    const {
        isSeatSelected,
        selectSeats,
        selectedSeats,
        getRankById,
        selectedRank,
        activity
    } = useContext(OvaticActivityContext);

    const rank = getRankById(seat.rankId);

    let color = 'green';
    if (rank) {
        const colors = rank.colors;
        if (colors.length) {
            color = colors[0];
        }
        // TODO there is a second color in the array, hmm
    }
    const seatSelected = isSeatSelected(seat);
    if (seatSelected) {
        color = 'blue';
    } else if (seat.blocked) {
        color = 'red';
    }

    const status = getSeatStatus(seat);
    if (status === OvaticSeatStatus.LOCKED_BY_OTHER_USER) {
        if (seatSelected) {
            color = 'orange';
        } else {
            color = 'gray';
        }
    }

    let cursor: CSSProperties['cursor'];
    let canSelect = status === OvaticSeatStatus.AVAILABLE;
    if (typeof selectedRank === 'number' && canSelect) {
        canSelect = seat.rankId === selectedRank;
        if (!canSelect) {
            // Cannot select seats with different ranks
            color = 'gray';
        }
    }
    if (canSelect) {
        const maxTicketsPerTransaction = activity?.maxTicketsPerTransaction;
        if (
            typeof maxTicketsPerTransaction === 'number' &&
            selectedSeats.length >= maxTicketsPerTransaction
        ) {
            canSelect = false;
            if (!seatSelected) {
                color = 'gray';
            }
        }
    }

    if (canSelect) {
        cursor = 'pointer';
    } else {
        cursor = 'not-allowed';
    }

    if (typeof highlight === 'boolean') {
        cursor = 'not-allowed';
        if (highlight && rank) {
            const colors = rank.colors;
            if (colors.length) {
                color = colors[0];
            }
        } else {
            color = 'gray';
        }
    }

    return (
        <circle
            cx={seat.coordinateX}
            cy={seat.coordinateY}
            r={5}
            stroke={color}
            fill={color}
            style={{
                cursor
            }}
            onClick={() => {
                let newSeatSelection = [...selectedSeats];
                if (
                    newSeatSelection.some(
                        (selectedSeat) =>
                            selectedSeat.seatNumber === seat.seatNumber
                    )
                ) {
                    // Deselect
                    newSeatSelection = newSeatSelection.filter(
                        (selectedSeat) =>
                            selectedSeat.seatNumber !== seat.seatNumber
                    );
                } else {
                    // Select
                    if (canSelect) {
                        newSeatSelection.push(seat);
                    }
                }
                selectSeats(newSeatSelection);
            }}
        >
            {seat.seatNumber}
        </circle>
    );
};
