import React from 'react';
import { OvaticActivityPriceData } from '../data/OvaticActivityPriceData';
import { OvaticItem } from '../data/OvaticItem';
import { OvaticAddTicketToCartRequest } from '../data/OvaticAddTicketToCartRequest';
import { OvaticCartResponse } from '../data/OvaticCartResponse';
import { EbError } from '../../shared/axios/EbError';
import { OvaticError } from '../data/OvaticError';
import { OvaticRegisterAccountRequest } from '../data/OvaticRegisterAccountRequest';
import { OvaticAccountData } from '../data/OvaticAccountData';
import { OvaticLoginAccountRequest } from '../data/OvaticLoginAccountRequest';

export interface OvaticContextType {
    activities?: OvaticItem[];
    error?: {
        title: string;
        message: string;
    };
    cart?: OvaticCartResponse;
    cartSecret: string;
    showCart: boolean;
    account?: OvaticAccountData;

    fetchActivityPrices(activityId: string): Promise<OvaticActivityPriceData[]>;

    /**
     * Add ticket to cart. Will create a new cart if none exists.
     * @param req
     */
    addTicketToCart(
        req: OvaticAddTicketToCartRequest
    ): Promise<OvaticCartResponse>;

    handleError(error: EbError | OvaticError);

    clearError();

    setShowCart(showCart: boolean);

    cancelCart(): Promise<void>;

    cancelTransaction(transactionId: number): Promise<void>;

    /**
     * Register an account. If registration is successful, the user will be logged in.
     * @param req
     */
    registerAccount(req: OvaticRegisterAccountRequest): Promise<any>;

    loginAccount(req: OvaticLoginAccountRequest);

    logout();
}

export const OvaticContext = React.createContext<OvaticContextType>(
    undefined as any
);
