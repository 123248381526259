import React, { useContext, useEffect, useState } from 'react';
import { BootSplash } from './BootSplash';
import { ApplicationContext } from '../../context/ApplicationContext';
import { KioskContext } from '../../context/KioskContext';
import {
    HealthCheckData,
    HealthCheckState
} from '../../health/data/HealthCheckData';
import { WebsocketPaths } from '../../constants';
import { Errors } from '../../errors/Errors';

export const SplashLoader: React.FC = () => {
    const appContext = useContext(ApplicationContext);
    const kioskContext = useContext(KioskContext);

    const [health, setHealth] = useState<HealthCheckData>();

    useEffect(() => {
        if (!appContext?.websocket?.app) {
            return undefined;
        }

        const sub = appContext.websocket.app
            .observe(WebsocketPaths.health)
            .subscribe({
                next: (data) => {
                    const ht: HealthCheckData = JSON.parse(data);
                    if (
                        health?.step === ht.step &&
                        health?.state === ht.state
                    ) {
                        return;
                    }

                    if (ht.outOfOrder) {
                        setHealth(ht);
                        return;
                    }

                    if (
                        health?.outOfOrder &&
                        ht.step === health?.step &&
                        ht.state === HealthCheckState.VALIDATED
                    ) {
                        setHealth(null);
                    }
                }
            });
        return () => sub.unsubscribe();
    }, [
        appContext.websocket.app,
        health?.outOfOrder,
        health?.state,
        health?.step,
        setHealth
    ]);

    let spinner = false;

    let message: {
        type: 'info' | 'success' | 'danger' | 'warning';
        value: string | JSX.Element;
    };

    let open = false;

    if (kioskContext?.kioskSplash) {
        open = true;
        switch (kioskContext.init) {
            case 'success': {
                message = {
                    type: 'success',
                    value: 'Smartbridge data loaded'
                };

                if (kioskContext.modules?.length === 0) {
                    message = {
                        type: 'danger',
                        value: 'No kiosk modules to display'
                    };
                }
                break;
            }
            case 'failed': {
                message = {
                    type: 'danger',
                    value: 'Failed to load smartbridge data!'
                };
                break;
            }
            default: {
                spinner = true;
                message = {
                    type: 'info',
                    value: 'Loading smartbridge...'
                };
            }
        }
    }

    if (appContext?.splash) {
        open = true;
        switch (appContext.init) {
            case 'success': {
                message = {
                    type: 'success',
                    value: 'License verified'
                };
                break;
            }
            case 'failed': {
                message = {
                    type: 'danger',
                    value: 'License failed!'
                };
                break;
            }
            case 'inuse': {
                message = {
                    type: 'warning',
                    value: 'License already in use!'
                };
                break;
            }
            default: {
                spinner = true;
                message = {
                    type: 'info',
                    value: 'Loading license...'
                };
            }
        }
    }

    if (health) {
        const lower = health.step.toLowerCase();
        spinner = false;
        message = {
            type: 'danger',
            value: lower in Errors ? Errors[lower] : health.step
        };
    }

    if (!open && !health) {
        return undefined;
    }

    return <BootSplash message={message} spinner={spinner} />;
};
