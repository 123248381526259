import { Axios } from 'axios';
import { ThemeData } from '../../smartbridge/data/ThemeData';

export class ThemeService {
    axios: Axios;

    constructor(axios: Axios) {
        this.axios = axios;
    }

    get(id: string, session: string): Promise<ThemeData> {
        return this.axios
            .get<ThemeData>(
                `/api/v1/smartbridge/themes/${id}?session=${session}`
            )
            .then((res) => res.data);
    }
}
