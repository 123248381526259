import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    OvaticActivityContext,
    OvaticActivityContextType
} from '../context/OvaticActivityContext';
import { OvaticItem } from '../data/OvaticItem';
import { ApplicationContext } from '../../context/ApplicationContext';
import { OvaticActivityDescriptionData } from '../data/OvaticActivityDescriptionData';
import { timer } from 'rxjs';
import { OvaticActivitySeatsResponse } from '../data/OvaticActivitySeatsResponse';
import { OvaticActivitySeatData } from '../data/OvaticActivitySeatData';
import { OvaticContext } from '../context/OvaticContext';
import { OvaticActivityPriceData } from '../data/OvaticActivityPriceData';
import { getSeatStatus, OvaticSeatStatus } from '../data/OvaticSeatStatus';
import { UserActivityContext } from '../../context/UserActivityContext';
import { OvaticActivityData } from '../data/OvaticActivityData';
import axios from 'axios';

export interface OvaticActivityContextProviderProps {
    children: React.ReactElement;
    item: OvaticItem;
}

export const OvaticActivityContextProvider: React.FC<
    OvaticActivityContextProviderProps
> = ({ children, item }) => {
    const {
        api: { gateway }
    } = useContext(ApplicationContext);
    const { fetchActivityPrices } = useContext(OvaticContext);

    const id = item.getId();
    const [description, setDescription] =
        useState<OvaticActivityDescriptionData>();
    const [seats, setSeats] = useState<OvaticActivitySeatsResponse>();
    const [selectedSeatNumbers, setSelectedSeatNumbers] = useState<number[]>(
        []
    );
    const [prices, setPrices] = useState<OvaticActivityPriceData[]>();
    const { resetActivity } = useContext(UserActivityContext);
    const [productions, setProductions] = useState<OvaticActivityData[]>();
    const availableSeatsByRank = useMemo(() => {
        const result: { [key: string]: number } = {};
        seats?.zones.forEach((zone) =>
            zone.seats.forEach((seat) => {
                if (getSeatStatus(seat) === OvaticSeatStatus.AVAILABLE) {
                    const count = result[seat.rankId] ?? 0;
                    result[seat.rankId] = count + 1;
                }
            })
        );
        return result;
    }, [seats]);

    const seatsById = useMemo(() => {
        if (seats) {
            const result = {};
            seats.zones.forEach((zone) => {
                zone.seats.forEach((seat) => {
                    result[seat.seatNumber] = seat;
                });
            });
            return result;
        } else {
            return {};
        }
    }, [seats]);

    useEffect(() => {
        setDescription(undefined);
        const source = axios.CancelToken.source();
        gateway
            .get<OvaticActivityDescriptionData>(
                `/ovatic/v1/activities/${id}/description`,
                {
                    cancelToken: source.token
                }
            )
            .then((res) => {
                setDescription(res.data);
            });
        return source.cancel;
    }, [id, gateway]);

    const loadSeats = useCallback(() => {
        return gateway
            .get<OvaticActivitySeatsResponse>(
                `/ovatic/v1/activities/${id}/seats`
            )
            .then((res) => {
                setSeats(res.data);
            });
    }, [id, gateway]);

    useEffect(() => {
        setSeats(undefined);
        setSelectedSeatNumbers([]);
        const subscription = timer(0, 5_000).subscribe(() => {
            return loadSeats();
        });
        return () => subscription.unsubscribe();
    }, [loadSeats]);

    useEffect(() => {
        setPrices(undefined);

        const interval = item.data.dynamicPricing ? 5_000 : 30_000;
        const subscription = timer(0, interval).subscribe(() => {
            fetchActivityPrices(id).then((prices) => setPrices(prices));
        });
        return () => subscription.unsubscribe();
    }, [id, fetchActivityPrices, item]);

    const selectedSeats: OvaticActivitySeatData[] = useMemo(() => {
        return selectedSeatNumbers.map((seatNumber) => seatsById[seatNumber]);
    }, [selectedSeatNumbers, seatsById]);

    const selectSeats: OvaticActivityContextType['selectSeats'] = useCallback(
        (seats) => {
            resetActivity();
            setSelectedSeatNumbers(
                seats
                    .filter((seat) => {
                        return (
                            getSeatStatus(seat) === OvaticSeatStatus.AVAILABLE
                        );
                    })
                    .map((seat) => seat.seatNumber)
            );
        },
        [resetActivity]
    );

    const isSeatSelected: OvaticActivityContextType['isSeatSelected'] =
        useCallback(
            (seat) => {
                return selectedSeatNumbers.some(
                    (selectedSeatNumber) =>
                        selectedSeatNumber === seat.seatNumber
                );
            },
            [selectedSeatNumbers]
        );

    const sectionsById = useMemo(() => {
        const sections = seats?.sections;
        if (sections) {
            const result = {};
            sections.forEach((section) => {
                result[section.sectionId] = section;
            });
            return result;
        } else {
            return {};
        }
    }, [seats]);

    const getSectionById: OvaticActivityContextType['getSectionById'] =
        useCallback((rankId) => sectionsById[rankId], [sectionsById]);

    const ranksById = useMemo(() => {
        const ranks = seats?.ranks;
        if (ranks) {
            const result = {};
            ranks.forEach((rank) => {
                result[rank.rankId] = rank;
            });
            return result;
        } else {
            return {};
        }
    }, [seats]);

    const getRankById: OvaticActivityContextType['getRankById'] = useCallback(
        (rankId) => ranksById[rankId],
        [ranksById]
    );

    const selectedRank = useMemo(() => {
        if (selectedSeats.length) {
            const rank = selectedSeats[0].rankId;
            if (selectedSeats.every((seat) => seat.rankId === rank)) {
                return rank;
            }
        }
        return undefined;
    }, [selectedSeats]);

    useEffect(() => {
        setProductions(undefined);
        const subscription = timer(0, 60_000).subscribe(() => {
            gateway
                .get<OvaticActivityData[]>(
                    `/ovatic/v1/activities/${id}/productions`
                )
                .then((res) => setProductions(res.data));
        });
        return () => subscription.unsubscribe();
    }, [gateway, id]);

    const getAvailableSeatCountForRank: OvaticActivityContextType['getAvailableSeatCountForRank'] =
        useCallback(
            (rankId) => {
                return availableSeatsByRank[rankId] || 0;
            },
            [availableSeatsByRank]
        );

    const contextValue: OvaticActivityContextType = useMemo(
        () => ({
            activity: item.data,
            description,
            prices,
            seats,
            productions,
            selectedRank,
            selectedSeats,
            selectSeats,
            isSeatSelected,
            getRankById,
            getSectionById,
            loadSeats,
            getAvailableSeatCountForRank
        }),
        [
            item,
            description,
            prices,
            seats,
            productions,
            selectedRank,
            selectedSeats,
            selectSeats,
            isSeatSelected,
            getRankById,
            getSectionById,
            loadSeats,
            getAvailableSeatCountForRank
        ]
    );

    return (
        <OvaticActivityContext.Provider value={contextValue}>
            {children}
        </OvaticActivityContext.Provider>
    );
};
