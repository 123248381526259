import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { ApplicationContext } from '../../context/ApplicationContext';
import { timer } from 'rxjs';
import { OvaticContext, OvaticContextType } from '../context/OvaticContext';
import { OvaticActivityPriceData } from '../data/OvaticActivityPriceData';
import { OvaticItem } from '../data/OvaticItem';
import { OvaticActivityData } from '../data/OvaticActivityData';
import { OvaticCartResponse } from '../data/OvaticCartResponse';
import { EbError, ErrorCode } from '../../shared/axios/EbError';
import { OvaticAddTicketToCartRequest } from '../data/OvaticAddTicketToCartRequest';
import { OvaticError } from '../data/OvaticError';
import { OvaticErrorModal } from './OvaticErrorModal';
import { OvaticCartModal } from './OvaticCartModal';
import { OvaticCartFixedButton } from './OvaticCartFixedButton';
import { OvaticCartState } from '../data/OvaticCartState';
import { UserActivityContext } from '../../context/UserActivityContext';
import { OvaticAccountData } from '../data/OvaticAccountData';
import { OvaticRegisterAccountRequest } from '../data/OvaticRegisterAccountRequest';
import { OvaticLoginAccountRequest } from '../data/OvaticLoginAccountRequest';
import { KioskContext } from '../../context/KioskContext';
import { CollectionType } from '../../shared/smartbridge/data/CollectionType';
import { PaymentContext } from '../../payment/context/PaymentContext';

export interface OvaticContextProviderProps {
    children: React.ReactElement;
}

export const OvaticContextProvider: React.FC<OvaticContextProviderProps> = ({
    children
}) => {
    const {
        api: { gateway },
        organizationId
    } = useContext(ApplicationContext);
    const { success: paymentSuccess } = useContext(PaymentContext);

    const { page } = useContext(KioskContext);

    const [activities, setActivities] = useState<OvaticItem[] | undefined>();
    const [cart, setCart] = useState<OvaticCartResponse>();
    const [showCart, setShowCart] = useState(false);
    const [cartSecret, setCartSecret] = useState('');
    const [error, setError] = useState<OvaticError | undefined>();
    const { lastActivity, resetActivity } = useContext(UserActivityContext);
    /*
     * Security wise it doesn't really matter that the customerId can be spoofed without proper authentication (we don't use server controlled sessions post authentication).
     * As we only allow you to buy tickets, and not see your bought tickets, so 'worst' case is someone buys you a free ticket :).
     * Or someone tries to checkout with your customer id, but doesn't pay.
     * Worst case is it might result in some pollution of data / perhaps e-mails for unfinished carts?
     * That might be a problem, but I'm not going to implement (temporary) Ovatic sessions for something that is long term intended to use Eastbridge accounts.
     * But you know, company priorities, no time, deadlines blah...
     */
    const [account, setAccount] = useState<OvaticAccountData>();

    const loadActivities = useCallback(() => {
        gateway
            .get<OvaticActivityData[]>(
                `/ovatic/v1/activities?org=${organizationId}`
            )
            .then((res) => {
                let activities = res.data.map(
                    (activity) => new OvaticItem(activity)
                );

                if (
                    page?.provider === CollectionType.OVATIC &&
                    page?.settings?.filter.length > 0
                ) {
                    const filtered = activities.filter((activity) =>
                        page.settings.filter
                            .map((it) => it.id)
                            .includes(activity.getType().id)
                    );
                    if (filtered.length > 0) {
                        activities = filtered;
                    }
                }

                setActivities(activities);
            })
            .catch((err) => {
                console.error('Failed to refresh activities', err);
            });
    }, [gateway, organizationId, page?.provider, page?.settings?.filter]);

    const fetchActivityPrices: OvaticContextType['fetchActivityPrices'] =
        useCallback(
            (activityId: string) => {
                return gateway
                    .get<OvaticActivityPriceData[]>(
                        `/ovatic/v1/activities/${activityId}/prices`
                    )
                    .then((res) => res.data);
            },
            [gateway]
        );

    const cartId = cart?.id;

    useEffect(() => {
        if (cartId) {
            gateway.post(`/ovatic/v1/carts/${cartId}/active`).catch((err) => {
                console.error(`Failed to update cart activity`, err);
            });
        }
    }, [lastActivity, cartId, gateway]);

    useEffect(() => {
        const subscription = timer(0, 60_000).subscribe(loadActivities);
        return () => subscription.unsubscribe();
    }, [loadActivities]);

    const loadCart = useCallback(async () => {
        if (cart) {
            const response = await gateway.get<OvaticCartResponse>(
                `/ovatic/v1/carts/${cart.id}`
            );
            setCart(response.data);
        }
    }, [gateway, cart]);

    useEffect(() => {
        if (cart) {
            const subscription = timer(30_000, 30_000).subscribe(() => {
                return loadCart();
            });
            return () => subscription.unsubscribe();
        }
        return undefined;
    }, [loadCart, cart]);

    useEffect(() => {
        if (
            cart &&
            paymentSuccess &&
            cart.state !== OvaticCartState.CONFIRMED
        ) {
            const s = timer(1000, 5000).subscribe(() => {
                console.debug(
                    'Refreshing cart as payment is successful but cart state is not CONFIRMED'
                );
                return loadCart();
            });
            return () => s.unsubscribe();
        }
        return undefined;
    }, [paymentSuccess, cart, loadCart]);

    const handleError: OvaticContextType['handleError'] = useCallback(
        (error) => {
            console.error('handleError', error);
            if (error instanceof EbError) {
                if (error.hasErrorCode(ErrorCode.OVATIC_CART_CANCELLED)) {
                    return setError({
                        title: 'Winkelwagen verlopen',
                        message: 'Winkelwagen is verlopen. Probeer het opnieuw.'
                    });
                } else if (
                    error.hasErrorCode(ErrorCode.OVATIC_SEATS_NOT_AVAILABLE)
                ) {
                    return setError({
                        title: 'Plekken niet meer beschikbaar',
                        message:
                            'De geselecteerde plekken zijn niet meer beschikbaar. Kies alstublieft andere plekken.'
                    });
                } else if (
                    error.hasErrorCode(
                        ErrorCode.OVATIC_NOT_ENOUGH_SEATS_TOGETHER
                    )
                ) {
                    return setError({
                        title: 'Onvoldoende zitplekken naast elkaar gevonden',
                        message:
                            'Er zijn onvoldoende zitplekken beschikbaar om naast elkaar te kunnen zitten. (1) U kan proberen om de rang te veranderen waardoor het aanbod van zitplekken veranderd. (2) U kan het aantal tickets per keer verminderen. (3) U kan handmatig uw zitplekken selecteren.'
                    });
                } else if (
                    error.hasErrorCode(ErrorCode.OVATIC_NOT_ENOUGH_FREE_SEATS)
                ) {
                    return setError({
                        title: 'Onvoldoende vrije zitplekken',
                        message:
                            'Er zijn onvoldoende vrije zitplekken voor deze rang. (1) U kan proberen om de rang te veranderen waardoor het aanbod van zitplekken veranderd. (2) U kan het aantal tickets verminderen. (3) U kan handmatig uw zitplekken selecteren.'
                    });
                }

                let message = '';
                const errors = error?.errors;
                if (Array.isArray(errors)) {
                    errors.forEach((err) => {
                        if (err.detail) {
                            message += err.detail;
                        }
                        if (err.code) {
                            message += `(${err.code})`;
                        }
                    });
                }

                if (message.length === 0) {
                    message = `Onze excuses voor het ongemak. (fout ${error?.status})`;
                }

                setError({
                    title: 'Er is iets fout gegaan',
                    message
                });
            } else {
                setError(error);
            }
        },
        []
    );

    useEffect(() => {
        if (cart && cart.state === OvaticCartState.CANCELLED) {
            // This should normally not occur, as we handle cart renewal with activity timer.
            // But it could be that Ovatic cancels the cart unexepctedly or the renewal failed
            setCart(undefined);
            handleError({
                title: 'Winkelwagen verlopen',
                message:
                    'De winkelwagen is verlopen. Selecteer alstublieft opnieuw de tickets.'
            });
        }
    }, [cart, handleError]);

    useEffect(() => {
        if (showCart && !cart) {
            setShowCart(false);
        }
    }, [cart, showCart]);

    useEffect(() => {
        if (!cart) {
            setCartSecret('');
        }
    }, [cart]);

    const addTicketToCart = useCallback(
        (req: OvaticAddTicketToCartRequest, retry: boolean = true) => {
            resetActivity();
            const cartQuery = cart ? `&cart=${cart.id}` : '';
            return gateway
                .post<OvaticCartResponse>(
                    `/ovatic/v1/carts/tickets?org=${organizationId}${cartQuery}`,
                    req
                )
                .then((res) => {
                    setCart(res.data);
                    if (res.data.secret) {
                        setCartSecret(res.data.secret);
                    }
                    return res.data;
                })
                .catch((err) => {
                    if (
                        retry &&
                        err instanceof EbError &&
                        err.hasErrorCode(ErrorCode.OVATIC_CART_CANCELLED)
                    ) {
                        setCart(undefined);
                    }
                    return Promise.reject(err);
                });
        },
        [gateway, cart, organizationId, resetActivity]
    );

    const clearError = useCallback(() => setError(undefined), []);

    const cancelCart = useCallback(async () => {
        if (cart) {
            try {
                await gateway.delete(`/ovatic/v1/carts/${cart.id}`);
            } finally {
                // Just continue, cart will eventually expire
                setCart(undefined);
            }
        }
        setShowCart(false);
    }, [cart, gateway]);

    const cancelTransaction = useCallback(
        async (transactionId) => {
            resetActivity();
            if (cart) {
                try {
                    await gateway.delete(
                        `/ovatic/v1/carts/${cart.id}/transactions/${transactionId}`
                    );
                } finally {
                    await loadCart();
                }
            }
        },
        [cart, loadCart, gateway, resetActivity]
    );

    const registerAccount: OvaticContextType['registerAccount'] = useCallback(
        async (req: OvaticRegisterAccountRequest) => {
            const res = await gateway.post<OvaticAccountData>(
                `/ovatic/v1/accounts`,
                { ...req, org: organizationId }
            );
            setAccount(res.data);
        },
        [gateway, organizationId]
    );

    const loginAccount: OvaticContextType['loginAccount'] = useCallback(
        async (req: OvaticLoginAccountRequest) => {
            const res = await gateway.post<OvaticAccountData>(
                `/ovatic/v1/accounts/login`,
                { ...req, org: organizationId }
            );
            setAccount(res.data);
        },
        [gateway, organizationId]
    );

    const logout = useCallback(() => {
        setAccount(undefined);
    }, []);

    useEffect(() => {
        if (!showCart && cart && cart.state === OvaticCartState.CONFIRMED) {
            // Reset cart when closing the cart modal and the cart is confirmed (paid)
            setCart(undefined);
        }
    }, [showCart, cart]);

    const contextValue: OvaticContextType = useMemo<OvaticContextType>(
        () => ({
            activities,
            account,
            fetchActivityPrices,
            addTicketToCart,
            error,
            handleError,
            clearError,
            cart,
            cartSecret,
            showCart,
            setShowCart,
            cancelCart,
            cancelTransaction,
            registerAccount,
            loginAccount,
            logout
        }),
        [
            activities,
            account,
            fetchActivityPrices,
            addTicketToCart,
            error,
            handleError,
            clearError,
            cart,
            cartSecret,
            showCart,
            setShowCart,
            cancelCart,
            cancelTransaction,
            registerAccount,
            loginAccount,
            logout
        ]
    );

    return (
        <OvaticContext.Provider value={contextValue}>
            {children}
            <OvaticCartFixedButton />
            <OvaticErrorModal />
            <OvaticCartModal />
        </OvaticContext.Provider>
    );
};
