import {
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps
} from '@mui/material';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import 'react-simple-keyboard/build/css/index.css';
import KeyboardReact, { KeyboardReactInterface } from 'react-simple-keyboard';
import { Offcanvas, OffcanvasBody } from 'reactstrap';
import { UserActivityContext } from '../../../context/UserActivityContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export interface InputHandlers {
    onChange(value: string);
}

export interface KeyboardContextType {
    focus(value: string, handlers: InputHandlers, showValue: boolean): void;

    blur(): void;
}

export const KeyboardContext = React.createContext<KeyboardContextType>(
    undefined as never
);

export const KeyboardContextProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [value, setValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [handlers, setHandlers] = useState<InputHandlers>({} as any);
    const [showValue, setShowValue] = useState(false);
    const keyboardRef = useRef<KeyboardReactInterface>();
    const fieldRef = useRef<HTMLInputElement>();
    const { lastActivity, resetActivity } = useContext(UserActivityContext);
    const [layout, setLayout] = useState('default');
    const [showPassword, setShowPassword] = useState(false);

    const focus = useCallback<KeyboardContextType['focus']>(
        (value, handlers, showValue) => {
            setLayout('default');
            setValue(value);
            setHandlers(handlers);
            setIsOpen(true);
            setShowValue(showValue);
            setShowPassword(false);
        },
        []
    );

    const blur = useCallback(() => {
        setIsOpen(false);
    }, []);

    useEffect(() => {
        if (isOpen) {
            const timeout = setTimeout(() => {
                setIsOpen(false);
            }, 60_000);
            return () => clearTimeout(timeout);
        }
        return undefined;
    }, [lastActivity, isOpen]);

    const contextValue = useMemo<KeyboardContextType>(
        () => ({ focus: focus, blur: blur }),
        [blur, focus]
    );

    return (
        <KeyboardContext.Provider value={contextValue}>
            {children}
            <Offcanvas
                direction="bottom"
                isOpen={isOpen}
                toggle={() => setIsOpen(false)}
                returnFocusAfterClose={false}
                unmountOnClose
                zIndex={2000}
                style={{
                    height: 'auto'
                }}
                onOpened={() => {
                    keyboardRef.current.setInput(value);
                    setTimeout(() => fieldRef.current.focus());
                }}
            >
                <OffcanvasBody>
                    <div className="text-center mb-3">
                        <TextField
                            inputRef={fieldRef}
                            style={{
                                width: '50%'
                            }}
                            type={
                                showValue || showPassword ? 'text' : 'password'
                            }
                            label="Invoerveld"
                            value={value}
                            onChange={(e) => {
                                setValue(e.currentTarget.value);
                                handlers.onChange(e.currentTarget.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setIsOpen(false);
                                }
                            }}
                            autoComplete="off"
                            InputProps={{
                                endAdornment: showValue ? undefined : (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                resetActivity();
                                                setShowPassword(!showPassword);
                                            }}
                                        >
                                            {showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                    <KeyboardReact
                        keyboardRef={(ref) => (keyboardRef.current = ref)}
                        layoutName={layout}
                        onChange={(value) => {
                            setValue(value);
                            handlers.onChange(value);
                        }}
                        onKeyPress={(button) => {
                            if (button === '{shift}' || button === '{lock}') {
                                if (layout === 'default') {
                                    setLayout('shift');
                                } else {
                                    setLayout('default');
                                }
                            } else if (button === '{enter}') {
                                setIsOpen(false);
                            }
                            resetActivity();
                        }}
                    />
                </OffcanvasBody>
            </Offcanvas>
        </KeyboardContext.Provider>
    );
};

export const KioskTextField: React.FC<
    Omit<TextFieldProps, 'onChange'> & { onChange(value: string) }
> = ({ onChange, ...props }) => {
    const { focus } = useContext(KeyboardContext);

    return (
        <TextField
            {...(props as any)}
            autoComplete="off"
            onFocus={(e) => {
                focus(
                    props.value as any,
                    {
                        onChange
                    },
                    props.type !== 'password'
                );
                if (props.onFocus) {
                    props.onFocus(e);
                }
            }}
        />
    );
};
