import React, { useContext } from 'react';

import { ThemeLibrettoDetail } from './ThemeLibrettoDetail';
import { KioskLibrettoContext } from '../KioskLibrettoContext';
import { Spinner } from 'reactstrap';
import { LibrettoTheme } from '../data/KioskData';
import { ThemeGrid } from '../components/ThemeGrid';
import { OvaticItem } from '../../../ovatic/data/OvaticItem';
import { OvaticActivityContextProvider } from '../../../ovatic/component/OvaticActivityContextProvider';
import { LibrettoRow } from '../components/LibrettoRow';
import { CollectionType } from '../../smartbridge/data/CollectionType';
import { UserActivityContext } from '../../../context/UserActivityContext';

export const ThemeLibretto: React.FC = () => {
    const {
        module,
        theme,
        selected,
        rows,
        toHome,
        availableFilters,
        filter,
        setFilter
    } = useContext(KioskLibrettoContext);
    const { resetActivity } = useContext(UserActivityContext);

    const settings = theme.settings as LibrettoTheme;

    const isEmpty =
        rows?.map((it) => it.items.length).reduce((a, b) => a + b, 0) === 0;

    if (selected) {
        if (selected.ticket instanceof OvaticItem) {
            return (
                <OvaticActivityContextProvider item={selected.ticket}>
                    <ThemeLibrettoDetail />
                </OvaticActivityContextProvider>
            );
        }
        return <ThemeLibrettoDetail />;
    }

    return (
        <ThemeGrid data={theme} toHome={toHome}>
            <>
                <div
                    className="d-flex flex-row justify-content-around align-items-center position-relative w-100"
                    style={{
                        height: availableFilters.length > 1 ? '15%' : '5%',
                        minHeight: availableFilters.length > 1 ? '15%' : '5%',
                        padding: '0 5%'
                    }}
                >
                    {module?.provider === CollectionType.OVATIC &&
                        availableFilters.length > 1 &&
                        availableFilters.map((it, k) => {
                            return (
                                <span
                                    key={k}
                                    className="font-weight-bold"
                                    style={{
                                        color:
                                            it === filter
                                                ? settings.body.color
                                                : settings.body.secondaryColor,
                                        fontSize:
                                            it === filter ? '630%' : '420%'
                                    }}
                                    onClick={() => {
                                        resetActivity();
                                        setFilter(it);
                                    }}
                                >
                                    {it}
                                </span>
                            );
                        })}
                </div>
                <div
                    className="d-flex flex-column w-100"
                    style={{
                        height: availableFilters.length > 1 ? '85%' : '95%'
                    }}
                >
                    {rows && rows.length > 0 && (
                        <>
                            {rows.map((row, k) => {
                                if (row.items.length === 0) {
                                    return undefined;
                                }

                                return (
                                    <LibrettoRow
                                        key={k}
                                        k={k}
                                        rowCount={rows.length}
                                        row={row}
                                        body={settings.body}
                                    />
                                );
                            })}
                        </>
                    )}
                    {rows && isEmpty && (
                        <div className="d-flex w-100 h-100 align-items-center justify-content-center text-white">
                            No items to show
                        </div>
                    )}
                    {!rows && (
                        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                            <Spinner />
                        </div>
                    )}
                </div>
            </>
        </ThemeGrid>
    );
};
