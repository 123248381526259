import React, { useContext, useEffect, useState } from 'react';
import {
    HealthCheckData,
    HealthCheckState,
    HealthCheckStep
} from '../data/HealthCheckData';
import { ApplicationContext } from '../../context/ApplicationContext';
import { WebsocketPaths } from '../../constants';
import { Alert } from 'reactstrap';
import { Errors } from '../../errors/Errors';

export const ErrorNotification: React.FC = () => {
    const {
        limited,
        websocket: { app }
    } = useContext(ApplicationContext);

    const [health, setHealth] = useState<HealthCheckData>();

    useEffect(() => {
        if (!app) {
            return undefined;
        }

        const sub = app.observe(WebsocketPaths.health).subscribe({
            next: (data) => {
                const ht: HealthCheckData = JSON.parse(data);
                console.log('error-notification-health');
                console.log(ht);
                if (ht.state === HealthCheckState.FAILED) {
                    setHealth(ht);
                }

                if (
                    ht.step === HealthCheckStep.ALL &&
                    ht.state === HealthCheckState.SUCCESS
                ) {
                    setHealth(ht);
                }
            }
        });
        return () => sub.unsubscribe();
    }, [app, setHealth]);

    if (limited) {
        return (
            <div className="position-fixed w-100 px-3 text-center bottom-0">
                <Alert color="danger">
                    Er is iets mis, kiosk draait met beperkte functionaliteiten
                </Alert>
            </div>
        );
    }

    console.log(health);
    if (
        health &&
        health.step === HealthCheckStep.ALL &&
        health.state === HealthCheckState.SUCCESS
    ) {
        return undefined;
    }

    return (
        <div className="position-fixed w-100 px-3 text-center bottom-0">
            <Alert color="danger">
                {health?.step.toLowerCase() in Errors
                    ? Errors[health?.step.toLowerCase()]
                    : health?.step}
            </Alert>
        </div>
    );
};
