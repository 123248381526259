import React, { useCallback, useContext, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { VSmartItem } from '../data/VSmartItem';
import { ApplicationContext } from '../../context/ApplicationContext';
import * as Yup from 'yup';
import { object, ref, string } from 'yup';
import {
    Alert,
    Button,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import { PaymentContext } from '../../payment/context/PaymentContext';
import { SaveBorrowerField } from '../../payment/data/SaveBorrowerRequest';
import { FORMIK_GLOBAL_ERROR_FIELD } from '../../formik/FormikGlobalErrorValues';
import { Errors } from '../../errors/Errors';
import { Wizard } from '../../shared/wizard/Wizard';
import { WizardStep } from '../../shared/wizard/WizardStep';
import { DetailStep } from './DetailStep';
import { UserInfoStep } from './UserInfoStep';
import { PaymentProps, PaymentStep } from './PaymentStep';
import { FinishStep } from './FinishStep';
import { saveBorrowerErrors } from '../data/SaveBorrowerErrors';
import { testSubscriptionField, yupBuilder } from '../../core/utils/YupUtils';
import { useTranslation } from 'react-i18next';
import { EbError } from '../../shared/axios/EbError';
import { UserActivityContext } from '../../context/UserActivityContext';

export interface SubscriptionModalProps {
    isOpen: boolean;
    item?: VSmartItem;

    toggle();
}

type FormikValues = {
    step: SubscriptionStep;
    data: IguanaFields;
    password: string;
    repeatPassword: string;
    agreeTermsOfService: boolean;
};

export enum SubscriptionStep {
    DETAILS = 0,
    USERDATA = 1,
    PAYMENT = 2,
    FINISH = 3
}

interface IguanaFields {
    [key: string]: string;
}

export const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
    isOpen,
    item,
    toggle
}) => {
    const {
        services: { iguana },
        organizationId
    } = useContext(ApplicationContext);

    const [t] = useTranslation();

    //const { setSelectedSubscription } = useContext(SubscriptionContext);

    const { outOfOrder, transaction, success, reset } =
        useContext(PaymentContext);

    const [fields, setFields] = useState<IguanaGroupFieldData[] | EbError>();

    const [validated, setValidated] = useState(false);

    const [pendingPaymentDetails, setPendingPaymentDetails] =
        useState<PaymentProps>();
    const { resetActivity } = useContext(UserActivityContext);

    const renderBackButton = useCallback(
        (currentStep: SubscriptionStep, isCancel?: boolean): boolean => {
            switch (currentStep) {
                case SubscriptionStep.FINISH:
                    return false;
                case SubscriptionStep.PAYMENT: {
                    if (transaction && success !== false) {
                        return false;
                    }

                    return isCancel;
                }
                case SubscriptionStep.DETAILS:
                    return isCancel;
                case SubscriptionStep.USERDATA:
                default:
                    return !outOfOrder || isCancel;
            }
        },
        [outOfOrder, success, transaction]
    );

    const previousButton = useCallback(
        (formikProps: FormikProps<FormikValues>) => {
            if (formikProps.values.step === SubscriptionStep.USERDATA) {
                if (validated) {
                    setValidated(false);
                    return;
                } else {
                    formikProps.resetForm();
                }
            }
            formikProps.setFieldValue('step', formikProps.values.step - 1);
        },
        [validated]
    );

    const renderContinueButton = useCallback(
        (currentStep: SubscriptionStep): boolean => {
            switch (currentStep) {
                case SubscriptionStep.PAYMENT: {
                    return !(
                        !success &&
                        pendingPaymentDetails &&
                        pendingPaymentDetails.money > 0
                    );
                }
                case SubscriptionStep.FINISH:
                    return true;
                case SubscriptionStep.DETAILS:
                case SubscriptionStep.USERDATA:
                default:
                    return !outOfOrder;
            }
        },
        [outOfOrder, pendingPaymentDetails, success]
    );

    const nextButton = (step: SubscriptionStep): string => {
        switch (step) {
            case SubscriptionStep.USERDATA:
                if (validated) {
                    return 'Activeer account';
                } else {
                    return 'Volgende';
                }
            case SubscriptionStep.PAYMENT: {
                if (success === undefined) {
                    return 'In afwachting';
                } else {
                    if (success) {
                        return 'Naar overzicht';
                    } else {
                        return 'Probeer opnieuw';
                    }
                }
            }
            case SubscriptionStep.FINISH:
                return 'Afronden';
            default:
                return 'Volgende';
        }
    };

    const passwordValidators: {
        label: string;
        test: (password: string) => boolean;
    }[] = [
        {
            label: 'Wachtwoord moet minimaal 8 en maximaal 15 tekens bevatten',
            test: (password) => password.length >= 8 && password.length < 16
        },
        {
            label: 'Wachtwoord moet minimaal 1 hoofdletter bevatten',
            test: (password) => /[A-Z]/.test(password)
        },
        {
            label: 'Wachtwoord moet minimaal 1 kleine letter bevatten',
            test: (password) => /[a-z]/.test(password)
        },
        {
            label: 'Wachtwoord moet minimaal 1 nummer bevatten',
            test: (password) => /[0-9]/.test(password)
        },
        {
            label: 'Wachtwoord moet minimaal 1 van de volgende speciale tekens bevatten @!#$%*+-/=?{|}',
            test: (password) => /[@!#$%*+-/=?{|}]/.test(password)
        }
    ];

    return (
        <Formik<FormikValues>
            initialValues={{
                step: SubscriptionStep.DETAILS,
                data: {},
                password: '',
                repeatPassword: '',
                agreeTermsOfService: false
            }}
            validationSchema={object().shape({
                data: object().when('step', {
                    is: SubscriptionStep.USERDATA,
                    then: object().shape(
                        !fields || fields instanceof EbError
                            ? { dummy: Yup.boolean().required() }
                            : fields
                                  .filter((f) => f.mandatory)
                                  .reduce(
                                      (a, v) => ({
                                          ...a,
                                          [`${v.fieldId}`]: Yup.string()
                                              .required(
                                                  'Dit is een verplicht veld'
                                              )
                                              .test(
                                                  testSubscriptionField(
                                                      `data.${v.fieldId}`,
                                                      v.type
                                                  )
                                              )
                                      }),
                                      {}
                                  )
                    )
                }),
                password: string().when('step', {
                    is: SubscriptionStep.USERDATA,
                    then: yupBuilder(t)
                        .string()
                        .required()
                        .build()
                        .test({
                            name: 'password',
                            test: function (value: string) {
                                if (
                                    value &&
                                    !passwordValidators.every((pv) =>
                                        pv.test(value)
                                    )
                                ) {
                                    const error = (
                                        <>
                                            {passwordValidators
                                                .filter((pv) => !pv.test(value))
                                                .map((pv, k) => {
                                                    return (
                                                        <React.Fragment key={k}>
                                                            {pv.label}
                                                            <br />
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </>
                                    );

                                    return (this as any).createError({
                                        message: error,
                                        path: 'password'
                                    });
                                }
                                return true;
                            }
                        })
                }),
                repeatPassword: Yup.string().when('step', {
                    is: SubscriptionStep.USERDATA,
                    then: yupBuilder(t)
                        .string()
                        .required()
                        .build()
                        .oneOf(
                            [ref('password'), null],
                            'Wachtwoord komt niet overeen'
                        )
                }),
                agreeTermsOfService: Yup.boolean().when('step', {
                    is: SubscriptionStep.USERDATA,
                    then: yupBuilder(t)
                        .boolean()
                        .required()
                        .build()
                        .test({
                            name: 'agreeTermsOfService',
                            test: function (value: boolean) {
                                if (!value) {
                                    return (this as any).createError({
                                        message:
                                            'U moet akkoord gaan met de algemene voorwaarden om gebruik te mogen maken van deze dienst',
                                        path: 'agreeTermsOfService'
                                    });
                                }
                                return true;
                            }
                        })
                })
            })}
            onSubmit={async (values, formikHelpers) => {
                resetActivity();
                formikHelpers.setFieldTouched('password', false);
                formikHelpers.setFieldTouched('repeatPassword', false);
                formikHelpers.setFieldTouched('agreeTermsOfService', false);

                if (values.step === SubscriptionStep.DETAILS) {
                    formikHelpers.setFieldValue(
                        'step',
                        SubscriptionStep.USERDATA
                    );
                    if (
                        !fields ||
                        fields instanceof EbError ||
                        fields.length === 0
                    ) {
                        try {
                            const _data = await iguana.getFields(
                                organizationId,
                                item.getId()
                            );
                            setFields(_data);
                            formikHelpers.setFieldValue(
                                'data',
                                _data.reduce(
                                    (a, v) => ({
                                        ...a,
                                        [v.fieldId]: v.defaultValue
                                    }),
                                    {}
                                )
                            );
                            console.log(_data);
                        } catch (e) {
                            setFields(e);
                        }
                    }
                    return;
                }

                if (values.step === SubscriptionStep.USERDATA && !validated) {
                    console.log('not validated');
                    try {
                        const mappedFields: SaveBorrowerField[] =
                            Object.entries(values.data).map(([key, value]) => {
                                if (key === 'BGrp01Identity/DateOfBirth') {
                                    const date = new Date(value);
                                    value = `${date.getFullYear()}${(
                                        date.getMonth() + 1
                                    )
                                        .toString()
                                        .padStart(2, '0')}${date
                                        .getDate()
                                        .toString()
                                        .padStart(2, '0')}`;
                                }
                                return {
                                    id: key,
                                    value: value
                                };
                            });

                        await iguana.saveBorrower(
                            organizationId,
                            item.getId(),
                            {
                                dupFields: '',
                                fields: mappedFields
                            },
                            true
                        );
                        setValidated(true);
                    } catch (e) {
                        console.log(e.errors);

                        e.errors.forEach((error) => {
                            if (error.detail in saveBorrowerErrors) {
                                error.detail = saveBorrowerErrors[error.detail];
                            }

                            if (error.code in values.data) {
                                formikHelpers.setFieldError(
                                    `data.${error.code}`,
                                    error.detail
                                );
                                return;
                            }

                            formikHelpers.setFieldError(
                                FORMIK_GLOBAL_ERROR_FIELD,
                                error.detail
                            );
                        });
                    }
                }

                if (values.step === SubscriptionStep.USERDATA && validated) {
                    try {
                        const mappedFields: SaveBorrowerField[] =
                            Object.entries(values.data).map(([key, value]) => {
                                if (key === 'BGrp01Identity/DateOfBirth') {
                                    const date = new Date(value);
                                    value = `${date.getFullYear()}${(
                                        date.getMonth() + 1
                                    )
                                        .toString()
                                        .padStart(2, '0')}${date
                                        .getDate()
                                        .toString()
                                        .padStart(2, '0')}`;
                                }
                                return {
                                    id: key,
                                    value: value
                                };
                            });

                        const borrower = await iguana.saveBorrower(
                            organizationId,
                            item.getId(),
                            {
                                dupFields: '',
                                fields: mappedFields
                            },
                            false
                        );

                        const activate = await iguana.activateBorrower(
                            organizationId,
                            borrower.activationCode
                        );
                        console.log('activate');
                        console.log(activate);

                        setPendingPaymentDetails({
                            email: values.data['BGrp02Addr01/Addr1Email'],
                            money: activate.amount,
                            reference: {
                                borrowerId: borrower.borrowerId,
                                bookingCode: item.data.bookingCode.code,
                                activationCode: borrower.activationCode
                            }
                        });

                        formikHelpers.setFieldValue(
                            'step',
                            SubscriptionStep.PAYMENT
                        );
                    } catch (e) {
                        e.errors.forEach((error) => {
                            console.log(error);
                            if (error.detail in saveBorrowerErrors) {
                                error.detail = saveBorrowerErrors[error.detail];
                            }

                            if (error.code in values.data) {
                                formikHelpers.setFieldError(
                                    `data.${error.code}`,
                                    error.detail
                                );
                                return;
                            }

                            formikHelpers.setFieldError(
                                FORMIK_GLOBAL_ERROR_FIELD,
                                error.detail
                            );
                        });
                    }

                    return;
                }

                if (values.step === SubscriptionStep.PAYMENT) {
                    formikHelpers.setFieldValue(
                        'step',
                        SubscriptionStep.FINISH
                    );
                }

                if (values.step === SubscriptionStep.FINISH) {
                    toggle();
                }
            }}
            validateOnChange
            validateOnBlur
            enableReinitialize
        >
            {(formikProps) => {
                return (
                    <Modal
                        isOpen={isOpen}
                        toggle={() => toggle()}
                        backdrop={'static'}
                        onClosed={() => {
                            reset();
                            setFields(undefined);
                            setValidated(false);
                            formikProps.resetForm();
                        }}
                        returnFocusAfterClose={false}
                        size="xl"
                        centered
                    >
                        <ModalHeader>
                            <span className="fs-2">LID WORDEN</span>
                        </ModalHeader>
                        {item && (
                            <Form onSubmit={formikProps.handleSubmit}>
                                <ModalBody className="position-relative fs-5">
                                    <Row>
                                        <Col>
                                            <Wizard
                                                activeStep={
                                                    formikProps.values.step
                                                }
                                                maxStep={
                                                    SubscriptionStep.FINISH
                                                }
                                                changeStep={(step) => {
                                                    formikProps.setFieldValue(
                                                        'step',
                                                        step
                                                    );
                                                }}
                                            >
                                                <WizardStep
                                                    step={
                                                        SubscriptionStep.DETAILS
                                                    }
                                                    name="Details"
                                                >
                                                    {formikProps.values.step ===
                                                        SubscriptionStep.DETAILS && (
                                                        <DetailStep
                                                            item={item}
                                                        />
                                                    )}
                                                </WizardStep>
                                                <WizardStep
                                                    step={
                                                        SubscriptionStep.USERDATA
                                                    }
                                                    name="Account informatie"
                                                >
                                                    {formikProps.values.step ===
                                                        SubscriptionStep.USERDATA && (
                                                        <UserInfoStep
                                                            item={item}
                                                            fields={fields}
                                                            validated={
                                                                validated
                                                            }
                                                        />
                                                    )}
                                                </WizardStep>
                                                <WizardStep
                                                    step={
                                                        SubscriptionStep.PAYMENT
                                                    }
                                                    name="Betalen"
                                                >
                                                    {formikProps.values.step ===
                                                        SubscriptionStep.PAYMENT && (
                                                        <PaymentStep
                                                            nextStep={() => {
                                                                formikProps.setFieldValue(
                                                                    'step',
                                                                    SubscriptionStep.FINISH
                                                                );
                                                            }}
                                                            {...pendingPaymentDetails}
                                                        />
                                                    )}
                                                </WizardStep>
                                                <WizardStep
                                                    step={
                                                        SubscriptionStep.FINISH
                                                    }
                                                    name="Samenvatting"
                                                >
                                                    {formikProps.values.step ===
                                                        SubscriptionStep.FINISH && (
                                                        <FinishStep
                                                            item={item}
                                                            password={
                                                                formikProps
                                                                    .values
                                                                    .password
                                                            }
                                                        />
                                                    )}
                                                </WizardStep>
                                            </Wizard>
                                        </Col>
                                    </Row>
                                    {outOfOrder && (
                                        <Alert color="danger">
                                            {Errors.pin}
                                        </Alert>
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    {renderBackButton(
                                        formikProps.values.step,
                                        true
                                    ) && (
                                        <Button
                                            className="fs-5"
                                            color="secondary"
                                            type="button"
                                            onClick={() => {
                                                resetActivity();
                                                toggle();
                                            }}
                                            disabled={formikProps.isSubmitting}
                                        >
                                            Annuleren
                                        </Button>
                                    )}
                                    {renderBackButton(
                                        formikProps.values.step
                                    ) && (
                                        <Button
                                            className="fs-5"
                                            color="secondary"
                                            type="button"
                                            onClick={() => {
                                                resetActivity();
                                                previousButton(formikProps);
                                            }}
                                            disabled={formikProps.isSubmitting}
                                        >
                                            Vorige
                                        </Button>
                                    )}
                                    {renderContinueButton(
                                        formikProps.values.step
                                    ) && (
                                        <Button
                                            className="fs-5"
                                            color="primary"
                                            type="submit"
                                            disabled={
                                                !formikProps.isValid ||
                                                formikProps.isSubmitting
                                            }
                                        >
                                            {nextButton(
                                                formikProps.values.step
                                            )}
                                        </Button>
                                    )}
                                </ModalFooter>
                            </Form>
                        )}
                    </Modal>
                );
            }}
        </Formik>
    );
};
