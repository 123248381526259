import React from 'react';
import { WizardStepMeta } from './WizardStep';

export interface IWizardContext {
    activeStep: number;
    maxStep: number;
    steps: WizardStepMeta[];
    changeStep(step: number);
}

export const WizardContext = React.createContext<IWizardContext>({} as any);
