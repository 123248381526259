import React, { useCallback, useEffect } from 'react';
import { BootSplash } from '../../shared/boot/BootSplash';

export const RedirectEbRoute: React.FC = () => {
    const load = useCallback(() => {
        const timeout = setTimeout(() => {
            window.location.replace('https://eastbridge.eu');
        }, 10_000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        load();
    }, [load]);

    return (
        <BootSplash
            message={{
                type: 'danger',
                value: (
                    <>
                        Invalid url
                        <br />
                        redirecting to Eastbridge
                    </>
                )
            }}
            spinner={true}
        />
    );
};
