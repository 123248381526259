import React from 'react';
import { Col, Spinner } from 'reactstrap';

import styled, { keyframes } from 'styled-components';
import { bounceInDown, rubberBand } from 'react-animations';

const bounceAnimation = keyframes`${bounceInDown}`;
const rubberAnimation = keyframes`${rubberBand}`;

const BouncyDiv = styled.div`
    animation: 1s ${bounceAnimation};
    font-size: 750%;
`;

const RubberDiv = styled.div`
    animation-delay: 1.5s;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: ${rubberAnimation};
`;

export const BootSplash: React.FC<{
    message?: {
        type: 'info' | 'success' | 'danger' | 'warning';
        value: JSX.Element | string;
    };
    spinner?: boolean;
}> = ({ message, spinner }) => {
    const style: React.CSSProperties = {
        backgroundImage: 'url(/assets/img/Eastbridge-flags.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 100,
        top: 0,
        left: 0
    };

    const fontStyle: React.CSSProperties = {
        marginTop: '50vh',
        fontFamily: 'Neuropol'
    };

    const splash = (
        <>
            <span style={{ color: '#95C122' }}>east</span>
            <span className="text-white">bridge</span>
        </>
    );

    return (
        <Col
            className="position-fixed vw-100 vh-100 d-flex align-items-center justify-content-center overflow-hidden"
            style={style}
        >
            <Col style={fontStyle}>
                <Col className="text-center">
                    <div style={{ fontSize: '250%' }}>
                        {message && (
                            <>
                                {spinner && (
                                    <>
                                        <Spinner
                                            className={`text-${message.type}`}
                                        />{' '}
                                    </>
                                )}
                                <span className={`text-${message.type}`}>
                                    {message.value}
                                </span>
                            </>
                        )}
                    </div>
                    <BouncyDiv>
                        <RubberDiv>{splash}</RubberDiv>
                    </BouncyDiv>
                </Col>
            </Col>
        </Col>
    );
};
