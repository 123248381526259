import React from 'react';
import { SanitizedDescription } from './SanitizedDescription';
import { VSmartItem } from '../data/VSmartItem';

export const DetailStep: React.FC<{ item: VSmartItem }> = ({ item }) => {
    return (
        <>
            <div className="d-flex flex-column">
                <p>
                    Fijn dat u lid wilt worden van onze bibliotheek met het{' '}
                    <span className="fw-bold">{item.getName()}</span>{' '}
                    abonnement.
                    <br />
                    Dit abonnement is{' '}
                    <span className="fw-bold">{item.getSubtitle()}</span>
                </p>
                <SanitizedDescription>
                    {item.getDescription()}
                </SanitizedDescription>
            </div>
        </>
    );
};
