import { OvaticActivitySeatData } from './OvaticActivitySeatData';

export enum OvaticSeatStatus {
    UNKNOWN = 'unknown',
    AVAILABLE = 1,
    /**
     * When a seat is added in a cart, it becomes temporarily locked until the cart expires or is checked out.
     * However should the cart expire, then it will become available again.
     */
    LOCKED_BY_OTHER_USER = 4,
    BLOCKED = 11
}

export const getSeatStatus = (
    seat: OvaticActivitySeatData
): OvaticSeatStatus => {
    if (seat.blocked) {
        return OvaticSeatStatus.BLOCKED;
    } else {
        return seat.status;
    }
};

export const getSeatStatusName = (status: OvaticSeatStatus): string => {
    switch (status) {
        case OvaticSeatStatus.AVAILABLE:
            return 'Beschikbaar';
        case OvaticSeatStatus.BLOCKED:
            return 'Geblokkeerd';
        case OvaticSeatStatus.UNKNOWN: {
            return 'Onbekend';
        }
        case OvaticSeatStatus.LOCKED_BY_OTHER_USER: {
            return 'Gereserveerd door een andere gebruiker';
        }
        default:
            return status;
    }
};
