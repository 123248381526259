export interface PaymentTransactionData {
    id: string;
    created?: Date;
    updated?: Date;
    paymentSource: 'EASTBRIDGE' | 'LMS' | 'OVATIC';
    paymentType: 'TOP_UP' | 'PAY';
    transactionType: 'CASH' | 'ELECTRONIC' | 'QR';
    itemType: 'PRINT' | 'COPY' | 'TICKET' | 'SUBSCRIPTION' | 'OTHER';
    itemReference: string;
    money: number;
    status:
        | 'ACTIVE'
        | 'ABORTED'
        | 'RECEIPT'
        | 'SUCCESS'
        | 'PROVIDER'
        | 'MAILING'
        | 'FINISHED';
    receipt: string;
    email: string;
}

export enum PaymentTransactionStatus {
    'ACTIVE',
    'ABORTED',
    'RECEIPT',
    'SUCCESS',
    'PROVIDER',
    'MAILING',
    'FINISHED'
}
