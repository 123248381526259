import React, { useContext } from 'react';
import { IBody } from '../data/KioskData';
import '../scss/InfiniteScroll.scss';
import { UserActivityContext } from '../../../context/UserActivityContext';

export const InfiniteCard: React.FC<{
    title: string;
    classes?: string;
    data: IBody;
    imageUrl?: string;
    action?();
}> = ({ title, classes, data, action, imageUrl }) => {
    const _imageUrl =
        imageUrl ??
        'https://agenda.ticketunie.com/img/thumbnail.ashx?trsid=416&id=6734&imagegroup=1';
    const { resetActivity } = useContext(UserActivityContext);

    return (
        <div
            className={`infinite-card ${classes}`}
            style={{
                backgroundColor: data.itemBackgroundColor,
                width: `calc(100%/${data.maxItems})`,
                height: '100%'
            }}
            onClick={() => {
                resetActivity();
                action?.();
            }}
        >
            <div
                className="d-flex w-100"
                style={{
                    height: '90%'
                }}
            >
                <img
                    alt={title}
                    style={{
                        objectFit: 'cover',
                        height: '100%',
                        width: '100%'
                    }}
                    src={_imageUrl}
                />
            </div>
            <div
                className="d-flex flex-column justify-content-end align-items-center"
                style={{
                    height: '10%',
                    fontSize: '170%',
                    color: data.color
                }}
            >
                <span>{title}</span>
            </div>
        </div>
    );
};
