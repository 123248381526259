import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import { OvaticContext } from '../context/OvaticContext';
import React from 'react';
import { UserActivityContext } from '../../context/UserActivityContext';

const ERROR_COUNT_DOWN = 30;

export const OvaticErrorModal: React.FC = () => {
    const { error, clearError } = useContext(OvaticContext);
    const [countDown, setCountDown] = useState(ERROR_COUNT_DOWN);
    const { resetActivity } = useContext(UserActivityContext);

    useEffect(() => {
        // Reset countdown
        setCountDown(ERROR_COUNT_DOWN);
    }, [error]);

    useEffect(() => {
        if (error) {
            if (countDown > 0) {
                const timeout = setTimeout(() => {
                    setCountDown(countDown - 1);
                }, 1000);
                return () => clearTimeout(timeout);
            } else {
                clearError();
            }
        }
        return undefined;
    }, [error, countDown, clearError]);

    return (
        <Modal centered isOpen={!!error} toggle={clearError}>
            <ModalHeader>{error?.title}</ModalHeader>
            <ModalBody>{error?.message}</ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => {
                        clearError();
                        resetActivity();
                    }}
                >
                    Ga verder (sluit over {countDown})
                </Button>
            </ModalFooter>
        </Modal>
    );
};
