import React, { useContext } from 'react';
import { WizardContext } from './WizardContext';
import classNames from 'classnames';

export interface WizardStepMeta {
    step: number;
    name: string;
}

export interface WizardStepProps extends WizardStepMeta {
    children: React.ReactNode;
}

export const WizardStep: React.FC<WizardStepProps> = ({ children, step }) => {
    const context = useContext(WizardContext);

    return (
        <div
            className={classNames('wizard-step', {
                active: step === context.activeStep
            })}
        >
            {children}
        </div>
    );
};
