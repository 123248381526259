import moment from 'moment-timezone';
import 'moment/locale/nl';
import React from 'react';

export interface FormatDateProps {
    date: Date;
}

export const FormatDate: React.FC<FormatDateProps> = ({ date }) => {
    return (
        <span className="text-nowrap">
            {moment(date).locale('nl').format('dd DD MMMM, yyyy')}
        </span>
    );
};
