import React, { useCallback, useEffect, useState } from 'react';

export interface UserActivityContextType {
    /**
     * Last activity as millis since epoch.
     */
    lastActivity: number;

    resetActivity();
}

export const UserActivityContext = React.createContext<UserActivityContextType>(
    undefined as any
);

export const UserActivityContextProvider: React.FC<{ children }> = ({
    children
}) => {
    const [lastActivity, setLastActivity] = useState(new Date().getTime());

    const reset = useCallback(() => {
        setLastActivity((lastActivity) => {
            const currentTime = new Date().getTime();
            if (currentTime - lastActivity > 5_000) {
                console.debug(`Detected activity`);
                return currentTime;
            }
            return lastActivity;
        });
    }, []);

    useEffect(() => {
        const events = ['click', 'touchstart'];
        const cleanup = [];
        events.forEach((event) => {
            const listener = () => {
                reset();
            };
            window.addEventListener(event, listener);
            cleanup.push(() => window.removeEventListener(event, listener));
        });
        return () => cleanup.forEach((tearDown) => tearDown());
    }, [reset]);

    const contextValue: UserActivityContextType = {
        lastActivity,
        resetActivity: reset
    };

    return (
        <UserActivityContext.Provider value={contextValue}>
            {children}
        </UserActivityContext.Provider>
    );
};
