import React, { useContext } from 'react';
import { InputField } from './InputField';
import {
    Alert,
    Button,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner
} from 'reactstrap';
import { EbError } from '../../shared/axios/EbError';
import { VSmartItem } from '../data/VSmartItem';
import { FormikGlobalErrorField } from '../../formik/FormikGlobalErrorField';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { KioskContext } from '../../context/KioskContext';
import { UserActivityContext } from '../../context/UserActivityContext';

export const UserInfoStep: React.FC<{
    item: VSmartItem;
    fields: IguanaGroupFieldData[] | EbError;
    validated: boolean;
}> = ({ item, fields, validated }) => {
    const { setShowTermsOfService } = useContext(KioskContext);
    const { resetActivity } = useContext(UserActivityContext);

    const loaded = !(fields instanceof EbError) && fields && fields.length > 0;

    const getNormalType = (
        iguanaType: string
    ): 'checkbox' | 'email' | 'date' | 'select' | 'text' => {
        if (iguanaType === 'chk') {
            return 'checkbox';
        } else if (iguanaType === 'eml') {
            return 'email';
        } else if (iguanaType === 'dat') {
            return 'date';
        } else if (iguanaType === 'sel') {
            return 'select';
        }

        return 'text';
    };

    return (
        <>
            {fields instanceof EbError && (
                <Alert color="danger">
                    Verplichte account informatie kon niet worden geladen.
                    <br />
                    Probeer het later opnieuw of ga naar het personeel voor
                    hulp.
                </Alert>
            )}
            {!fields && (
                <span>
                    <Spinner size="sm" color="primary" /> Benodigde persoonlijke
                    gegevens worden geladen.
                </span>
            )}
            {loaded && (
                <p>
                    De volgende persoonlijke gegevens zijn nodig om lid te
                    worden met het <b>{item.getName()}</b> abonnement.
                </p>
            )}
            {loaded &&
                fields
                    .filter((group) => group.mandatory === 1)
                    .map((group) => {
                        return (
                            <InputField
                                key={group.fieldId}
                                id={`data.${group.fieldId}`}
                                label={group.name}
                                type={getNormalType(group.type)}
                                options={group.options}
                                mandatory={group.mandatory === 1}
                                valid={validated}
                                autoComplete="off"
                            />
                        );
                    })}
            {loaded && (
                <Row>
                    <Col className="mt-3">
                        <p>
                            Het wachtwoord waarmee u kan inloggen op het
                            bibliotheek systeem
                        </p>
                        <InputField
                            key={'password'}
                            id={`password`}
                            label={`Wachtwoord`}
                            type={'password'}
                            autoComplete="off"
                            valid={validated}
                            mandatory={true}
                        />
                        <InputField
                            key={'repeatPassword'}
                            id={`repeatPassword`}
                            label={`Herhaal wachtwoord`}
                            type={'password'}
                            autoComplete="off"
                            valid={validated}
                            mandatory={true}
                        />
                        <Field name="agreeTermsOfService">
                            {(fieldProps: FieldProps) => (
                                <FormGroup className="mb-3" check>
                                    <Input
                                        type="checkbox"
                                        {...fieldProps.field}
                                        invalid={
                                            fieldProps.meta.touched &&
                                            !!fieldProps.meta.error
                                        }
                                    />
                                    <Label check>
                                        Ik ga akkoord met de{' '}
                                        <Button
                                            type="button"
                                            color="link"
                                            className="p-0 m-0 align-baseline fs-5"
                                            onClick={() => {
                                                resetActivity();
                                                setShowTermsOfService(true);
                                            }}
                                        >
                                            algemene voorwaarden
                                        </Button>
                                    </Label>
                                    {fieldProps.meta.touched && (
                                        <FormFeedback invalid>
                                            <ErrorMessage
                                                name={fieldProps.field.name}
                                            />
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            )}
                        </Field>
                    </Col>
                </Row>
            )}
            {validated && (
                <Alert color="warning">
                    Controleer uw persoonlijke gegevens voordat u uw account
                    activeert
                </Alert>
            )}
            <FormikGlobalErrorField />
        </>
    );
};
