import React, { useContext, useEffect, useState } from 'react';
import { UserActivityContext } from '../../context/UserActivityContext';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { OvaticContext } from '../context/OvaticContext';
import { timer } from 'rxjs';
import { KioskContext } from '../../context/KioskContext';
import { ApplicationContext } from '../../context/ApplicationContext';
import { KeyboardContext } from '../../shared/kiosk/components/KioskTextField';

const COUNT_DOWN = 30;

export const InactiveModal: React.FC = () => {
    const { displayConfig } = useContext(ApplicationContext);
    const { lastActivity, resetActivity } = useContext(UserActivityContext);
    const { page, toHome } = useContext(KioskContext);
    const [active, setActive] = useState(true);
    const { cart, cancelCart, logout } = useContext(OvaticContext);
    const [countDown, setCountDown] = useState(COUNT_DOWN);
    const { blur } = useContext(KeyboardContext);

    useEffect(() => {
        setActive(true);
        const timeout = setTimeout(() => {
            setActive(false);
            setCountDown(COUNT_DOWN);
            blur();
        }, displayConfig.settings.inactiveTime * 1000);
        return () => clearTimeout(timeout);
    }, [blur, displayConfig.settings.inactiveTime, lastActivity]);

    useEffect(() => {
        if (active || countDown === 0) {
            return;
        }

        setTimeout(() => {
            setCountDown(countDown - 1);
        }, 1000);
    }, [countDown, active]);

    useEffect(() => {
        if (active || countDown > 0) {
            return undefined;
        }
        const subscription = timer(0, 5_000).subscribe(() => {
            logout();
            cancelCart().finally(() => {
                toHome();
            });
        });
        return () => subscription.unsubscribe();
    }, [countDown, cart, active, cancelCart, toHome, logout]);

    return (
        <Modal
            isOpen={!active && (!!cart || !!page)}
            toggle={resetActivity}
            centered
        >
            <ModalHeader>
                <span className="fs-2">Ben je er nog?</span>
            </ModalHeader>
            <ModalBody>
                Als je er nog bent, klik dan op de{' '}
                <strong>ik ben er nog</strong> knop.
                {cart?.ticketCount > 0 &&
                    ' Doe je dat niet op tijd, dan worden de tickets in je winkelwagen vrijgegeven.'}
            </ModalBody>
            <ModalFooter>
                <Button onClick={resetActivity}>
                    Ik ben er nog ({countDown})
                </Button>
            </ModalFooter>
        </Modal>
    );
};
