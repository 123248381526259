import { EbError } from '../../shared/axios/EbError';

export type Length = number | DataType;
export type DataType = 'TINYTEXT' | 'TEXT';

export function resolveLength(length: Length): number {
    if (typeof length === 'number') {
        return length;
    } else {
        return getDataTypeMaxLength(length);
    }
}

export function getDataTypeMaxLength(dataType: DataType) {
    switch (dataType) {
        case 'TINYTEXT':
            return 255;
        case 'TEXT':
            return 65535;
        default:
            throw new EbError(400, `Unknown data type ${dataType}`);
    }
}
