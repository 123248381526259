import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { ApplicationContextProvider } from './context/ApplicationContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';
import { RedirectEbRoute } from './routes/default/RedirectEbRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './scss/modal.scss';

export const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/:organizationId/:displayId/:hash"
                    element={<ApplicationContextProvider />}
                />
                <Route path="/*" element={<RedirectEbRoute />} />
            </Routes>
            <ToastContainer pauseOnFocusLoss={false} />
        </BrowserRouter>
    );
};
