import { EbError } from '../shared/axios/EbError';

export const FORMIK_GLOBAL_ERROR_FIELD = 'globalError';

/**
 * Interface that can be extended by a FormikValues, used to declare a field which holds the global error.
 * Use with {@link FormikGlobalErrorField}
 */
export interface FormikGlobalErrorValues {
    globalError?: EbError;
}
