import React, { useContext, useEffect, useState } from 'react';
import { PaymentContext } from '../../payment/context/PaymentContext';
import { VSmartItem } from '../data/VSmartItem';
import { EbError } from '../../shared/axios/EbError';
import { Alert, Button, Col, Row, Spinner } from 'reactstrap';
import { PaymentTransactionStatus } from '../../payment/data/PaymentTransactionData';
import { ApplicationContext } from '../../context/ApplicationContext';
import Barcode from 'react-barcode/lib/react-barcode';
import { TransactionReceipt } from '../../payment/components/TransactionReceipt';
import { UserActivityContext } from '../../context/UserActivityContext';

export const FinishStep: React.FC<{ item: VSmartItem; password: string }> = ({
    item,
    password
}) => {
    const {
        api: { app },
        organizationId,
        services: { iguana }
    } = useContext(ApplicationContext);
    const { transaction, printerAvailable } = useContext(PaymentContext);

    const [barcodePrinted, setBarcodePrinted] = useState(false);

    const [printing, setPrinting] = useState(false);

    const [passwordUpdated, setPasswordUpdate] = useState<boolean | EbError>();
    const { resetActivity } = useContext(UserActivityContext);

    useEffect(() => {
        if (!transaction || transaction instanceof EbError || passwordUpdated) {
            return;
        }

        const reference = JSON.parse(transaction.itemReference) as {
            borrowerId: string;
        };

        iguana
            .setPassword(organizationId, {
                borrowerId: reference.borrowerId,
                newPassword: password
            })
            .then(() => {
                setPasswordUpdate(true);
            })
            .catch((e) => {
                setPasswordUpdate(e);
            });
    }, [iguana, organizationId, password, passwordUpdated, transaction]);

    if (!transaction || transaction instanceof EbError) {
        return (
            <Alert>Er is iets mis gegaan, naar de klantenbalie voor hulp</Alert>
        );
    }

    const borrowerId = JSON.parse(transaction.itemReference)['borrowerId'];

    const status = PaymentTransactionStatus[transaction.status];

    // Minimum transaction status here is SUCCESS
    if (status < PaymentTransactionStatus.PROVIDER) {
        return (
            <>
                <Spinner size="sm" /> Moment geduld uw account word geactiveerd
            </>
        );
    }

    return (
        <>
            <Row>
                <p className="fs-3">Welkom bij onze bibliotheek.</p>
                <p>
                    U heeft het <b>{item.getName()}</b> abonnement aangeschaft
                    en geactiveerd.
                </p>
            </Row>
            {!(transaction instanceof EbError) && (
                <>
                    {passwordUpdated instanceof EbError && (
                        <Alert color="danger">
                            Er is iets mis gegaan bij het synchroniseren van uw
                            wachtwoord. Ga naar het personeel voor hulp.
                        </Alert>
                    )}
                    {!passwordUpdated && (
                        <Alert color="info">
                            <Spinner size="sm" color="warning" /> Een moment
                            geduld, uw wachtwoord word gesynchroniseerd. Als de
                            synchronisatie compleet is kunt u gelijk uw account
                            gebruiken op het bibliotheek systeem.
                        </Alert>
                    )}
                    {passwordUpdated &&
                        !(passwordUpdated instanceof EbError) && (
                            <>
                                <Alert color="success">
                                    Uw wachtwoord synchronisatie is compleet. U
                                    kunt gelijk gebruik maken van het
                                    bibliotheek systeem.
                                </Alert>
                                <Col className="mb-3">
                                    Met deze barcode en uw wachtwoord kunt u
                                    inloggen op het bibliotheek systeem
                                    <Row>
                                        <Barcode
                                            value={borrowerId}
                                            format="CODE39"
                                        />
                                    </Row>
                                </Col>
                                {status < PaymentTransactionStatus.FINISHED && (
                                    <Alert color="warning">
                                        <Spinner size="sm" color="warning" />
                                        Barcode word verzonden naar{' '}
                                        {transaction.email}
                                    </Alert>
                                )}
                                {transaction.status === 'FINISHED' && (
                                    <Alert className="success">
                                        Barcode is verzonden naar{' '}
                                        {transaction.email}.
                                        <br />
                                        Kijk voor de zekerheid in uw spam folder
                                        als u de email niet kunt vinden.
                                        {barcodePrinted && (
                                            <>
                                                <br />
                                                Barcode is uitgeprint
                                            </>
                                        )}
                                    </Alert>
                                )}
                                {!barcodePrinted && (
                                    <p>
                                        <Button
                                            color="primary"
                                            className="me-1 fs-5"
                                            onClick={() => {
                                                resetActivity();
                                                setPrinting(true);
                                                app.post<any>(
                                                    `/v1/printer/provider`,
                                                    {
                                                        transaction
                                                    }
                                                )
                                                    .then(() => {
                                                        setBarcodePrinted(true);
                                                    })
                                                    .finally(() => {
                                                        setPrinting(false);
                                                    });
                                            }}
                                            disabled={
                                                !printerAvailable || printing
                                            }
                                        >
                                            {printing && (
                                                <Spinner
                                                    className="mr-2"
                                                    size="sm"
                                                />
                                            )}
                                            Klik hier
                                        </Button>
                                        als u toch een uitgeprinte versie van de
                                        barcode wilt hebben.
                                    </p>
                                )}
                            </>
                        )}
                    <TransactionReceipt
                        printing={printing}
                        setPrinting={setPrinting}
                    />
                </>
            )}
        </>
    );
};
