import React from 'react';
import moment from 'moment-timezone';
import 'moment/locale/nl';

export interface FormatTimeProps {
    date: Date;
}

export const FormatTime: React.FC<FormatTimeProps> = ({ date }) => {
    return (
        <span className="text-nowrap">
            {moment(date).locale('nl').format('HH:mm')} uur
        </span>
    );
};
