import React, { createRef, useContext, useEffect } from 'react';
import { Button, Col } from 'reactstrap';
import { PresentationItemData } from '../data/PresentationItemData';
import { KioskContext } from '../../../context/KioskContext';
import { MediaType } from '../data/MediaType';
import { ApplicationContext } from '../../../context/ApplicationContext';
import { ImageData } from '../data/ImageData';
import { VideoData } from '../data/VideoData';
import { UserActivityContext } from '../../../context/UserActivityContext';

export const SmartBridgeEvent: React.FC<{
    event?: PresentationItemData;
}> = ({ event }) => {
    const {
        services: { smartBridge }
    } = useContext(ApplicationContext);
    const { eventState, setEventState } = useContext(KioskContext);
    const { resetActivity } = useContext(UserActivityContext);

    const videoRef = createRef<HTMLVideoElement>();

    const style: React.CSSProperties = {
        zIndex: 100,
        top: 0,
        left: 0,
        backgroundImage:
            event?.media.type === MediaType.IMAGE
                ? `url(${smartBridge.getImage(
                      event.media.id,
                      (event.media as ImageData).checksum
                  )})`
                : 'unset',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 0,
        backgroundColor:
            event?.media.type === MediaType.VIDEO ? 'black' : 'transparent'
    };

    useEffect(() => {
        if (!event) {
            return undefined;
        }

        setEventState('playing');

        if (event.media.type === MediaType.IMAGE) {
            const t = setTimeout(() => {
                setEventState('finished');
            }, event.duration * 1_000);
            return () => clearTimeout(t);
        } else if (event.media.type === MediaType.VIDEO) {
        }

        return undefined;
    }, [event, setEventState, smartBridge]);

    useEffect(() => {
        if (!videoRef?.current || !event) {
            return undefined;
        }

        const currentRef = videoRef.current;

        const error = () => {
            setEventState('finished');
        };
        currentRef.addEventListener('error', error);

        const ended = () => {
            setEventState('finished');
        };
        currentRef.addEventListener('ended', ended);

        const canPlay = () => {
            currentRef
                .play()
                .then((r) => {
                    console.info(r);
                })
                .catch((e) => {
                    console.error(e);
                    setEventState('finished');
                });
        };
        currentRef.addEventListener('canplay', canPlay);

        currentRef.src = smartBridge.getVideo(
            event.media.id,
            (event.media as VideoData).checksum
        );

        return () => {
            currentRef.removeEventListener('error', error);
            currentRef.removeEventListener('canplay', canPlay);
            currentRef.removeEventListener('ended', ended);
        };
    }, [event, setEventState, smartBridge, videoRef]);

    if (!event || eventState === 'finished') {
        return undefined;
    }

    return (
        <Col
            className="position-fixed vw-100 vh-100 d-flex align-items-center justify-content-center overflow-hidden"
            style={style}
            onTouchStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                resetActivity();
            }}
        >
            {event && event.media.type === MediaType.VIDEO && (
                <video
                    className="vw-100 vh-100"
                    ref={videoRef}
                    autoPlay
                    controls={false}
                    muted={event.muted}
                />
            )}
            <Button
                style={{
                    position: 'fixed',
                    right: 50,
                    bottom: 50,
                    zIndex: 1000,
                    padding: 15,
                    borderRadius: 30
                }}
                onClick={() => {
                    resetActivity();
                }}
            >
                <i className="fa fa-hand" /> Raak aan om verder te gaan
            </Button>
        </Col>
    );
};
