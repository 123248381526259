import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { ThemeLibretto } from './theme/ThemeLibretto';
import { LibrettoData } from './data/LibrettoData';
import { KioskItem } from './data/KioskItem';
import { OvaticContext } from '../../ovatic/context/OvaticContext';
import { ThemeData } from '../smartbridge/data/ThemeData';
import { CollectionData } from '../smartbridge/data/CollectionData';
import { CollectionType } from '../smartbridge/data/CollectionType';
import { SubscriptionContext } from '../../vsmart/context/SubscriptionContext';

interface KioskLibrettoContextType {
    module?: CollectionData;
    theme: ThemeData;
    selected: { rowId: string; ticket: KioskItem };
    rows: LibrettoData[];
    preview: boolean;
    movingRow: string;
    availableFilters: LibrettoFilters[];
    filter: LibrettoFilters;

    setSelected(selected: { rowId: string; ticket: KioskItem });

    select(ticket: KioskItem);

    toHome?();

    setMovingRow(movingRow: string);

    setFilter(filter: LibrettoFilters);
}

export const KioskLibrettoContext =
    React.createContext<KioskLibrettoContextType>(undefined as any);

export enum LibrettoFilters {
    NOW = 'NU',
    SOON = 'BINNENKORT',
    ALL = 'ALLES'
}

export const KioskLibrettoContextProvider: React.FC<{
    theme: ThemeData;
    module?: CollectionData;
    preview: boolean;
    toHome?();
}> = ({ theme, module, preview, toHome }) => {
    const [selected, setSelected] = useState<{
        rowId: string;
        ticket: KioskItem;
    }>();

    const { activities } = useContext(OvaticContext);
    const { subscriptions } = useContext(SubscriptionContext);

    const [rows, setRows] = useState<LibrettoData[]>();

    const [movingRow, setMovingRow] = useState<string>();

    const [availableFilters, setAvailableFilters] = useState<LibrettoFilters[]>(
        []
    );
    const [filter, setFilter] = useState(LibrettoFilters.ALL);

    const filterActivities = useCallback(
        (filter: LibrettoFilters) => {
            if (!activities) {
                return [];
            }

            if (filter === LibrettoFilters.ALL) {
                return activities;
            }

            const offset = filter === LibrettoFilters.SOON ? 14 : 7;
            const now = new Date();

            return activities.filter(
                (it) =>
                    it.getStartDate() <=
                    new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate() + offset
                    )
            );
        },
        [activities]
    );

    useEffect(() => {
        if (!rows || rows.length === 0 || movingRow) {
            return undefined;
        }

        setMovingRow(rows[0].id);
    }, [rows, movingRow]);

    useEffect(() => {
        if (!rows || rows.length === 0) {
            return undefined;
        }

        const t = setInterval(() => {
            const index = Math.floor(Math.random() * rows.length);
            setMovingRow(rows[index].id);
        }, 10_000);
        return () => clearInterval(t);
    }, [rows]);

    useEffect(() => {
        let available = [LibrettoFilters.ALL];

        if (filterActivities(LibrettoFilters.NOW).length > 0) {
            available.push(LibrettoFilters.SOON);
        }

        if (filterActivities(LibrettoFilters.NOW).length > 0) {
            available.push(LibrettoFilters.NOW);
        }

        setAvailableFilters(available.reverse());
    }, [activities, filterActivities]);

    useEffect(() => {
        if (availableFilters.length > 0 && !availableFilters.includes(filter)) {
            setFilter(availableFilters[0]);
        }
    }, [availableFilters, filter]);

    useEffect(() => {
        if (module.provider !== CollectionType.OVATIC) {
            return;
        }

        const now = new Date();

        let filteredActivities = activities ?? [];
        if (filter !== LibrettoFilters.ALL) {
            const offset = filter === LibrettoFilters.NOW ? 7 : 14;
            filteredActivities = activities.filter(
                (it) =>
                    it.getStartDate() <=
                    new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate() + offset
                    )
            );
        }

        setRows([
            {
                id: '1',
                name: 'row 1',
                items: filteredActivities
            },
            {
                id: '2',
                name: 'row 2',
                items: filteredActivities
            }
        ]);
    }, [activities, filter, module.provider]);

    useEffect(() => {
        if (module.provider !== CollectionType.SUBSCRIPTIONS) {
            return;
        }

        setRows([
            {
                id: '1',
                name: 'row 1',
                items: subscriptions
            },
            {
                id: '2',
                name: 'row 2',
                items: subscriptions
            }
        ]);
    }, [activities, module.provider, subscriptions]);

    const mockHome = useCallback(() => {}, []);

    const select: KioskLibrettoContextType['select'] = useCallback(
        (ticket) => {
            const row =
                rows.find((row) =>
                    row.items.some((item) => item.getId() === ticket.getId())
                ) ||
                (rows.length && rows[0]);
            /*
             * Different dates that a production takes place are separate activities.
             * However to not show the same activity with different dates in the row, we only return the earliest activity of a production on the server side.
             * This however means later dates of the same production are never in the row, thus no row id can be found.
             * For now we just pick the first row :/
             */
            setSelected({
                rowId: row?.id,
                ticket
            });
        },
        [rows]
    );

    const value: KioskLibrettoContextType = useMemo(
        () => ({
            module,
            theme,
            selected,
            rows,
            preview,
            movingRow,
            availableFilters,
            filter,
            select,
            setSelected,
            toHome: toHome ?? mockHome,
            setMovingRow,
            setFilter
        }),
        [
            module,
            theme,
            selected,
            rows,
            preview,
            movingRow,
            availableFilters,
            filter,
            select,
            toHome,
            mockHome
        ]
    );

    return (
        <KioskLibrettoContext.Provider value={value}>
            <ThemeLibretto />
        </KioskLibrettoContext.Provider>
    );
};
