import React from 'react';
import { OvaticAddressData } from '../data/OvaticAddressData';

export interface OvaticAddressProps {
    address: OvaticAddressData;
}

export const OvaticAddress: React.FC<OvaticAddressProps> = ({ address }) => {
    return (
        <p className="mb-0">
            <span>{address.city}, </span>
            {address.zipCode && <span>{address.zipCode}, </span>}
            {(address.street || address.streetNumber) && (
                <span>
                    {address.street} {address.streetNumber}
                    {address.streetExtra && (
                        <small>, {address.streetExtra}</small>
                    )}
                </span>
            )}
        </p>
    );
};
