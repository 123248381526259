import React, { useContext } from 'react';
import { OvaticActivityContext } from '../context/OvaticActivityContext';
import { Col, Row } from 'reactstrap';
import { OvaticSelectedSeat } from './OvaticSelectedSeat';

export const OvaticSelectedSeats: React.FC = () => {
    const { selectedSeats } = useContext(OvaticActivityContext);

    if (selectedSeats.length === 0) {
        return (
            <Row>
                <Col>
                    <p>
                        Klik op een beschikbare plek in de tekening om die plek
                        te selecteren.
                    </p>
                </Col>
            </Row>
        );
    }

    return (
        <Row className="g-3 mt-2">
            {selectedSeats.map((seat) => (
                <Col md={4} key={seat.seatNumber}>
                    <OvaticSelectedSeat seat={seat} />
                </Col>
            ))}
        </Row>
    );
};
