import React, { useContext, useMemo, useState } from 'react';
import { PaymentContext } from '../../payment/context/PaymentContext';
import { EbError } from '../../shared/axios/EbError';
import { Alert, Button, Row, Spinner } from 'reactstrap';
import { PaymentTransactionStatus } from '../../payment/data/PaymentTransactionData';
import { ApplicationContext } from '../../context/ApplicationContext';
import { OvaticContext } from '../context/OvaticContext';
import { OvaticCartResponse } from '../data/OvaticCartResponse';
import { TransactionReceipt } from '../../payment/components/TransactionReceipt';
import { UserActivityContext } from '../../context/UserActivityContext';

export const OvaticCartFinishStep: React.FC = () => {
    const {
        api: { app }
    } = useContext(ApplicationContext);
    const { transaction, printerAvailable } = useContext(PaymentContext);
    const { cart, cartSecret } = useContext(OvaticContext);
    const [ticketsPrinted, setTicketsPrinted] = useState(false);
    const [printing, setPrinting] = useState(false);
    const { resetActivity } = useContext(UserActivityContext);
    const cartWithSecret: OvaticCartResponse = useMemo(
        () => ({
            ...cart,
            secret: cartSecret
        }),
        [cart, cartSecret]
    );

    if (!transaction || transaction instanceof EbError) {
        return (
            <Alert>Er is iets mis gegaan, naar de klantenbalie voor hulp</Alert>
        );
    }

    const status = PaymentTransactionStatus[transaction.status];

    // Minimum transaction status here is SUCCESS
    if (status < PaymentTransactionStatus.PROVIDER) {
        return (
            <>
                <Spinner size="sm" /> Moment geduld uw tickets worden verwerkt
            </>
        );
    }

    return (
        <>
            <Row>
                <p className="fs-3">Veel plezier met uw tickets.</p>
            </Row>
            {!(transaction instanceof EbError) && (
                <>
                    {status < PaymentTransactionStatus.FINISHED && (
                        <Alert color="warning">
                            <Spinner size="sm" color="warning" />
                            Tickets word verzonden naar {transaction.email}
                        </Alert>
                    )}
                    {transaction.status === 'FINISHED' && (
                        <Alert className="success">
                            Tickets zijn verzonden naar {transaction.email}
                            .
                            <br />
                            Kijk voor de zekerheid in uw spam folder als u de
                            email niet kunt vinden.
                            {ticketsPrinted && (
                                <>
                                    <br />
                                    Tickets zijn uitgeprint
                                </>
                            )}
                        </Alert>
                    )}
                    {!ticketsPrinted && (
                        <p>
                            <Button
                                color="primary"
                                className="me-1 fs-5"
                                onClick={() => {
                                    resetActivity();
                                    setPrinting(true);
                                    app.post<any>(`/v1/printer/provider`, {
                                        transaction,
                                        ovatic: cartWithSecret
                                    })
                                        .then((res) => res.data)
                                        .finally(() => {
                                            setPrinting(false);
                                            setTicketsPrinted(true);
                                        });
                                }}
                                disabled={!printerAvailable || printing}
                            >
                                {printing && (
                                    <Spinner className="mr-2" size="sm" />
                                )}
                                Klik hier
                            </Button>
                            als u toch een uitgeprinte versie van de tickets
                            wilt hebben.
                        </p>
                    )}
                    <TransactionReceipt
                        printing={printing}
                        setPrinting={setPrinting}
                    />
                </>
            )}
        </>
    );
};
