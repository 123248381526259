import React, { useContext, useState } from 'react';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Spinner
} from 'reactstrap';
import { OvaticContext } from '../context/OvaticContext';
import { ErrorMessage, Field, FieldProps, Formik } from 'formik';
import { OvaticRegisterAccountRequest } from '../data/OvaticRegisterAccountRequest';
import { EbError, ErrorCode } from '../../shared/axios/EbError';
import * as Yup from 'yup';
import { KioskContext } from '../../context/KioskContext';
import { InputField } from '../../vsmart/components/InputField';
import { UserActivityContext } from '../../context/UserActivityContext';

export interface OvaticRegisterAccountProps {
    /**
     * Defaults to false.
     */
    guest?: boolean;
}

export const OvaticRegisterAccount: React.FC<OvaticRegisterAccountProps> = ({
    guest = false
}) => {
    const { setShowTermsOfService } = useContext(KioskContext);
    const { registerAccount, handleError } = useContext(OvaticContext);
    const [showTermsOfServiceError, setShowTermsOfServiceError] =
        useState(false);
    const { resetActivity } = useContext(UserActivityContext);
    let agreeTermsOfServiceValidation = Yup.boolean().test({
        name: 'agreeTermsOfService',
        test: function (value: boolean) {
            if (!value && showTermsOfServiceError) {
                return (this as any).createError({
                    message:
                        'U moet akkoord gaan met de algemene voorwaarden om gebruik te mogen maken van deze dienst',
                    path: 'agreeTermsOfService'
                });
            }
            return true;
        }
    });
    if (showTermsOfServiceError) {
        agreeTermsOfServiceValidation.required();
    }
    return (
        <Formik<OvaticRegisterAccountRequest>
            initialValues={{
                email: '',
                password: '',
                agreeTermsOfService: false,
                guest
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .required('Dit veld is verplicht')
                    .email('Dit is geen geldig e-mailadres')
                    .max(
                        200,
                        (params) =>
                            `Dit veld mag maximaal ${params.max} karakters lang zijn`
                    ),
                password: Yup.string().when('guest', {
                    is: false,
                    then: (schema) =>
                        schema
                            .required('Dit veld is verplicht')
                            .min(
                                8,
                                (params) =>
                                    `Het wachtwoord moet uit minstens ${params.min} karakters bestaan`
                            )
                }),
                agreeTermsOfService: agreeTermsOfServiceValidation
            })}
            onSubmit={async (values, helpers) => {
                resetActivity();
                try {
                    await registerAccount(values);
                } catch (e) {
                    let handled = false;
                    if (e instanceof EbError) {
                        if (
                            e.hasFieldErrorCode(
                                'agreeTermsOfService',
                                ErrorCode.REQUIRED
                            )
                        ) {
                            handled = true;
                            setShowTermsOfServiceError(true);
                            setTimeout(() =>
                                helpers
                                    .validateForm()
                                    .catch((err) =>
                                        console.error(
                                            'Validate form failed',
                                            err
                                        )
                                    )
                            );
                        } else if (
                            e.hasErrorCode(
                                ErrorCode.OVATIC_ACCOUNT_ALREADY_EXISTS
                            )
                        ) {
                            handled = true;
                            helpers.setFieldError(
                                'email',
                                'Dit e-mailadres is al gebruikt voor een account. U kan proberen om in te loggen. Als u het wachtwoord bent vergeten, dan kan u deze via de Ovatic website herstellen, zie daarvoor de link hierboven.'
                            );
                        }
                    }
                    if (!handled) {
                        handleError(e);
                    }
                }
            }}
        >
            {(formikProps) => (
                <Form onSubmit={formikProps.handleSubmit}>
                    <InputField
                        id="email"
                        label="E-mail"
                        type="email"
                        mandatory
                    />
                    {!guest && (
                        <InputField
                            id="password"
                            label="Wachtwoord"
                            type="password"
                            mandatory
                        />
                    )}
                    <Field name="agreeTermsOfService">
                        {(fieldProps: FieldProps) => (
                            <FormGroup className="mb-3" check>
                                <Input
                                    type="checkbox"
                                    {...fieldProps.field}
                                    invalid={
                                        fieldProps.meta.touched &&
                                        !!fieldProps.meta.error
                                    }
                                />
                                <Label check>
                                    Ik ga akkoord met de{' '}
                                    <Button
                                        type="button"
                                        color="link"
                                        className="p-0 m-0 align-baseline fs-5"
                                        onClick={() => {
                                            resetActivity();
                                            setShowTermsOfService(true);
                                        }}
                                    >
                                        algemene voorwaarden
                                    </Button>
                                </Label>
                                {fieldProps.meta.touched && (
                                    <FormFeedback invalid>
                                        <ErrorMessage
                                            name={fieldProps.field.name}
                                        />
                                    </FormFeedback>
                                )}
                            </FormGroup>
                        )}
                    </Field>
                    <Button
                        className="fs-5"
                        type="submit"
                        color="primary"
                        disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                        }
                    >
                        {formikProps.isSubmitting && (
                            <Spinner size="sm" className="mr-2" />
                        )}
                        {guest ? (
                            <>
                                <i className="fa fa-arrow-right" /> Doorgaan met
                                bestelling
                            </>
                        ) : (
                            <>
                                <i className="fa fa-user" /> Registreer account
                            </>
                        )}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
