import React, { useContext } from 'react';
import { OvaticActivityContext } from '../context/OvaticActivityContext';
import { Badge } from 'reactstrap';

export const OvaticSeatPlanLegend: React.FC = () => {
    const { seats } = useContext(OvaticActivityContext);

    if (!seats) {
        return null;
    }

    const items = seats.ranks.map((rank) => {
        return (
            <span key={rank.rankId}>
                <sup className="px-1">
                    <Badge
                        pill
                        color={null}
                        style={{
                            backgroundColor: rank.colors[0]
                        }}
                    >
                        &nbsp;
                    </Badge>
                </sup>
                {rank.name} (capaciteit: {rank.capacity}) &nbsp;
            </span>
        );
    });

    return (
        <div className="legend text-center">
            <small>{items}</small>
        </div>
    );
};
