import React, { useCallback, useContext, useEffect } from 'react';
import { OvaticContext } from '../context/OvaticContext';
import { PaymentContext } from '../../payment/context/PaymentContext';
import { formatMoney } from '../../util';
import { Button } from 'reactstrap';
import { CashierDisplay } from '../../payment/components/CashierDisplay';
import { ApplicationContext } from '../../context/ApplicationContext';
import { UserActivityContext } from '../../context/UserActivityContext';

export interface OvaticCartPaymentStepProps {
    goStepBack();

    goStepForward();
}

export const OvaticCartPaymentStep: React.FC<OvaticCartPaymentStepProps> = ({
    goStepBack,
    goStepForward
}) => {
    const {
        services: { payment }
    } = useContext(ApplicationContext);
    const { cart, account } = useContext(OvaticContext);
    const { startTransaction, success, transaction } =
        useContext(PaymentContext);
    const { resetActivity } = useContext(UserActivityContext);

    const paymentAmount = cart?.totalAmount ?? 0;
    const requirePayment = paymentAmount > 0;
    const email = account?.email;

    const startPayment = useCallback(() => {
        if (requirePayment && account?.customerId && cart?.id) {
            startTransaction({
                user: {
                    email: email
                },
                money: paymentAmount,
                item: 'TICKET',
                source: 'OVATIC',
                reference: JSON.stringify({
                    cart: cart.id,
                    customer: account.customerId
                }),
                type: 'PAY'
            });
        }
    }, [
        account?.customerId,
        cart?.id,
        email,
        paymentAmount,
        requirePayment,
        startTransaction
    ]);

    useEffect(() => {
        startPayment();
    }, [startPayment]);

    useEffect(() => {
        if (success === false) {
            goStepBack();
        } else if (success === true) {
            goStepForward();
        }
    }, [goStepBack, goStepForward, success]);

    let body;
    if (success) {
        body = <p>De betaling is afgerond.</p>;
    } else {
        body = (
            <>
                <CashierDisplay />
                {/*success === false && (
                    <Button
                        color="danger"
                        onClick={() => {
                            startPayment();
                        }}
                        disabled={outOfOrder}
                    >
                        Start pin betaling
                    </Button>
                )*/}
                {transaction && success === undefined && (
                    <Button
                        className="fs-5"
                        onClick={() => {
                            resetActivity();
                            payment.abort();
                        }}
                    >
                        Annuleer pin betaling
                    </Button>
                )}
            </>
        );
    }

    return (
        <div>
            <p>
                Uw e-mailadres: <strong>{email}</strong>
            </p>
            <p>
                Totaal bedrag:{' '}
                <strong>{formatMoney(paymentAmount / 100)}</strong>
            </p>
            {body}
        </div>
    );
};
