import React, { useContext, useState } from 'react';
import { OvaticContext } from '../context/OvaticContext';
import { Button, Spinner, Table } from 'reactstrap';
import { OvaticAddress } from './OvaticAddress';
import { FormatDate } from '../../shared/kiosk/components/FormatDate';
import { FormatTime } from '../../shared/kiosk/components/FormatTime';
import { formatMoney } from '../../util';

export interface OvaticCartSummaryProps {
    readOnly?: boolean;
}

export const OvaticCartSummary: React.FC<OvaticCartSummaryProps> = ({
    readOnly = false
}) => {
    const { cart, cancelTransaction } = useContext(OvaticContext);

    const transactions = cart?.transactions;
    const hasTransactions = transactions && transactions.length;

    const [deletingTransaction, setDeletingTransaction] = useState<number[]>(
        []
    );

    return (
        <Table>
            <thead>
                <tr>
                    <th>Ticket</th>
                    <th>Datum</th>
                    <th>Locatie</th>
                    <th>Aantal tickets</th>
                    <th>Prijs</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {hasTransactions ? (
                    cart.transactions.map((transaction, index) => {
                        const activity = transaction.activity;
                        const building = activity.building;
                        const isDeletingTransaction =
                            deletingTransaction.includes(
                                transaction.transactionId
                            );
                        return (
                            <tr key={index}>
                                <td>
                                    <p className="mb-0">{activity.title}</p>
                                    {activity.subtitle && (
                                        <small>
                                            <em>{activity.subtitle}</em>
                                        </small>
                                    )}
                                </td>
                                <td>
                                    <FormatDate
                                        date={new Date(activity.startDate)}
                                    />
                                    <br />
                                    <span className="text-nowrap">
                                        <FormatTime
                                            date={new Date(activity.startDate)}
                                        />
                                        {' - '}
                                        <FormatTime
                                            date={new Date(activity.endDate)}
                                        />
                                    </span>
                                </td>
                                <td>
                                    {building?.name && (
                                        <p className="mb-0">{building?.name}</p>
                                    )}
                                    {building && (
                                        <em>
                                            <OvaticAddress
                                                address={building.address}
                                            />
                                        </em>
                                    )}
                                </td>
                                <td>{transaction.ticketCount}</td>
                                <td>
                                    {formatMoney(transaction.price / 100)}
                                    {transaction.bookingFee > 0 && (
                                        <small>
                                            Booking fee
                                            {formatMoney(
                                                transaction.bookingFee / 100
                                            )}
                                        </small>
                                    )}
                                </td>
                                <td>
                                    {!readOnly && (
                                        <Button
                                            color="danger"
                                            disabled={isDeletingTransaction}
                                            onClick={async () => {
                                                setDeletingTransaction(
                                                    (prev) => {
                                                        return [
                                                            ...prev,
                                                            transaction.transactionId
                                                        ];
                                                    }
                                                );
                                                try {
                                                    await cancelTransaction(
                                                        transaction.transactionId
                                                    );
                                                } finally {
                                                    setDeletingTransaction(
                                                        (prev) =>
                                                            prev.filter(
                                                                (id) =>
                                                                    id !==
                                                                    transaction.transactionId
                                                            )
                                                    );
                                                }
                                            }}
                                        >
                                            {isDeletingTransaction && (
                                                <Spinner
                                                    size="sm"
                                                    className="mr-2"
                                                />
                                            )}
                                            <i className="fa fa-trash" />
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={6}>Winkelwagen is leeg.</td>
                    </tr>
                )}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={3} style={{ textAlign: 'end' }}>
                        Totaal:
                    </th>
                    <th>{cart?.ticketCount ?? 0}</th>
                    <th>{formatMoney(cart?.totalAmount / 100)}</th>
                    <th />
                </tr>
            </tfoot>
        </Table>
    );
};
