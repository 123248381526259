import React from 'react';
import { KioskConstants, Themes } from '../data/KioskData';

export const ThemeFooter: React.FC<{ settings: Themes }> = ({ settings }) => {
    return (
        <div
            className="d-flex align-items-end justify-content-center"
            style={{
                height: `${KioskConstants.STATIC_BOTTOM_MARGIN}%`,
                paddingBottom: '3%',
                fontSize: '210%'
            }}
        >
            <span
                className="font-weight-bold"
                style={{
                    color: settings.footer.color
                }}
            >
                {settings.footer.title}
            </span>
        </div>
    );
};
