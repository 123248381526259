import React from 'react';
import { FormGroup } from 'reactstrap';
import { Field } from 'formik';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './InputField.scss';
import { KioskTextField } from '../../shared/kiosk/components/KioskTextField';

interface InputFieldProps {
    id: string;
    label: string;
    type: 'text' | 'checkbox' | 'email' | 'select' | 'date' | 'password';
    options?: { code: string; value: string }[];
    mandatory?: boolean;
    valid?: boolean;
    autoComplete?: any;
}

export const InputField: React.FC<InputFieldProps> = ({
    id,
    label,
    type = 'text',
    options,
    mandatory,
    valid,
    autoComplete
}) => {
    return (
        <Field name={id}>
            {({ field, form, meta }) => {
                return (
                    <FormGroup>
                        <FormControl
                            className={valid ? 'validated' : ''}
                            variant="outlined"
                            fullWidth
                        >
                            {(type === 'text' ||
                                type === 'email' ||
                                type === 'password') && (
                                <KioskTextField
                                    {...field}
                                    className={valid ? 'validated' : ''}
                                    error={!!meta.error && meta.touched}
                                    label={label}
                                    helperText={meta.touched && meta.error}
                                    required={mandatory}
                                    name={field.name}
                                    value={field.value}
                                    type={type}
                                    disabled={valid}
                                    onChange={(value) => {
                                        form.setFieldValue(field.name, value);
                                    }}
                                    onBlur={field.onBlur}
                                    autoComplete={autoComplete}
                                />
                            )}
                            {type === 'checkbox' && (
                                <FormControlLabel
                                    className={valid ? 'validated' : ''}
                                    control={
                                        <Checkbox
                                            name={field.value}
                                            disabled={valid}
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                        />
                                    }
                                    label={label}
                                />
                            )}
                            {type === 'select' && (
                                <>
                                    <InputLabel id="demo-simple-select-filled-label">
                                        {label}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        label={label}
                                        name={field.name}
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        disabled={valid}
                                    >
                                        {options?.map((option) => {
                                            return (
                                                <MenuItem
                                                    id={option.code}
                                                    value={option.code}
                                                >
                                                    {option.value}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </>
                            )}
                            {type === 'date' && (
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <MobileDatePicker
                                        label={label}
                                        value={field.value ? field.value : null}
                                        onChange={(newValue) => {
                                            form.setFieldValue(
                                                field.name,
                                                newValue
                                            );
                                        }}
                                        disableMaskedInput
                                        disableFuture
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className={
                                                    valid ? 'validated' : ''
                                                }
                                                error={
                                                    !!meta.error && meta.touched
                                                }
                                                id="standard-helperText"
                                                label={label}
                                                helperText={
                                                    meta.touched && meta.error
                                                }
                                                required={mandatory}
                                                name={field.name}
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                variant="outlined"
                                                {...field}
                                            />
                                        )}
                                        disabled={valid}
                                    />
                                </LocalizationProvider>
                            )}
                        </FormControl>
                    </FormGroup>
                );
            }}
        </Field>
    );
};
